/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Profile Component
import React, { PureComponent } from "react";
import LoadingBar from 'react-top-loading-bar';

import Header from "../../components/Header";
import NewHeader from "../../components/NewHeader";
import Footer from "../../components/Footer";
import { notificationItems } from "../Mockdata";
import Sticky from "react-stickynode";

import {
  getCity,
  getError,
  getCategory,
  homePageList,
  getRecentSearch,
  getTypeHead,
  getProfileDetails,
  updatePersonaldetail,
  getCampaignChoice
} from "../../service";
import { act } from "react-dom/test-utils";
import { withRouter } from "react-router";
import uiConfig from "../../uiConfig";
import config from "../../service/config";
import AdibHeader from "../../components/AdibHeader";
class PageBase extends PureComponent {
  constructor(props) {
  super(props);
  this.state = {
    countryList: [],
    categoryList: [],
    homeList: null,
    userData: null,
    reacentSearch: [],
    typeAhead: [],
    loadingBarProgress: 0,
    campaignCardClicked:false,
    activeMenu:"home",
    headerAdib:false,
    hasActiveMyMenu : false,
    hasShowMoreMenu:false,
    selectedMyMenu:"",
    mainMenuActive:false,
    width: 0,
    popularSearch: []
  };
  this.wrapperRef = React.createRef();
}


  //Event Listner for outside click - to hide the dropdowns
  async componentDidMount() {
    this.updateWindowDimensions();
      window.addEventListener("resize", this.updateWindowDimensions);
    document.addEventListener('mousedown', this.handleClickOutside);
    // if(this.props.getRecentSearchData){
    //   await this.props.getRecentSearchData();
    // }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  /* Onclick Function */
  _onChangeTab = (activeMenu) => {
    const location=this.props.location.pathname;
    if(location==="/home"){
     this.props._onChangeTab(activeMenu);
     this.props.setActiveMenu(activeMenu)
     this.setState({ activeMenu, hasShowMoreMenu: false, hasActiveMyMenu: false, mainMenuActive:false,selectedMyMenu:""});
     sessionStorage.setItem("actMenu",activeMenu)
    }
    else{
      this.props._onChangeTab(activeMenu);
      // this.props.setActiveMenu(activeMenu)
      this.setState({ activeMenu, hasShowMoreMenu: false, hasActiveMyMenu: false, mainMenuActive:false,selectedMyMenu:""});
      sessionStorage.setItem("actMenu",activeMenu)
      this.props.history.push( "/home", activeMenu)
    }
    window.scrollTo(0,0)
  };

  _onLoaderFinished = () => {
    this.setState({
      loadingBarProgress: 0
    });
  };

  saveDetailsLoader = () => {
    this.setState({loadingBarProgress : 100});
  };
  updatePersonaldetails(request) {
    updatePersonaldetail(request)
      .then(() => {})
      .catch((err) => {
        alert(getError(err));
      });
  }
  setActiveItem=(activeItem)=>{
    this.props.setActiveItem(activeItem)
  }

  //Handle Show More Sub Menu
  handleOnMoreMenu=()=>{
    this.setState({
      hasShowMoreMenu: !this.state.hasShowMoreMenu,
      hasActiveMyMenu:false
    })
  }

  //Handle Show My Account Menu
  onShowMyMenu=()=>{
    this.setState({
      hasActiveMyMenu: !this.state.hasActiveMyMenu,
      hasShowMoreMenu:false
    })
  }

  //OnChanging Profile Menu
  onProfileMenu=(menu)=>{
    const { history } = this.props;
    this.setState({
      selectedMyMenu:menu, 
      hasShowMoreMenu:false,
    },()=>{
      sessionStorage.setItem('currPage',menu);
    //  sessionStorage.setItem("actMenu",menu);
      sessionStorage.setItem("activeMenu","undefined");
      this.props.setActiveItem(menu);
      history.push("/home/profile", menu);
    })
  }

  //When we select React Select(Country) - Close other dropdowns
  onSelectFocus=()=>{
    this.setState({
      hasShowMoreMenu: false,
      hasActiveMyMenu: false
    })
  }

  handleClickOutside=(event)=>{
    if (this.wrapperRef.current && this.wrapperRef.current.contains(event.target)) {
          return;
      }
    this.setState({
      hasShowMoreMenu: false,
      hasActiveMyMenu: false
    })
  }
  
  changeHeader=(val)=>{
    this.setState({
      headerAdib:val
    })
  }

  //When click whishlist - send props true to activate the whishlist
  onSelectWhishlist=()=>{
    this.setState({
      mainMenuActive:true,
      selectedMyMenu:"",
      hasShowMoreMenu: false
    })
  }
  
  componentWillReceiveProps(nextProps){
    this.setState({ 
      activeMenu:nextProps.activeMenu,
      countryList: nextProps.countryList,
      categoryList: nextProps.categoryList,
      homeList: nextProps.homeList,
      userData: nextProps.userData,
      reacentSearch: nextProps.reacentSearch,
      typeAhead: nextProps.typeAhead,
      loadingBarProgress: nextProps.loadingBarProgress,
      campaignCardClicked:nextProps.campaignCardClicked,
      rightMenuActive:nextProps.rightMenuActive,
      popularSearch: nextProps.popularSearch
    })
  }

  _renderContent() {}

  render() {
    const {headerAdib, width} = this.state;
    
    return (    <>
    {this.props.history.location.pathname!=="/login"&&  
    <div className="col-md-12 home-page">
        <LoadingBar
          color='#00AE8E'
          height={3}
          shadow={true}
          progress={this.state.loadingBarProgress}
          loaderSpeed={10000}
          onLoaderFinished={() => this._onLoaderFinished}
        />
          {
            config.clientName !== "ADIB" ? 
            config.clientName !== "HDFC" ?
            <div className={width<=933 ? "col-md-12 header-details new" : "" } >
            <NewHeader
              _openLogin={this.props._openLogin}
              userAuthed={this.props.userAuthed}
              _closeLogin={this.props._closeLogin}
              updateAuth={this.props.updateAuth}
              openModal={this.props.openModal}
              activeMenu={this.state.activeMenu}
              onChangeTab={this._onChangeTab}
              countryList={this.props.countryList}
              recentLocation={this.props.recentLocation}
              categoryList={this.state.categoryList}
              userData={this.state.userData}
              reacentSearch={this.state.reacentSearch}
              typeAhead={this.props.typeAhead}
              history={this.props.history}
              notificationItems={notificationItems}
              getHomeList={this.props.getHomeList}
              getTypeHeaItem={this.props.getTypeHeadItem}
              updatePersonaldetails={this.updatePersonaldetails}
              saveDetailsLoader={this.saveDetailsLoader}
              setActiveItem={this.setActiveItem}
              setActiveMenu={this.props.setActiveMenu}
              getRecentSearchData={this.props.getRecentSearchData}
              popularSearch={this.state.popularSearch}
            />
            </div> :
            <div className={ width<=933 ? "col-md-12 header-details new" : "" } >
              <NewHeader
                _openLogin={this.props._openLogin}
                userAuthed={this.props.userAuthed}
                _closeLogin={this.props._closeLogin}
                updateAuth={this.props.updateAuth}
                openModal={this.props.openModal}
                activeMenu={this.state.activeMenu}
                onChangeTab={this._onChangeTab}
                countryList={this.props.countryList}
                recentLocation={this.props.recentLocation}
                categoryList={this.state.categoryList}
                userData={this.state.userData}
                reacentSearch={this.state.reacentSearch}
                typeAhead={this.props.typeAhead}
                history={this.props.history}
                notificationItems={notificationItems}
                getHomeList={this.props.getHomeList}
                getTypeHeaItem={this.props.getTypeHeadItem}
                updatePersonaldetails={this.updatePersonaldetails}
                saveDetailsLoader={this.saveDetailsLoader}
                setActiveItem={this.setActiveItem}
                setActiveMenu={this.props.setActiveMenu}
                getRecentSearchData={this.props.getRecentSearchData}
                popularSearch={this.state.popularSearch}
              />
              </div>
              :
              <div className={headerAdib?"col-md-12 header-details header-details-adib":"col-md-12 p-0"} ref={this.wrapperRef}>
                <AdibHeader
                  _openLogin={this.props._openLogin}
                  userAuthed={this.props.userAuthed}
                  _closeLogin={this.props._closeLogin}
                  updateAuth={this.props.updateAuth}
                  openModal={this.props.openModal}
                  categoryList={this.state.categoryList}
                  onChangeTab={this._onChangeTab}
                  activeMenu={this.state.activeMenu}
                  history={this.props.history}
                  setActiveMenu={this.props.setActiveMenu}
                  onSelectMenu = {this.onSelectMenu}
                  setActiveItem={this.setActiveItem}
                  countryList={this.props.countryList}
                  getHomeList={this.props.getHomeList}
                  typeAhead={this.props.typeAhead}
                  reacentSearch={this.state.reacentSearch}
                  userData={this.state.userData}
                  handleOnMoreMenu={this.handleOnMoreMenu}
                  hasShowMoreMenu={this.state.hasShowMoreMenu}
                  onShowMyMenu={this.onShowMyMenu}
                  hasActiveMyMenu={this.state.hasActiveMyMenu}
                  onProfileMenu={this.onProfileMenu}
                  selectedMyMenu={this.state.selectedMyMenu}
                  changeHeader={this.changeHeader}
                  onSelectFocus={this.onSelectFocus}
                  mainMenuActive={this.state.mainMenuActive}
                  onSelectWhishlist={this.onSelectWhishlist}
                  getRecentSearchData={this.props.getRecentSearchData}
                  rightMenuActive={this.state.rightMenuActive}
                  setRightMenuActive={this.props.setRightMenuActive}
                />
              </div>
          }
      </div>}
      </>
    );
  }
}

export default withRouter(PageBase)
