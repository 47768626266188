/**
 *
 * Disclaimer: Source code mentioned below is(are) Intellectual Property of
 * Crayon Data Holdings Limited (including its subsidiaries and affiliates).
 * Crayon Data Holdings Limited reserves right to own and control it the way
 * it may deem fit. You must refrain from use, access, read, modify, add or
 * delete, sell or use in any other package or programme pertaining to such
 * source code without explicit prior written approval of
 * Crayon Data Holding Limited. Breach of the same shall attract penalty as
 * applicable.
 *
 */

// Heading Component
import React, { Component } from "react";
import CheeseburgerMenu from "cheeseburger-menu";
import Autosuggest from "react-autosuggest";
import disableScroll from "disable-scroll";

import eventBus from "../../pages/helper";
// Import Component
import Image from "../Image";
import Select from "../Select";
import Icon from "../Icons";
import NavBar from "../Navbar";
import Newnavbar from "../Newnavbar";
import Profile from "../Profile";
import NewProfile from "../NewProfile";
import SearchBar from "../SearchBar";
import MobileSearchBar from "../MobileSearchBar";
import { deepEqual } from "../../pages/helper";
// Helper
import { languageOption } from "../helper/options";

import MayaLogo from "../../assets/img/logo.png";
import AdibLogo from "../../assets/img/adib_logo.png";
import HdfcLogo from "../../assets/img/HDFC_logo_new.png";
import { accessTokenHelper } from "../../helper/token";
import {
  getCampaigns,
  getError,
  getTypeHead,
  searchKeywordFunc,
} from "../../service";
import history from "../../routes/history";
import { useHistory, withRouter } from "react-router";
import uiConfig from "../../uiConfig";
import LocationImage from "../../assets/img/location.png";
import LocationImage1 from "../../assets/img/location1.png";
import location_img from "../../assets/img/locationSlider.png";
import Icons from "../Icons";
import recent_location from "../../assets/img/recent_location.png";
import active_recent_location_festivetreats from "../../assets/img/active_recent_location1.png";
import active_recent_location from "../../assets/img/active_recent_location.png";
import select_recent_location from "../../assets/img/select_recent_location.png";
import select_recent_location_festive_treats from "../../assets/img/select_recent_location1.png";
import recomend_location from "../../assets/img/recomend_location.png";
// import active_recomend_location from "../../assets/img/recom_location.png";
import SearchIcon from "../../assets/img/searchIcon.png";
import SearchIcon1 from "../../assets/img/searchIcon1.png";
import SearchIconWhite from "../../assets/img/search_white.png";
import search_head from "../../assets/img/search_head.png";
import ham_menu from "../../assets/img/ham_menu.png";
import ham_menu_festive from "../../assets/img/ham_menu1.png";
import cancel_icon from "../../assets/img/cancel-icon.png";
import heartIcon from "../../assets/img/heartIcon.png";
import hearticon_wishlist from "../../assets/img/wishlist_fff.png";
import cancel from "../../assets/img/cancel.png";
import smartbuy from "../../assets/img/smartbuy.png";
import hdfc_mbl from "../../assets/img/hdfc_mbl_logo.png";
import active_search from "../../assets/img/active-search.png";
import SearchIconGray from "../../assets/img/search-gray.png";
import smart_buy_logo from "../../assets/img/smart_buy_logo.png";
import smart_buy_active from "../../assets/img/smart_buy_active.png";
// import hdfc_mbl_logo from "../../assets/img/HDFC-Bank-logo.png";
import my_acc_icon from "../../assets/img/my_acc_icon.png";
import red_arrow from "../../assets/img/red_arrow.png";
import pow_by_maya from "../../assets/img/pow_by_maya.png";
import festiveHDFC from '../../assets/img/HDFC-Bank-logo.png'
import {
  arabicContent,
  arabicHeaderMenu,
  tokenHeader
} from "../../constants/index";
import TasteCollectionPopup from "../../components/TasteCollectionPopup";
import config from "../../service/config";
import HeaderMobile from "../HeaderMobile";
import LoginPopup from "../../components/LoginPopup";
import { randomTokenGen } from "../../service";
import { getRecentCity } from "../../service";
import {notificationFunc,requestNotificationPermission} from '../BrowserNotifications'
import "./style.scss";

import Sticky from "react-stickynode";

// Matomo 
var MatomoTracker = require('matomo-tracker');
var matomo = new MatomoTracker(config.siteId, config.siteURL);

const imgPath =
  "/images/" + uiConfig.config.whitelabel.clientName + "_logo.png";
const imgHeight = uiConfig.config.whitelabel.logo.height;
const imgWidth = uiConfig.config.whitelabel.logo.width;
var logoMatch = {
  maya: MayaLogo,
  adib: AdibLogo,
  hdfc: uiConfig.config.whitelabel.festiveTreats ? festiveHDFC : HdfcLogo,
};
var locationImage = {
  maya: LocationImage,
  hdfc: uiConfig.config.whitelabel.festiveTreats ? LocationImage: LocationImage1
};
var SearchIconMatch = {
  maya: SearchIcon,
  hdfc: uiConfig.config.whitelabel.festiveTreats ? SearchIcon:SearchIcon1
};
var heartIconMatch = {
  maya: heartIcon,
  hdfc: uiConfig.config.whitelabel.festiveTreats ? heartIcon:hearticon_wishlist
};


class NewHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryValue:
        localStorage.getItem("language") &&
        localStorage.getItem("language") == "EN"
          ? "english"
          : "arabic",
      country: "",
      searchCountry: "",
      selectedCity: null,
      searchValue: "",
      invisible: false,
      width: 0,
      menuOpen: false,
      value: "",
      suggestions: [],
      countryListUpdated: [],
      typeAhead: [],
      suggestionSelected: false,
      notificationOpen: false,
      isCountryModalOpen: false,
      filteredCountryList: this.props.countryList,
      showGps: true,
      loginpopup: false,
      recentLocation: [],
      language: localStorage.getItem("language") || "EN",
      countryClicked: localStorage.getItem("selectCountry") ? true : false,
      isProfile: false,
      height: 0,
      showTcPopup: false,
    };

    this.watchID = null;
    this.textInput = React.createRef();
    this.locationRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside(event) {
    if (
      this.locationRef.current &&
      !this.locationRef.current.contains(event.target)
    ) {
      this.closeCountryModal();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  async componentDidMount() {
    this.setState({ language: localStorage.getItem("language") || "EN" });
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    document.addEventListener("click", this.handleClickOutside, true);
    if (this.props.countryList && this.props.countryList.length > 0) {
      this.setState({
        filteredCountryList: this.props.countryList
      });
      var countries = this.props.countryList;
      var newCountryList = [];
      countries.map((country, index) => {
        if (!country.custom) {
          newCountryList.push(country);
        }
      });
      var newcountry2 = [];
      newcountry2.push({ custom: true });
      newCountryList.map((country2, index) => {
        newcountry2.push(country2);
      });
      newcountry2.push({
        label: "Near me",
        value: this.state.language === "EN" ? "Near me" : "بالقرب مني"
      });
      const countryList = newcountry2;
      this.setState({ countryListUpdated: newcountry2 });
      this.watchID = navigator.geolocation.watchPosition((position) => {
        localStorage.setItem(
          "currentLocation",
          JSON.stringify({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          })
        );
      });
      var browserLocation = localStorage.getItem("currentLocation");
      var latlong = JSON.parse(browserLocation);
      var mapValue = {};
      var loc = "";
    }
    setTimeout(() => {
      this.props.updateAuth();
    }, 1000);
    if (config.notification) {
      if (Notification.permission === "granted" && !localStorage.getItem("notification-consent")) {
        notificationFunc();
      } else{
        requestNotificationPermission();
      }
    }
    localStorage.setItem("keywordsearchcount", 1);
  }

  componentDidUpdate(prevProps) {
    let { isProfile } = this.state;
    var countries = this.props.countryList;

    let val = window.location.pathname.split("/");
    if (
      val &&
      val.length > 0 &&
      (val[2] === "profile" || val[2] === "wishlisted") &&
      isProfile === false
    ) {
      this.setState({ isProfile: true });
    } else if (
      val &&
      val.length > 0 &&
      val[2] !== "profile" &&
      val[2] !== "wishlisted" &&
      isProfile === true
    ) {
      this.setState({ isProfile: false });
    }
    if (this.props.countryList && this.props.countryList.length > 0) {
      var newCountryList = [];
      if (countries) {
        this.watchID = navigator.geolocation.watchPosition((position) => {
          localStorage.setItem(
            "currentLocation",
            JSON.stringify({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            })
          );
        });
        countries.map((country, index) => {
          if (!country.custom) {
            newCountryList.push(country);
          }
        });
        var newcountry2 = [];
        newcountry2.push({ custom: true });
        newCountryList.map((country2, index) => {
          newcountry2.push(country2);
        });
        newcountry2.push({
          label: "Near me",
          value: this.state.language === "EN" ? "Near me" : "بالقرب مني"
        });
        const countryList = newcountry2;

        var mapValue = {};

        if (navigator.geolocation && navigator.geolocation.getCurrentPosition) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              if (!this.state.countryClicked) {
                // setTimeout(() => {
                if (!JSON.parse(localStorage.getItem("currentLocation"))) {
                  localStorage.setItem(
                    "currentLocation",
                    JSON.stringify({
                      latitude: position.coords.latitude,
                      longitude: position.coords.longitude
                    })
                  );
                }
                var browserLocation = localStorage.getItem("currentLocation");
                var latlong = JSON.parse(browserLocation);
                // console.log(
                //   "latlong",
                //   latlong,
                //   JSON.parse(localStorage.getItem("currentLocation"))
                // );
                let locationPermission = localStorage.getItem(
                  "location-permission"
                );
                if (!locationPermission) {
                  localStorage.setItem(
                    "selectFirstCountry",
                    JSON.stringify({
                      label: "Near me",
                      value: "Near me",
                      geoCode: {
                        latitude: latlong?.latitude,
                        longitude: latlong?.longitude
                      }
                    })
                  );
                  localStorage.setItem(
                    "selectCountry",
                    JSON.stringify({
                      label: "Near me",
                      value: "Near me",
                      geoCode: {
                        latitude: latlong?.latitude,
                        longitude: latlong?.longitude
                      }
                    })
                  );
                  localStorage.setItem("location-permission", true);

                  mapValue = {
                    label: "Near me",
                    value: "Near me",
                    geoCode: {
                      latitude: latlong?.latitude,
                      longitude: latlong?.longitude
                    }
                  };
                  if (this.state.country !== mapValue.value) {
                    this.setState(
                      {
                        country: mapValue.value,
                        selectedCity: mapValue,
                        searchCountry: mapValue.label,
                        countryListUpdated: newcountry2
                      },
                      () => {
                        if (this.props.getRecentSearchData) {
                          this.props.getRecentSearchData();
                          getCampaigns()
                          .then(res => {
                            if (res && res.campaigns && res.campaigns.length > 0) {
                              eventBus.dispatch('updatebanner',res.campaigns )
                            } else {
                              eventBus.dispatch('updatebanner',[] )
                            }
                           })
                        }
                      }
                    );
                  }
                }
                // }, 1000);
              }
            },
            (error) => {
              if (!this.state.countryClicked  && this.props.activeMenu !== "visa") {
                localStorage.setItem(
                  "selectFirstCountry",
                  JSON.stringify(countryList[1])
                );
                localStorage.setItem(
                  "selectCountry",
                  JSON.stringify(countryList[1])
                );
                localStorage.setItem("location-permission", false);
                mapValue = countryList[1];
                if (this.state.country !== mapValue.value) {
                  this.setState(
                    {
                      country: mapValue.value,
                      selectedCity: mapValue,
                      searchCountry: mapValue.label,
                      countryListUpdated: newcountry2
                    },
                    () => {
                      if (this.props.getRecentSearchData) {
                        this.props.getRecentSearchData();
                        getCampaigns()
                        .then(res => {
                          if (res && res.campaigns && res.campaigns.length > 0) {
                            eventBus.dispatch('updatebanner',res.campaigns )
                          } else {
                            eventBus.dispatch('updatebanner',[] )
                          }
                         })
                      }
                    }
                  );
                }
              }
            }
          );
        }

        if (!deepEqual(prevProps, this.props)) {
          if (localStorage.getItem("selectCountry") !== undefined) {
            const mapValueNew = JSON.parse(
              localStorage.getItem("selectCountry")
            );
            if (mapValueNew) {
              this.setState({
                country: mapValueNew.value,
                selectedCity: mapValueNew,
                searchCountry: mapValueNew.label,
                countryListUpdated: newcountry2
              });
            }
          }
        }
      }
    }
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  /* Onclick Function */
  _onChangeSelect = (opt) => {
    localStorage.setItem("language", opt.value === "english" ? "EN" : "AR");
    this.setState({
      countryValue: opt ? opt.value : ""
    });
    matomo.track({
      url: window.location.href,
      e_c: localStorage.getItem("TOKEN"),
      e_a: "CHANGE_LANGUAGE",
      e_n: opt.value === "english" ? "EN" : "AR"
    });

    //   window.location.href = '/home';
    setTimeout(() => {
      this.props.history.go(0);
    }, 100);
  };

  showLocation = () => {
    if (localStorage.getItem("location-permission") === "true") {
      this.setState({ country: "Near me" });
      const { getHomeList, history } = this.props;
      var browserLocation = localStorage.getItem("currentLocation");
      var latlong = JSON.parse(browserLocation);
      var opt = {
        label: "Near me",
        value: "Near me",
        geoCode: {
          latitude: latlong?.latitude,
          longitude: latlong?.longitude
        }
      };
      this.setState({
        country: opt ? opt.value : "",
        searchCountry: opt ? opt.label : "",
        selectedCity: opt,
        filteredCountryList: this.props.countryList
      });
      matomo.track({
        url: window.location.href,
        e_c: opt.value,
        e_a: 'LOCATION',
      });
      if (this.state.language === "EN") {
        localStorage.setItem(
          "selectCountry",
          JSON.stringify({
            label: "Near me",
            value: "Near me",
            geoCode: {
              latitude: latlong?.latitude,
              longitude: latlong?.longitude
            }
          })
        );
      } else {
        localStorage.setItem(
          "selectCountry",
          JSON.stringify({
            label: arabicContent.nearMe,
            value: arabicContent.nearMe,
            geoCode: {
              latitude: latlong?.latitude,
              longitude: latlong?.longitude
            }
          })
        );
      }
      getCampaigns(opt)
      .then(res => {
        if (res && res.campaigns && res.campaigns.length > 0) {
          eventBus.dispatch('updatebanner',res.campaigns )
        } else {
          eventBus.dispatch('updatebanner',[] )
        }
       })
      history.push(`/home`);
      this.props.setActiveMenu(sessionStorage.getItem("actMenu") || "home");
    } else {
      alert("Geolocation is not enabled. Please enable to use this feature");
    }
  };

  getRecentLocation() {
    getRecentCity().then((data) => {
      if (data.length > 0) {
        this.setState({ recentLocation: data });
      }
    });
  }

  _onChangeCountrySelect = async (opt) => {
    const { getHomeList, history } = this.props;
    this.closeMenu();
    this.setState({
      countryClicked: true
    });
    if (opt && opt.label) {
      this.setState({
        country: opt ? opt.value : "",
        searchCountry: opt ? opt.label : "",
        selectedCity: opt,
        filteredCountryList: this.props.countryList
      });
      // if(this.state.language==="EN") {
      localStorage.setItem("selectCountry", JSON.stringify(opt));
      //  } else {
      //   localStorage.setItem('selectCountryArabic',JSON.stringify(opt));
      //  }
      matomo.track({
        url: window.location.href,
        e_c: opt.value,
        e_a: 'LOCATION',
      });
      this.closeCountryModal();
      getCampaigns()
       .then(res => {
        if (res && res.campaigns && res.campaigns.length > 0) {
          eventBus.dispatch('updatebanner',res.campaigns )
        } else {
          eventBus.dispatch('updatebanner',[] )
        }
       }).catch((err) => {
        console.error(err)
      })
      if (history) {
        history.push(`/home`);
        this.props.setActiveMenu(sessionStorage.getItem("actMenu") || "home");
      }
    } else {
      this.closeCountryModal();
      if (navigator.geolocation) {
        this.showLocation();
      }
    }
  };

  /* _handleSelect = (opt) => {
    const { history } = this.props;
    this.setState({ searchValue: opt ? opt.value : "" });
    history.push("/home/search", opt.value);
  };

  filterOptions = (inputValue) => {
    return searchOptions.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  _loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(this.filterOptions(inputValue));
    }, 1000);
  };  */

  _onClickItem = () => {
    this.setState({ invisible: true });
    // disableScroll.on();
  };

  _onClickSearch = () => {
    let { invisible } = this.state;
    if (invisible) {
      document.body.style.minHeight = null;
      document.documentElement.style.overflow = null;
      document.body.style.overflow = null;
    } else {
      window.scrollTo(0, 0);
      document.documentElement.style.overflow = "hidden";
      document.body.style.overflow = "hidden";
      document.body.style.minHeight = "100vh";
    }
    this.setState({ invisible: !invisible });
  };

  _onRemoveItem = () => {
    this.setState({ invisible: false, value: "", suggestions: [] });
    disableScroll.off();
    document.documentElement.style.overflowY = "auto";
    document.body.style.overflowY = "auto";
  };

  _notificationPopup = () => {
    this.setState({ notificationOpen: !this.state.notificationOpen });
  };

  _onkeyDown = (ev) => {
    if (ev && ev.keyCode === 27) {
      this._onRemoveItem();
    }
  };

  closeMenu = () => {
    document.body.style.minHeight = null;
    document.documentElement.style.overflow = null;
    document.body.style.overflow = null;
    this.setState({ menuOpen: false });
  };

  onOpenMenu = () => {
    window.scrollTo(0, 0);
    document.documentElement.style.overflow = "hidden";
    document.body.style.overflow = "hidden";
    document.body.style.minHeight = "100vh";
    this.setState({ menuOpen: true, invisible: false });
  };

  _onClickProfile = () => {
    const { history } = this.props;
    history.push(`/home/profile`, "myProfile");
  };

  _onClickClaimed = () => {
    const { history } = this.props;
    history.push(`/home/profile`, "claimed");
  };

  _onClickRedemption = () => {
    const { history } = this.props;
    history.push("/home/profile", "redemption");
  };

  _onClickHome = () => {
    const { history } = this.props;
    this.setState({
      suggestions: [],
      invisible: false,
      value: ""
    });
    // this.props.setActiveMenu("home")
    this.props.onChangeTab("home");
    let userId = localStorage.getItem("USERID");
    if (userId) {
      history.push({ pathname: "/home", userId: userId });
    } else {
      history.push(`/home`, "home");
    }
  };

  _onClickTasteProfile = () => {
    const { history } = this.props;
    history.push("/home/profile", "tasteProfile");
  };

  _onClickProfileItem = (activeItem) => {
    const { history } = this.props;
    this.closeMenu();
    sessionStorage.setItem("currPage", activeItem);
    this.props.setActiveItem(activeItem);
    history.push("/home/profile", activeItem);
    this.updateWindowDimensions();
    matomo.track({
      url: window.location.href,
      e_c: "my account",
      e_a: 'MENU_CLICK',
      e_n: activeItem,
    });
  };

  onLogout = () => {
    const { history } = this.props;
    document.documentElement.style.overflowY = "auto";
    document.body.style.overflowY = "auto";
    localStorage.clear();
    sessionStorage.clear();
    accessTokenHelper.del();
    history.push("/login");
  };

  pushToHome = () => {
    this.props.onChangeTab("home");
    this.props.setActiveItem("home");
    history.push("/home");
  };

  onLogoutHDFC = () => {
    this.closeMenu();
    localStorage.setItem("isLoggedin","false")
    localStorage.setItem("isLoggedin","false")
    const token = randomTokenGen(16);
    localStorage.setItem("TOKEN", token);
    localStorage.setItem('USERID',token);
    localStorage.removeItem('allOffers-wishlist');
    // this.props.updateAuth();
    this.props.setActiveItem("home");
    history.push("/login");
  };
  onLoginHDFC = () =>{
    this.closeMenu();
   this.props._openLogin();
  //  this.props.setActiveItem("home");
  //  history.push("/home");
    // this.setState({ isPopoverOpen: false });
  }
  onLoginHDFC = () =>{
    this.closeMenu();
   this.props._openLogin();
  //  this.props.setActiveItem("home");
  //  history.push("/home");
    // this.setState({ isPopoverOpen: false });
  }
  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      suggestionSelected: false
    });
    this.onSuggestionsFetchRequested(newValue);
  };

  onFocus = (e) => {
    e.stopPropagation();
    if (document.getElementById("header-content") !== null) {
      var headerWidth = document.getElementById("header-content").offsetWidth;
      var searchwidth = headerWidth / 1.2;
      document.getElementById("searchDiv").style.width = searchwidth + "px";
      this.setState({ invisible: true });
      // disableScroll.on();
      document.documentElement.style.overflowY = "hidden";
      document.body.style.overflowY = "hidden";
    } else {
      document.documentElement.style.overflowY = "hidden";
      document.body.style.overflowY = "hidden";
    }
  };

  //When click outside the suggestion section will hide
  onCloseSearchBar = () => {
    this.setState({ invisible: false, value: "", suggestions: [] });
    disableScroll.off();
    document.documentElement.style.overflowY = "auto";
    document.body.style.overflowY = "auto";
  };

  //Language Change - Mobile View
  onLanguageChange = () => {
    let lang =
      this.state.language === "EN"
        ? "AR"
        : this.state.language === "AR"
        ? "EN"
        : "";
    localStorage.setItem("language", lang);
    this.setState({
      countryValue: lang === "EN" ? "english" : lang === "AR" ? "arbic" : ""
    });
    matomo.track({
      url: window.location.href,
      e_c: localStorage.getItem("TOKEN"),
      e_a: "CHANGE_LANGUAGE",
      e_n: lang
    });
    //  window.location.href = '/home';
    setTimeout(() => {
      this.props.history.go(0);
    }, 100);
  };

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = (value) => {
    if (value.length >= 2) {
      const { typeAhead } = this.state;
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;
      return inputLength === 0 ? [] : typeAhead;
    } else {
      return [];
    }
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue = (suggestion) => suggestion.name;

  // Use your imagination to render suggestions.
  renderSuggestion = (suggestion) => <div>{suggestion.name}</div>;

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = (value) => {
    const { selectedCity } = this.state;
    if (value.length >= 1) {
      if (this.getTypeHeadItem) {
        this.getTypeHeadItem(selectedCity, value);
      }
    }
  };

  openCountry = (isCountryModalOpen) => {
    this.getRecentLocation();
    this.onCloseSearchBar();
    //this.closeMenu();
    const minWidth = 933;
    this.setState(
      { isCountryModalOpen, filteredCountryList: this.props.countryList },
      () => {
        document.documentElement.style.overflowY = "hidden";
        document.body.style.overflowY = "hidden";
        if (minWidth <= this.state.width) {
          document.body.style.padding = "0px";
        }
      }
    );
  };

  getTypeHeadItem = (city, query) => {
    var activeMenu = "all";

    if (
      this.props.activeMenu == "home" ||
      this.props.activeMenu == "allOffers" ||
      this.props.activeMenu == ""
    ) {
      activeMenu = "all";
    } else {
      activeMenu = this.state.activeMenu;
    }
    getTypeHead(activeMenu, city, query)
      .then((res) => {
        // if (res && res.response && res.response && !this.state.suggestionSelected) {
        //   this.setState({ typeAhead: res.response }, () => {
        //     this.setState({ suggestions: this.getSuggestions(query) })
        //   });
        // }
        if (res && !this.state.suggestionSelected) {
          this.setState({ typeAhead: res }, () => {
            this.setState({ suggestions: this.getSuggestions(query) });
          });
        }
      })
      .catch((err) => alert(getError(err)));
  };

  onSuggestionsFetchRequest = () => {};

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    const { value } = this.state;
    if (value === "") {
      this.setState({
        suggestions: []
      });
    }
  };

  oneSuggestionSelected = async (event, { suggestion }) => {
    if (suggestion.type == "item") {
      const { history } = this.props;
      const { emptyTypeAhead } = this.props;
      if (event && event.keyCode !== 13) {
        this.setState({
          invisible: false,
          value: "",
          suggestionSelected: true,
          suggestions: []
        });
        disableScroll.off();
        if (history) {
          this.onSuggestionsClearRequested();
          await this.props.setActiveMenu("");
          emptyTypeAhead && emptyTypeAhead();
          history.push(`/home/merchant/${suggestion.id}`);
          this.textInput.current.blur();
        }
      }
      document.documentElement.style.overflowY = "auto";
      document.body.style.overflowY = "auto";
    } else {
      this.setState({ suggestionSelected: true });
      this.onClickRecentSearch(suggestion.name);
    }
    this.props.getRecentSearchData();
    localStorage.removeItem("selectedFilters");
  };

  onClickRecentSearch = (suggestion) => {
    const { history } = this.props;
    this.props.getRecentSearchData();
    this.setState({ invisible: false, value: "", suggestions: [] });
    disableScroll.off();
    history.push("/home/search", {
      searchKeyword: suggestion,
      selectedCity: this.state.selectedCity,
      clicked: Number(localStorage.getItem("keywordsearchcount")) + 1
    });
    localStorage.setItem(
      "keywordsearchcount",
      Number(localStorage.getItem("keywordsearchcount")) + 1
    );
    this.textInput.current.blur();
    document.documentElement.style.overflowY = "auto";
    document.body.style.overflowY = "auto";
    localStorage.removeItem("selectedFilters");
  };

  _onkeyDownContent = (ev) => {
    const { history } = this.props;
    const { emptyTypeAhead } = this.props;
    if (ev && ev.keyCode === 13 && ev.target.value.trim()) {
      this.setState({
        invisible: false,
        value: "",
        suggestionSelected: true,
        suggestions: []
      });
      history.push("/home/search", {
        searchKeyword: ev.target.value,
        selectedCity: this.state.selectedCity,
        clicked: Number(localStorage.getItem("keywordsearchcount")) + 1
      });
      localStorage.setItem(
        "keywordsearchcount",
        Number(localStorage.getItem("keywordsearchcount")) + 1
      );
      this.textInput.current.blur();
      document.documentElement.style.overflowY = "auto";
      document.body.style.overflowY = "auto";
      this.props.getRecentSearchData();
    } else if (ev && ev.keyCode === 9) {
      emptyTypeAhead && emptyTypeAhead();
      this.setState({ invisible: false, value: "", suggestions: [] });
      document.documentElement.style.overflowY = "auto";
      document.body.style.overflowY = "auto";
    } else if (ev && ev.keyCode === 8 && ev.target.value.length <= 1) {
      emptyTypeAhead && emptyTypeAhead();
      this.setState({ invisible: true, value: "", suggestions: [] });
    }
  };

  _renderCountryData = (title) => {
    const { filteredCountryList, language, country } = this.state;
    const { recentLocation } = this.props;

    if (title === "recent locations") {
    let minWidth = 933;     
      let locationVal = JSON.parse(localStorage.getItem("selectCountry")) || {};
      if (minWidth >= this.state.width) {
        return (
          <> 
            <div className="recent-location mbl" style={{height: 'auto'}}>             
              <div className="gps-title" style={{marginLeft: 0}}>{title}</div>
              {recentLocation.map((country) => (
                <div className="box mbl">
                  <div
                    className="recent-gps-text mbl"
                    onClick={() => this._onChangeCountrySelect(country)}
                  >
                    <div
                      className="sub-text"
                      style={{
                        fontWeight:
                          country?.value === locationVal?.value ? 600 : 400
                      }}
                    >
                      {country.label}
                    </div>
                    {country?.value === locationVal?.value && (
                      <img
                        src={uiConfig.config.whitelabel.festiveTreats ? select_recent_location_festive_treats :active_search}
                        style={{ width: "18px", height: "18px" }}
                        className="bg-selected_location"
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </>
        )
      }
      else{
        return (
          <>
            <div className="recent-location">
              <div className="gps-title">{title}</div>
              {recentLocation.map((country, index) => {
                return (
                  country && (
                    <div className="box">
                      {this.state?.country.toLowerCase() ===
                      country?.label.toLowerCase() ? (
                        <>
                          <div>
                            <img
                              src={uiConfig.config.whitelabel.festiveTreats? active_recent_location_festivetreats :active_recent_location}
                              width={18}
                              height={18}
                            />{" "}
                          </div>
                          <div
                            className="recent-gps-text"
                            onClick={() => this._onChangeCountrySelect(country)}
                          >
                            <div
                              className="sub-text"
                              style={{
                                color:
                                  uiConfig?.config?.whitelabel?.header?.navbar
                                    ?.categoryColorActive
                              }}
                            >
                              {country?.label}
                            </div>
                            <div className="support-text active" style={{
                                  color:
                                    uiConfig?.config?.whitelabel?.header?.navbar
                                      ?.categoryColorActive
                                }}>
                              {country?.stateValue}
                            </div>
                          </div>
                          <img
                            src={uiConfig.config.whitelabel.festiveTreats? select_recent_location_festive_treats : select_recent_location}
                            width={16}
                            height={12}
                          />
                        </>
                      ) : (
                        <>
                          <div>
                            <img src={recent_location} width={18} height={18} />{" "}
                          </div>
                          <div
                            className="recent-gps-text"
                            onClick={() => this._onChangeCountrySelect(country)}
                          >
                            <div className="sub-text">{country?.label}</div>
                            <div className="support-text">
                              {country?.stateValue}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )
                );
              })}
            </div>
          </>
        );
      }
    } else {
      let minWidth = 933;
      let locationVal = JSON.parse(localStorage.getItem("selectCountry")) || {};
      if (minWidth >= this.state.width) {
        return (
          <>
            <div className="recent-location mbl">           
              <div className="gps-title" style={{marginLeft: 0}}>{title}</div>
              {filteredCountryList.map((country) => (
                <div className="box mbl">
                  <div
                    className="recent-gps-text mbl" style={{marginLeft: '0px'}}
                    onClick={() => this._onChangeCountrySelect(country)}
                  >
                    <div
                      className="sub-text"
                      style={{
                        fontWeight:
                          country?.value === locationVal?.value ? 600 : 400
                      }}
                    >
                      {country.label}
                    </div>
                    {country?.value === locationVal?.value && (
                      <img
                        src={uiConfig.config.whitelabel.festiveTreats ? select_recent_location_festive_treats :active_search}
                        style={{ width: "18px", height: "18px" }}
                        className="bg-selected_location"
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </>
        );
      }
      return (
        <>
          <div className="recent-location">
            <div className="gps-title">{title}</div>
            {filteredCountryList.map((country) => (
              <div className="box">

                  <div>
                  {title === "recent locations" ? (
                    <img src={recent_location} width={18} height={18} />
                  ) : (
                    <img src={recomend_location} width={18} height={18} />
                  )}{" "}
                 </div>
                                  <div
                            className="recent-gps-text"
                        onClick={() => this._onChangeCountrySelect(country)}>
                        <div className="sub-text">{country.label}</div>
                          <div className="support-text">{country?.stateValue}</div>
                          </div>
              </div>
            ))}
          </div>
        </>
      );
    }
  };

  closeCountryModal = () => {
    this.setState(
      {
        isCountryModalOpen: false,
        filteredCountryList: this.props.countryList,
        showGps: true
      },
      () => {
        document.documentElement.style.overflowY = "unset";
        document.body.style.overflowY = "unset";
        // document.body.style.paddingRight = "0px";
      }
    );
  };

  searchCountry = (val) => {
    const { countryList } = this.props;
    //  if(val.length>3)
    // {
    let filteredCountryList = countryList.filter(
      (data) =>
        data.value?.toLowerCase().includes(val.toLowerCase()) ||
        data?.stateValue?.toLowerCase().includes(val.toLowerCase())
    );

    this.setState({
      filteredCountryList
    });
    if (val.length === 0) {
      this.setState({ showGps: true });
    } else {
      this.setState({ showGps: false });
    }
    // }
    // else{
    //   this.setState({
    //     filteredCountryList:[]
    //   })
    // }
  };

  _renderCountryModal() {
    const minWidth = 933;
    const { settings, showGps, language, country } = this.state;
    const { recentLocation } = this.props;
    // Removed notification/settings Icon for mobile view
    if (minWidth >= this.state.width) {
      return (
        <div
          className={`col-md-12 p-0 not-loc-modal animate-slider fadeInLeft`}
        >
          <div
            className={`col-md-4 p-0 main mbl ${
              language === "AR" && "main-arabic"
            }`}
            ref={this.locationRef}
          >
            <div className="not-cont col-md-12 p-0">
              <div className="col-md-12 header p-0 flex justify-between">
                <div className="title">Location</div>
                {/* <div
                  className="cancel-icn flex-center cursor"
                  onClick={this.closeCountryModal}
                >
                  <Icon fill="#000" width={15} height={15} name="cancel" />
                </div> */}
                <div
                  className="h-pointer flex-center abs-close"
                  onClick={this.closeCountryModal}
                >
                  <Icon name="cancel" fill="#000" width={12} height={11} />
                </div>
              </div>
              <div className="title-desc">
                Choose the location to explore the offers & deals.
              </div>
              <div className="search-location mbl">
                <img className="search-icn" src={SearchIconGray} />
                {/* <input type="text" placeholder={language==="EN"?"Search for area, street name..":arabicContent.searchLoc} onChange={(e)=>this.searchCountry(e.target.value)}/> */}
                <input
                  type="text"
                  placeholder={
                    language === "EN"
                      ? "Search location"
                      : arabicContent.searchCity
                  }
                  onChange={(e) => this.searchCountry(e.target.value)}
                />
              </div>

              {showGps && (
                <div className="gps mbl">
                  <div className="box">
                    <div>
                      <Icons name="location" width={18} height={18} />
                    </div>
                    <div
                      className="gps-text"
                      onClick={() => this._onChangeCountrySelect()}
                    >
                      <div className="flex-center justify-between">
                        <div
                          className="sub-text gps-sub-text"
                          style={
                            country?.toLowerCase() === "near me"
                              ? {
                                  color:
                                    uiConfig?.config?.whitelabel?.header?.navbar
                                      ?.categoryColorActive
                                }
                              : {}
                          }
                        >
                          {language === "EN"
                            ? "Get current location"
                            : arabicContent.getCurrentLoc}
                        </div>
                        {country?.toLowerCase() === "near me" && (
                          <img
                            src={uiConfig.config.whitelabel.festiveTreats ? select_recent_location_festive_treats :active_search}
                            width={18}
                            height={18}
                            className="bg-selected_location"
                          />
                        )}
                      </div>
                      <div
                        className={
                          "support-text mbl" +
                          (country?.toLowerCase() === "near me"
                            ? " active"
                            : " ")
                        }
                        style={{color:
                          uiConfig?.config?.whitelabel?.header?.navbar
                            ?.categoryColorActive}}
                      >
                        {language === "EN" ? "Using GPS" : arabicContent.gps}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div style={this.state.width<= 933 ? {height: 'calc(100% - 236px)',overflow : 'auto'} : {}}>
               {recentLocation?.length > 0 &&
                this._renderCountryData("recent locations")}
              {this._renderCountryData(
                language === "EN"
                  ? "Recommended Locations"
                  : arabicContent.recommendLoc
              )}</div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="col-md-12 p-0 not-loc-modal">
        <div
          className={`col-md-4 p-0 main ${language === "AR" && "main-arabic"}`}
          ref={this.locationRef}
        >
          <div className="not-cont col-md-12 p-0">
            <div className="col-md-12 header p-0 flex-start">
              <div
                className="cancel-icn flex-center cursor"
                onClick={() => this.closeCountryModal()}
              >
                <Icon fill="#000" width={15} height={15} name="cancel" />
              </div>
            </div>
            <div className="search-location">
              {/* <input type="text" placeholder={language==="EN"?"Search for area, street name..":arabicContent.searchLoc} onChange={(e)=>this.searchCountry(e.target.value)}/> */}
              <input
                type="text"
                placeholder={
                  language === "EN"
                    ? "Search for city"
                    : arabicContent.searchCity
                }
                onChange={(e) => this.searchCountry(e.target.value)}
              />
            </div>

            {showGps && (
              <div className="gps">
                <div className="box">
                  <div>
                    <img src={location_img} width={18} height={18} />
                  </div>
                  <div
                    className="gps-text"
                    onClick={() => this._onChangeCountrySelect()}
                  >
                    <div
                      className="sub-text"
                      style={
                        country?.toLowerCase() === "near me"
                          ? {
                              color:
                                uiConfig?.config?.whitelabel?.header?.navbar
                                  ?.categoryColorActive
                            }
                          : {}
                      }
                    >
                      {language === "EN"
                        ? "Get current location"
                        : arabicContent.getCurrentLoc}
                    </div>
                    <div
                      className={
                        "support-text" +
                        (country?.toLowerCase() === "near me" ? " active" : " ")
                      }
                      style={{ color:
                        country?.toLowerCase() === "near me" ? uiConfig?.config?.whitelabel?.header?.navbar
                          ?.categoryColorActive:"#000"}}
                    >
                      {language === "EN" ? "USING GPS" : arabicContent.gps}
                    </div>
                  </div>
                  {country?.toLowerCase() === "near me" && (
                    <img src={uiConfig.config.whitelabel.festiveTreats? select_recent_location_festive_treats : select_recent_location} width={24} height={24} />
                  )}
                </div>
              </div>
            )}

            {recentLocation?.length > 0 &&
              this._renderCountryData("recent locations")}
            {this._renderCountryData(
              language === "EN"
                ? "Recommended Locations"
                : arabicContent.recommendLoc
            )}
          </div>
        </div>
      </div>
    );
  }

  /* Other Render Functions */
  _renderMenuItems(title, onClick) {
    return (
      <div className="col-md-12 menu-item-mobile cursor p-0" onClick={onClick}>
        <h5 className="profile-info mb-16 mt-0">{title}</h5>
      </div>
    );
  }

  setTcPopup = (value) => {
    if (!value) {
      document.body.style.overflowY = 'auto';
      document.documentElement.style.overflowY = 'auto'
    }
    this.setState({ showTcPopup: value });
  };

  _renderNormalView() {
    const {
      countryValue,
      country,
      invisible,
      value,
      suggestions,
      isCountryModalOpen,
      language
    } = this.state;
    const {
      onChangeTab,
      activeMenu,
      history,
      countryList,
      categoryList,
      _openLogin
    } = this.props;
    const minWidth = 933;
    //  console.log("country",country)
    const inputProps = {
      placeholder:
        language === "EN"
          ? "Try “Cafe” or “Retail stores”"
          : arabicContent.trycafe,
      value,
      onChange: this.onChange,
      onBlur: this.onBlur,
      onFocus: this.onFocus,
      onKeyDown: this._onkeyDownContent,
      ref: this.textInput,
      style: {
        backgroundColor: uiConfig.config.whitelabel.header.bg,
        color: uiConfig.config.whitelabel.header.locationText
      }
    };

    const handleClickWhistList = () => {
      if (localStorage.getItem("USERID") === null) {
        _openLogin();
        localStorage.setItem("interactionFrom", "Wishlist");
      } else {
        //this.props.setActiveMenu(" ");
        history.push("/home/wishlisted");
      }
    };
    let categoriesList =
      categoryList !== null
        ? categoryList
        : localStorage.getItem("catList")
        ? JSON.parse(localStorage.getItem("catList"))
        : [];
    if (minWidth < this.state.width) {
      return (
        <div className="col-md-12 p-0 header new_header">
          {suggestions.length !== 0 && invisible && (
            <div className="opacity-div" />
          )}
          <Sticky enabled={true} top='#header' innerZ={100}>
            <div
              className="flex header-content top_part"
              id="header-content"
              style={{ backgroundColor: uiConfig.config.whitelabel.header.bg }}
            >
              <div
                className="flex cursor"
                onClick={this._onClickHome}
                style={{
                  borderRight: `1px solid ${uiConfig.config.whitelabel.header.lineColor}`
                }}
              >
                <img
                  src={logoMatch[uiConfig.config.whitelabel.clientName]}
                  style={
                    uiConfig.config.whitelabel.clientName === "hdfc"
                    ? uiConfig.config.whitelabel.logo
                      : {}
                  }
                  className={
                    config.clientName === "HDFC"
                      ? `bazaar-header-logo-hdfc`
                      : `bazaar-header-logo${language == "AR" ? "mr-0" : ""}`
                  }
                  height="42"
                />
              </div>
              {uiConfig.config.whitelabel.clientName === "hdfc" && uiConfig.config.whitelabel.header.secondoryImage && (
                <div
                  className="flex cursor bazaar-header-smartbuy"
                  onClick={this._onClickHome}
                >
                  <img src={smartbuy} alt="smrtbuy-desktop" />
                </div>
              )}
              <div
                className="country-select p-0 cselect"
                onClick={() => this.openCountry(true)}
                style={
                  uiConfig.config.whitelabel.header.lineColor
                    ? {
                        borderLeft: `1px solid ${uiConfig.config.whitelabel.header.lineColor}`,
                        borderRight: `1px solid ${uiConfig.config.whitelabel.header.lineColor}`
                      }
                    : {}
                }
              >
                <div className="flex">
                  <div
                    className={
                      language == "EN"
                        ? "flex-center"
                        : "flex-center arabic-location-icon"
                    }
                  >
                    <Image
                      src={locationImage[uiConfig.config.whitelabel.clientName]}
                      alt="image"
                      w={15}
                      h={20}
                    />
                  </div>
                  <div
                    className="location-text ltext"
                    style={{
                      color: uiConfig.config.whitelabel.header.locationText
                    }}
                  >
                    {country}
                  </div>
                </div>

                {/* <Select
                isCountrySelect
                value={country}
                options={this.state.countryListUpdated}
                onChange={this._onChangeCountrySelect}
              /> */}
              </div>
              <div
                className={`${
                  invisible ? "half-div" : "half-div searchDiv"
                } p-0`}
                id="searchDiv"
                style={{}}
              >
                <div
                  className={
                    language == "EN"
                      ? "search-icon"
                      : "search-icon arabic-search"
                  }
                  onClick={this._onClickItem}
                >
                  {/* <i className="fa fa-search"></i> */}
                  <img
                    src={SearchIconMatch[uiConfig.config.whitelabel.clientName]}
                    width="16"
                  />
                </div>
                <div className={`${invisible ? "col-md-10" : "col-md-11"} p-0`}>
                  {/* <Select
                  isAutoComplete
                  cacheOptions
                  placeholder="Try “Cafe” or “Retail stores”"
                  defaultOptions
                  options={searchOptions}
                  loadOptions={this._loadOptions}
                  value={searchValue}
                  onChange={this._handleSelect}
                /> */}
                  <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequest}
                    onSuggestionsClearRequested={
                      this.onSuggestionsClearRequested
                    }
                    onSuggestionSelected={this.oneSuggestionSelected}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.getSuggestionValue}
                    inputProps={inputProps}
                    focusInputOnSuggestionClick={false}
                    alwaysRenderSuggestions={value === "" ? false : true}
                  />
                </div>
                {invisible && (
                  <div
                    className="flex-center search-icon search-close-button"
                    onClick={this._onRemoveItem}
                  >
                    {uiConfig.config.whitelabel.clientName === "maya" ? (
                      <Icon name="cancel" fill="#000" width={16} height={16} />
                    ) : (
                      <img src={uiConfig.config.whitelabel.festiveTreats ? cancel_icon :cancel} width="16" height="16" />
                    )}
                  </div>
                )}
              </div>
              <div
                className={`${
                  invisible
                    ? "none"
                    : language == "EN"
                    ? "headerRightDiv my_acc"
                    : "headerLeftDiv"
                }`}
                style={
                  uiConfig.config.whitelabel.header.lineColor
                    ? {
                        borderLeft: `1px solid ${uiConfig.config.whitelabel.header.lineColor}`
                      }
                    : {}
                }
              >
                <div
                  className={`${
                    invisible ? "none" : ""
                  } cursor hide-item p-0 flex-center wishlistedDiv`}
                  onClick={handleClickWhistList}
                >
                  <img
                    src={heartIconMatch[uiConfig.config.whitelabel.clientName]}
                    width="20"
                  />
                </div>
                {!(
                  config.clientName == "HDFC" || config.clientName == "MAYA"
                ) && (
                  <div
                    className={`${invisible ? "none" : ""} ${
                      language == "EN"
                        ? "p-0 hide-item mobile-country-select headerCountry"
                        : "p-0 hide-item mobile-country-select headerLeftCountry arabic-align"
                    } `}
                  >
                    <Select
                      isCustomSelect
                      value={countryValue}
                      options={languageOption}
                      onChange={this._onChangeSelect}
                    />
                  </div>
                )}
                <div
                  className={`${
                    invisible ? "none-profile" : ""
                  } p-0 hide-item mobile-profile-dropdown`}
                >
                  {config.clientName === "HDFC" ? (
                    <NewProfile
                      openLogin={this.props._openLogin}
                      userAuthed={this.props.userAuthed}
                      updateAuth={this.props.updateAuth}
                      closeLogin={this.props._closeLogin}
                      userData={this.props.userData}
                      history={history}
                      pushToHome={this.pushToHome}
                      updatePersonaldetails={this.props.updatePersonaldetails}
                      notificationItems={this.props.notificationItems}
                      saveDetailsLoader={this.saveDetailsLoader}
                      setActiveItem={this.props.setActiveItem}
                      notificationPopup={this._notificationPopup}
                      setTcPopup={this.setTcPopup}
                    />
                  ) : (
                    <NewProfile
                      openLogin={this.props._openLogin}
                      userAuthed={this.props.userAuthed}
                      closeLogin={this.props._closeLogin}
                      updateAuth={this.props.updateAuth}
                      userData={this.props.userData}
                      history={history}
                      updatePersonaldetails={this.props.updatePersonaldetails}
                      notificationItems={this.props.notificationItems}
                      saveDetailsLoader={this.saveDetailsLoader}
                      setActiveItem={this.props.setActiveItem}
                      notificationPopup={this._notificationPopup}
                      setTcPopup={this.setTcPopup}
                    />
                  )}
                </div>
              </div>
            </div>
          </Sticky>
          <div style={{height : "50px"}}>
          <Sticky
            enabled={true}
            top={64}
            innerZ={this.state.invisible ? 0 : 100}
            innerActiveClass={"sticky-desktop-nav"}
          >
            <Newnavbar
              onChangeTab={onChangeTab}
              activeMenu={activeMenu}
              categoryList={categoriesList}
            />
            <div className="bot-line p-0"></div>
          </Sticky>
          </div>
          {isCountryModalOpen && this._renderCountryModal()}
        </div>
      );
    }

    return null;
  }

  _renderMobileView() {
    const {
      suggestions,
      country,
      invisible,
      value,
      notificationOpen,
      isCountryModalOpen,
      language,
      countryValue,
      menuOpen,
      isProfile,
      height
    } = this.state;

    const {
      onChangeTab,
      activeMenu,
      history,
      countryList,
      categoryList,
      _openLogin,
      userAuthed
    } = this.props;

    const inputProps = {
      placeholder:
        language === "EN"
          ? "Try “Cafe” or “Retail stores”"
          : arabicContent.trycafe,
      value,
      onChange: this.onChange,
      onFocus: this.onFocus,
      onKeyDown: this._onkeyDownContent,
      ref: this.textInput
    };

    let displayCountry = JSON.parse(localStorage.getItem("selectCountry"));

    const handleClickWhistList = () => {
      if (localStorage.getItem("USERID") === null) {
        _openLogin();
        localStorage.setItem("interactionFrom", "Wishlist");
      } else {
        this.closeMenu();
        //this.props.setActiveMenu(" ");
        history.push("/home/wishlisted");
      }
    };

    const minWidth = 933;
    const mobileWidth = 480;
    let categoriesList =
      categoryList && categoryList !== null
        ? categoryList
        : localStorage.getItem("catList")
        ? JSON.parse(localStorage.getItem("catList"))
        : [];
    if (minWidth >= this.state.width) {
      return (
        <>
          <Sticky enabled={true} top={0} innerZ={100} enableTransforms={false}>
            <div className={"header-mobile header" + (menuOpen ? " act" : "")} style={{background:uiConfig.config.whitelabel.header.background}}>
              <div
                className="left-part cursor"
                onClick={() => {
                  this.closeMenu();
                  this._onClickHome();
                }}
              >
                {/* <div>
                <img
                  src={hdfc_mbl}
                  alt={uiConfig.config.whitelabel.clientName}
                  width={28}
                  height={28}
                />
              </div>
              <div className={"sep-line" + (menuOpen ? " act" : "")} >
              </div> */}
                  {/* <div className="flex cursor"  style={{alignItems:"center"}}>
                <div className="mobileLogo" style={{alignItems:"center"}}>
                <img
                    src={menuOpen ? logoMatch[uiConfig.config.whitelabel.clientName] : logoMatch[uiConfig.config.whitelabel.clientName]}
                    alt={uiConfig.config.whitelabel.clientName}
                    // style={{width:"183px",height:"32px"}}
                  />
                </div>
                <div style={{paddingLeft:"5px"}}>
                <img
                    src={menuOpen? fest:fest}
                    alt={uiConfig.config.whitelabel.clientName}
                  />
                </div>
              </div> */}
                <div>
                  <img
                    src={menuOpen ? smart_buy_active  : smart_buy_logo}
                    alt={uiConfig.config.whitelabel.clientName}
                    width={255}
                    // height={40}
                  />
                </div>
              </div>

              <div className={menuOpen ? "" : "right-part"}>
                {!menuOpen && (
                  <>
                    <div
                      className={"cursor search-ic" + (invisible ? " act" : "")}
                      onClick={this._onClickSearch}
                    >
                       <img src={search_head} alt="search" width={18}
                        height={18} />
                    </div>
                    {/* <div className={`${invisible ? "search-box" : "none"}`}>
                    <div className={"input-box"}>
                      <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={
                          this.onSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          this.onSuggestionsClearRequested
                        }
                        onSuggestionSelected={this.oneSuggestionSelected}
                        getSuggestionValue={this.getSuggestionValue}
                        renderSuggestion={this.getSuggestionValue}
                        inputProps={inputProps}
                      />
                      <div
                        className="close-icon cursor"
                        onClick={this._onRemoveItem}
                      >
                        <Icon
                          name="cancel"
                          fill="#000"
                          width={12}
                          height={12}
                        />
                      </div>
                    </div>
                  </div> */}
                  </>
                )}
                <div className="cursor ham-menu">
                  {menuOpen ? (
                    <img
                      src={cancel_icon}
                      alt="cancel_icon"
                      width={14}
                      height={14}
                      onClick={this.closeMenu}
                    />
                  ) : (
                    <img
                    src={uiConfig.config.whitelabel.festiveTreats? ham_menu_festive:ham_menu}
                      alt="cheese-menu"
                      onClick={this.onOpenMenu}
                      width={18}
                        height={14}
                    />
                  )}
                </div>
              </div>
              {isCountryModalOpen && this._renderCountryModal()}
            </div>
          </Sticky>

          <div className={"new-hid-menu" + (menuOpen ? "-opened" : "")}>
            <div className={"top-part" + (userAuthed ? "" : " act")}>
              {userAuthed ? (
                <div className="my-acc" style={{background:uiConfig.config.whitelabel.header.menuBackground}}>
                  <div>
                    <img src={my_acc_icon} />
                  </div>
                  <div className="cont-part">My Account</div>
                </div>
              ) : (
                <div className="login-sec cursor" onClick={_openLogin}>
                  LOGIN
                </div>
              )}
            </div>

            <div className="allow-scroll">
              <div style={{ flex: height < 700 ? 1 : 0 }}>
                <div className="loc-section">
                  <div className="each cursor" onClick={handleClickWhistList}>
                    My Wishlist
                  </div>
                  <div
                    className="each cursor"
                    onClick={() => {
                      if (userAuthed) this._onClickProfileItem("claimed");
                      else _openLogin();
                    }}
                  >
                    Claimed Offers
                  </div>
                  {
                    uiConfig?.config?.whitelabel?.tasteCollection?.show &&
                    <div
                    className="each cursor"
                    onClick={() => {
                      this.closeMenu();
                      this.setTcPopup(true);
                      document.body.style.overflowY = 'hidden';
                      document.documentElement.style.overflowY = 'hidden'
                    }}
                  >
                    Taste Preference
                  </div>}
                  <div
                    className="each cursor"
                    onClick={() => {
                      if (userAuthed) this._onClickProfileItem("Portfolio");

                      else _openLogin();

                    }}

                  >

                    Your Vouchers

                  </div>

                  <div

                    className="each cursor"

                    onClick={() => {
                      this._onClickProfileItem("appFeedback");
                    }}
                  >
                    App Feedback
                  </div>
                  <div
                    className="each cursor act"
                    onClick={() => this.openCountry(true)}
                  >
                    <div>Location</div>
                    <div className="sub-part">
                      {displayCountry?.label === "Near me"
                        ? displayCountry?.label
                        : displayCountry?.label +
                          ", " +
                          displayCountry?.stateValue}
                    </div>
                  </div>
                </div>

                <div className={"prof-section" + (userAuthed ? " act" : "")}>
                  <div
                    className="each cursor"
                    onClick={() => {
                      this._onClickProfileItem("faq");
                    }}
                  >
                    FAQs
                  </div>
                  <div
                    className="each cursor"
                    onClick={() => {
                      this._onClickProfileItem("termsAndCondition");
                    }}
                  >
                    Terms & Conditions
                  </div>
                  <div
                    className="each cursor"
                    onClick={() => {
                      this._onClickProfileItem("privacy");
                    }}
                  >
                    Privacy Policy
                  </div>
                </div>
                {userAuthed && localStorage.getItem("isLoggedin") === "true"?(
                  <div
                    className="logout-sec"
                    onClick={() => {
                      if (config.clientName === "HDFC" && userAuthed) {
                        this.onLogoutHDFC();
                      }
                    }}
                  >
                    <div className="cursor">Logout</div>
                    <div className="cursor">
                      <img src={red_arrow} style={{width:"16px"}}/>
                    </div>
                  </div>
                )  
                : 
                <div
                className="logout-sec"
                onClick={() => {
                  if (config.clientName === "HDFC" && userAuthed) {
                    this.onLoginHDFC();
                  }
                }}
              >
                <div className="cursor">Login</div>
                <div className="cursor">
                  <img src={red_arrow} style={{width:"16px"}} />
                </div>
              </div>
              }
              </div>
              <div className={height < 700 ? "pow-icon2" : "pow-icon"}>
                <div className="mdl-fott">
                  {" "}
                  <span
                    className="maya-logo cursor"
                    // onClick={() => {
                    //   window.open("https://maya.ai/");
                    // }}
                  >
                    <Icons name="mayatexthdfc" width={207} height={""} />
                  </span>
                </div>
                {/* <div className="cpy-text">
                  © 2022 HDFC Smartbuy, Inc. All rights reserved Privacy · Terms
                </div> */}
              </div>
            </div>
          </div>
          {!isProfile && (
            <Sticky
              top={74}
              className={menuOpen || invisible ? "none" : "navbar-sticky"}
              innerActiveClass={"sticky-mbl-nav"}
            >
              <NavBar
                onChangeTab={onChangeTab}
                activeMenu={activeMenu}
                categoryList={categoriesList}
              />
            </Sticky>
          )}
        </>
      );
    }

    return null;
  }

  /* Main Render Functions */
  render() {
    const { searchValue, searchCountry, suggestions, selectedCity, showTcPopup } =
      this.state;
    const {
      reacentSearch,
      popularSearch,
      emptyTypeAhead,
      getRecentSearchData,
      setActiveMenu,
      history,
      activeMenu
    } = this.props;

    return (
      <>
        { showTcPopup && <TasteCollectionPopup setTcPopup={this.setTcPopup} activeMenu={activeMenu} setActiveMenu={setActiveMenu} getRecentSearchData={getRecentSearchData} />}
        {config.clientName === "HDFC" && (
          <LoginPopup
            openState={this.props.openModal}
            closeLogin={this.props._closeLogin}
          />
        )}
        {this._renderNormalView()}
        {this._renderMobileView()}
        {this.state.invisible && suggestions.length === 0 && (
          <SearchBar
            searchValue={searchValue}
            reacentSearch={reacentSearch}
            searchCountry={searchCountry}
            onClickRecentSearch={this.onClickRecentSearch}
            onCloseSearchBar={this.onCloseSearchBar}
            popularSearch={popularSearch}
          />
        )}
        {this.state.invisible && suggestions.length === 0 && (
          <MobileSearchBar
            searchValue={searchValue}
            reacentSearch={reacentSearch}
            searchCountry={searchCountry}
            onClickRecentSearch={this.onClickRecentSearch}
            onCloseSearchBar={this.onCloseSearchBar}
            popularSearch={popularSearch}
            emptyTypeAhead={emptyTypeAhead}
            selectedCity={selectedCity}
            getRecentSearchData={getRecentSearchData}
            setActiveMenu={setActiveMenu}
            history={history}
            _onClickSearch={this._onClickSearch}
          />
        )}
      </>
    );
  }
}

export default withRouter(NewHeader);
