import React from "react";
import "./style.scss";
import { ReactComponent as PageNotFound } from "../Icons/Svgs/page-not-found.svg";
const PageNotFoundComponent = (props) => {
    return (
        <div className="wrapper">
            <div className="main-img" >
                <PageNotFound />
            </div>
            <div className="att-tit">{props.title}</div>
            <div className="att-des">{props.description}</div>
        </div>
    );
};
export default PageNotFoundComponent;