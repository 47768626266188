/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Heading Component
import React, { Component } from "react";
import CheeseburgerMenu from "cheeseburger-menu";
import smartDealsLogo from "../../assets/img/smart_deals_logo.png"
import searchImg from "../../assets/img/search_white.png"
import menuImg from "../../assets/img/cheese_burger_menu.png";
import userImg from "../../assets/img/user_white.png";
import tickImg from "../../assets/img/tick.png"
import Icon from "../Icons";
import { useHistory, withRouter } from "react-router";
import uiConfig from "../../uiConfig";
import AdibSearchBar from "../Adib_Search_Bar";
import { arabicContent } from "../../constants";


const imgPath="/images/"+uiConfig.config.whitelabel.clientName+"_logo.png"
const logoHeight = uiConfig.config.whitelabel.logo.height;
const logoWidth = uiConfig.config.whitelabel.logo.width;
class HeaderMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isMenuOpen:false,
        isModalOpen:false,
        modalName:"",
    }


  }

  async componentDidMount() {
    this.props.changeHeader(true)
  }
  async componentWillUnmount() {
    this.props.changeHeader(false)
  }

  toggleMenu=(isMenuOpen)=>{
      this.setState({isMenuOpen:isMenuOpen,isModalOpen:false},()=>{
        if(isMenuOpen){
          this.props.onCloseSearchBar()
          document.documentElement.style.overflowY='hidden'
          document.body.style.overflowY = 'hidden';
          document.body.style.minHeight='100vh';
        }
        else{
          document.documentElement.style.overflowY='auto'
          document.body.style.overflowY = 'auto';
          document.body.style.minHeight='100vh';

        }
      })

  }


onCountryChange=(country)=>{
  this.props._onChangeCountrySelect(country)
}
onClickBack=()=>{
  this.setState({
    isModalOpen:false
  })
}
  toggleModal=(isModal,modalName,modalNameArabic)=>{
   
      this.setState({
        isModalOpen:isModal,
        modalName:modalName,
        modalNameArabic:modalNameArabic
      })
    
   
  }
  

  handleSearch=(val)=>{
    this.props.handleSearch(val);
    this.setState({isMenuOpen:false,isModalOpen:false})
  }

  render(){
      const {isMenuOpen,isModalOpen,modalName,modalNameArabic} = this.state;
      const {categoryList,onChangeTab,countryList,country,onLanguageChange,languageOption,language,_onClickHome,_onClickProfileItem,_onClickWishlist,myAccount,selectedMyMenu,onProfileMenu} = this.props;
      let selectedLanguage = localStorage.getItem("language")


    return (
        <div className={isMenuOpen&&!isModalOpen?"header-mobile opened":isMenuOpen&&isModalOpen?"header-mobile-white-bg":"header-mobile closed"}>
            <div className="header-top">
         <div className="header-left">
           <img src={smartDealsLogo} alt="logo" onClick={()=>{_onClickHome();this.toggleMenu(false)}}/>
         </div>
         <div className="header-right">
         <div className="search" onClick={()=>{this.handleSearch("search")}}><img src={searchImg} alt="search"/></div>
         <div className="menu-icon">
             {isMenuOpen?
             
             <div className="cancel-icon" onClick={()=>this.toggleMenu(false)}>
                  <Icon name="cancel" fill="#000" width={20} height={16} /> </div>:
             <img src={menuImg} alt="menu" onClick={()=>this.toggleMenu(true)}/>}</div>
         </div>
         </div>
         {/* <div className={isMenuOpen&&!isModalOpen?"header-menu":isModalOpen?"header-menu-modal-opened":"header-menu-modal-closed"}> */}
        <div className="header-menu">
        {categoryList.length>0&&
        <div className="category-menu white">
           {categoryList.map(category=>{
               return <div className="menu white" onClick={()=>{onChangeTab(category.value);this.toggleMenu(false)}}>{category.label}</div>
           })}
           
         </div>}
         <div className="menu white" onClick={()=>{_onClickWishlist();this.toggleMenu(false)}}>{selectedLanguage==="AR"?arabicContent.myWishlist:"My Wishlist"}</div>
         {countryList.length>0&&
          <>
          <div className="menu white" onClick={()=>this.toggleModal(true,"countries",arabicContent.countries)}><div>{selectedLanguage==="AR"?arabicContent.countries:"Countries"}</div><div className="arrow-icon">{selectedLanguage==="AR"?<Icon name="arrowLeft" fill="#000" width={20} height={15} />:<Icon name="arrowRight" fill="#000" width={20} height={15} />}</div></div>
           <div className="sub-menu">{country}</div>
           </>}
           <div className="menu white" onClick={()=>this.toggleModal(true,"language",arabicContent.langauges)}><div>{selectedLanguage==="AR"?arabicContent.langauges:"Languages"}</div><div className="arrow-icon">{selectedLanguage==="AR"?<Icon name="arrowLeft" fill="#000" width={20} height={15} />:<Icon name="arrowRight" fill="#000" width={20} height={15} />}</div></div>
           <div className="sub-menu">{language}</div>
           
           <div className="menu white" onClick={()=>this.toggleModal(true,"account",arabicContent.myaccount)}><div>{selectedLanguage==="AR"?arabicContent.myaccount:"My Account"}</div><div className="arrow-icon"> {selectedLanguage==="AR"?<Icon name="arrowLeft" fill="#000" width={20} height={15} />:<Icon name="arrowRight" fill="#000" width={20} height={15} />}</div></div>
        </div>
      <div className={selectedLanguage==="AR"?"header-menu-modal-opened ar":"header-menu-modal-opened"}>
           <div className="back-menu" onClick={()=>this.onClickBack()}><Icon name="arrowLeft" fill="#000" width={20} height={16}/><span>{selectedLanguage==="AR"?arabicContent.backToMainMenu:"Back to Main Menu"}</span></div>
           <div className="menu-header">{selectedLanguage==="AR"?arabicContent.choose:"Choose"} {selectedLanguage==="AR"?modalNameArabic:modalName}</div>
           {modalName==="countries"?
           <div className="category-menu">
           {countryList.length>0&&countryList.map(countries=>{
               return <div className={country===countries.value?"menu selected":"menu"} onClick={()=>{this.onCountryChange(countries);this.toggleMenu(false)}}><span>{countries.label}</span>{country===countries.value&&<img src={tickImg}  alt="selected"/>}</div>
           })}
           </div>
         : modalName==="language"?
         <div className="category-menu">
        
        {languageOption.map(opt=>{
               return <div className={opt.value===language?"menu selected":"menu"} onClick={()=>{onLanguageChange(opt);this.toggleMenu(false)}}><span>{opt.label}</span>{opt.value===language&&<img src={tickImg}  alt="selected"/>}</div>
           })}
           
       </div>
       :
       <div className="category-menu">
        
       {myAccount&&myAccount.map(opt=>{
              return <div className={opt.value===selectedMyMenu?"menu selected":"menu"} onClick={()=>{onProfileMenu(opt.value);this.toggleMenu(false)}}><span>{opt.label}</span>{opt.value===selectedMyMenu&&<img src={tickImg}  alt="selected"/>}</div>
          })}
          
      </div>}
        
           
           
        
        </div>
        {/* <div className="account-sec" onClick={()=>{_onClickProfileItem("claimed");this.toggleMenu(false)}}>
             <span>Account</span>
             <img src={userImg} alt="account"/></div> */}
             {this.props.invisible &&  (
                <AdibSearchBar
                    searchValue={this.props.searchValue}
                    reacentSearch={this.props.reacentSearch}
                    searchCountry={this.props.searchCountry}
                    onClickRecentSearch={this.props.onClickRecentSearch}
                    onCloseSearchBar={this.props.onCloseSearchBar}
                    value={this.props.value}
                    suggestions={this.props.suggestions}
                    invisible={this.props.invisible}
                    onChange={this.props.onChange}
                    _onkeyDownContent={this.props._onkeyDownContent}
                    onFocus={this.props.onFocus}
                    onSuggestionsFetchRequest={this.props.onSuggestionsFetchRequest}
                    onSuggestionsClearRequested={this.props.onSuggestionsClearRequested}
                    oneSuggestionSelected={this.props.oneSuggestionSelected}
                    getSuggestionValue={this.props.getSuggestionValue}
                    textInput={this.props.textInput}
                    onClickSearchText={this.props.onClickSearchText}
                    closeOutSide={this.props.closeOutSide}
                />
            )}
        </div>
    
        )
  }
}
export default withRouter(HeaderMobile)