/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Heading Component
import React, { Component } from "react";
import PropTypes from "prop-types";
import clsx from 'clsx';

export default class Image extends Component {
  static get propTypes() {
    return {
      src: PropTypes.string,
      defaultSrc: PropTypes.string,
      w: PropTypes.number,
      h: PropTypes.number
    };
  }

  static get defaultProps() {
    return {
      src: "",
      defaultSrc: "",
      w: 0,
      h: 0
    };
  }

  /* Main Render Functions */
  render() {
    const { src, defaultSrc, w, h, alt, isCover } = this.props;

    const imageSrc = src || defaultSrc;

    const cls = {
      img: true,
      "img-fit": isCover
    };

    return (
      <div className={clsx(cls)}>
        <img src={imageSrc} alt={alt} width={w} height={h} />
      </div>
    );
  }
}
