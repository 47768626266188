/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

import Cookie from 'js-cookie'

export const isDevelopment = true
    
export const DOMAIN = isDevelopment
    ? window.location.hostname
    : '.localhost:3000' // replace this with your domain. Let's say, your site it called, https://crayondata.com, change it to '.crayondata.com'

Cookie.defaults.domain = DOMAIN
Cookie.defaults.expires = 1 // days 
Cookie.defaults.sameSite = 'Lax'
Cookie.defaults.secure = !isDevelopment

export default Cookie