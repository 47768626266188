/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// SearchBar Component
import React, { PureComponent } from "react";
import { arabicContent } from "../../constants";

import { tagOptions } from "../helper/options";

export default class SearchBar extends PureComponent {
  state = {
    language: localStorage.getItem("language")
  };

  componentDidMount() {
    this.setState({language: localStorage.getItem("language")});
    // if(document.getElementById("base-container")){
    //   alert(document.getElementById("base-container").offsetWidth);
    // }
  }


  
  /* OnClick Function */
  _renderBaseSearchContainer() {
    const {
      searchValue,
      searchCountry,
      reacentSearch,
      onClickRecentSearch,
      popularSearch
    } = this.props;

      const {language } = this.state;

    if (!searchValue) {
      return (
        <div className={language == "EN" ? "row ml-0 mr-0 base-container base-container-en" : "row ml-0 mr-0 base-container base-container-ar" } id="base-container">
          <div className="header-search-box">
            <div className="headerBoxLeft">
              <div>
                {
                  language == "EN" ?
                    <h1 className="margin-0 main-title">
                      Let’s find you the perfect offers & deals in{" "}
                      <span className="location">{searchCountry}</span>
                    </h1>
                  :
                    <h1 className="margin-0 main-title">
                      {arabicContent.perfdeal}
                    </h1>
                }
                
                </div>
                <div className="flex-item flex">
                  { popularSearch && popularSearch.length>0 && popularSearch.map((x) => (
                    <div
                      key={`${x}`}
                      className="card-cont search-card flex-center cursor"
                      onClick={() => onClickRecentSearch(x)}
                    >
                      {x}
                    </div>
                  ))}
                </div>
            </div>
            <div>
              {
                language == "EN" ?
                <h4 className="recent-title">Recent Searches</h4>
                :
                <h4 className="recent-title">{arabicContent.recentSearches}</h4>
              }
              <div>
                {
                    reacentSearch.length > 0 &&
                    reacentSearch.map((tagOption, idx) => (
                      <div
                        key={`${idx + 1}`}
                        className="recent-search cursor"
                        onClick={() => onClickRecentSearch(tagOption.searchText)}
                      >
                        {tagOption.searchText}
                      </div>
                    ))
                }
              </div>
            </div>
          </div>
          {/* <div className="col-md-8 lef-col flex-center">
            <div className="col-md-12 p-0">
              <h1 className="margin-0 main-title">
                Let’s find you the perfect offers
                <br /> & deals in{" "}
                <span className="location">{searchCountry}</span>
              </h1>
              <div className="mt-36 flex-item flex">
                {tagOptions.map((tagOption) => (
                  <div
                    key={`${tagOption.id}`}
                    className="card-cont flex-center"
                    style={{background:`${tagOption.color}`}}
                  >
                    {tagOption.label}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-md-4 col-rht">
            <div className="col-md-12 p-0">
              <h4 className="recent-title">Recent Searches</h4>
            </div>
            {reacentSearch.length > 0 &&
              reacentSearch.map((tagOption, idx) => (
                <div
                  key={`${idx + 1}`}
                  className="recent-search cursor"
                  onClick={() => onClickRecentSearch(tagOption.searchText)}
                >
                  {tagOption.searchText}
                </div>
              ))}
          </div> */}
        </div>
      );
    }
    return null;
  }

  /* Main Render Functions */
  render() {
    return (
      <div className="col-md-12 p-0 search-bar-div" onClick={()=>this.props.onCloseSearchBar()}>
        <div className="col-md-12 p-0 search-content">
          {this._renderBaseSearchContainer()}
        </div>
      </div>
    );
  }
}
