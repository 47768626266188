/**
 *
 * Disclaimer: Source code mentioned below is(are) Intellectual Property of
 * Crayon Data Holdings Limited (including its subsidiaries and affiliates).
 * Crayon Data Holdings Limited reserves right to own and control it the way
 * it may deem fit. You must refrain from use, access, read, modify, add or
 * delete, sell or use in any other package or programme pertaining to such
 * source code without explicit prior written approval of
 * Crayon Data Holding Limited. Breach of the same shall attract penalty as
 * applicable.
 *
 */

import axios from "axios";
//import { accessTokenHelper } from "../helper/token";
import { axiosOpen, axiosProtected } from "./apihelper";
import Config from "./config";
import { headers, headerwotoken, authAPIHeader } from "./header";
import imageToBase64 from "image-to-base64/browser";
// import base64Img from "base64-img";
import { arabicContent } from "../constants/index";
import momentTimezone from "moment-timezone";
import moment from 'moment';

const lang = localStorage.getItem("language")
  ? localStorage.getItem("language").toLowerCase()
  : "en";
// =========================================(Login)=================================================

export async function Login(formData) {
  // const response = await fetch(
  //   Config.domain + Config.apiVersion + "auth/login",
  //   {
  //     headers: headerwotoken,
  //     method: "POST",
  //     body: JSON.stringify(formData)
  //   }
  // );
  // return await response.json();
  const url = "auth/login";
  headers = headerwotoken;
    // Config.domain + Config.apiVersion + "auth/login",
    let body =JSON.stringify(formData)
    const { data } = await axiosOpen.post(url, body, { headers });
    return data
}

// =========================================(Request OTP)=================================================

export async function requestOTP(formData) {
  const response = await fetch(Config.authURL + "otp_generation", {
    method: "POST",
    headers: authAPIHeader,
    body: JSON.stringify(formData)
  });

  return await response.json();

  // const url = "otp_generation";
  // headers = authAPIHeader;
  //   let body =JSON.stringify(formData)
  //   const { data } = await axiosOpen.post(url, body, { headers });
  //   return data
}

// =========================================(Request OTP)=================================================

export async function validateOTP(formData) {
  const response = await fetch(Config.authURL + "otp_validation", {
    headers: authAPIHeader,
    method: "POST",
    body: JSON.stringify(formData)
  });
  return await response;
  // const url = "otp_validation";
  // headers = authAPIHeader;
  //   let body =JSON.stringify(formData)
  //   const { data } = await axiosOpen.post(url, body, { headers });
  //   return data
}

// =========================================(Home)=================================================

export async function getCity() {
  const url = "list/cities?lang=" + lang;
  let loc = JSON.parse(localStorage.getItem("currentLocation"));
  let body =
    localStorage.getItem("location-permission") === "true"
      ? { geocode: { latitude: loc?.latitude, longitude: loc?.longitude } }
      : {};
  const { data } = await axiosOpen.post(url, body,{timeout: 15000});
  return data;
}

export async function getRecentCity() {
  const url = "user/cities/recent?lang=" +lang +"&limit=3";
  headers["User-Access-Token"] = localStorage.getItem("TOKEN");
  // const response = await fetch(
  //   Config.domain +
  //     Config.apiVersion +
  //     "user/cities/recent?lang=" +
  //     lang +
  //     "&limit=3",
  //   {
  //     method: "GET",
  //     headers
  //   }
    const { data } = await axiosOpen.get(url, { headers });
    return data;
}

  //return await response.json();
  // const url = `user/cities/recent?lang=${lang}&limit=3`;
  // const { data } = await axiosOpen.get(url);
  // return data

// =========================================(Category )=================================================

export async function getCategory(type) {
  // const response = await fetch(
  //   Config.domain + Config.apiVersion + "list/" + type + "?lang=en",
  //   {
  //     method: "GET",
  //     headers
  //   }
  // );
  let opt = localStorage.getItem("selectCountry");
  opt = opt && JSON.parse(opt);
  let geoCode = opt?.geoCode;
  let label = opt?.label;
  //const url = "list/" + type + "?lang=" + lang + (label === "Near me" ? ("&filterBy=geoCode&filterValue=" +"{latitude=" + geoCode.latitude + ",longitude=" + geoCode.longitude+"}") : "&filterBy=city&filterValue=" + (label ? label : null));
  const url =
    "list/" +
    type +
    "?lang=" +
    lang +
    (label === "Near me"
      ? "&filterBy=geoCode&filterValue=" +
        `${geoCode?.latitude},${geoCode?.longitude}`
      : "&filterBy=city&filterValue=" + (label ? label : null));
  const { data } = await axiosOpen.get(url);

  //  const { data } = await getCategory(type)
  return data;
  //  return await response.json();
}

// =========================================( Redeemed Offer )=================================================
export async function getRedeemedOffers() {
  // const response = await fetch(
  //   Config.domain + Config.apiVersion + "user/redeemed/offers?lang=en",
  //   {
  //     method: "GET",
  //     headers
  //   }
  // );

  // return await response.json();

  const url = "user/redeemed/offers?lang=" + lang;
  const { data } = await axiosProtected.get(url);

  return data;
}

// =========================================(Home List )=================================================
export async function homePageList(
  selectedCategory,
  selectedCity,
  geoPosition
) {
  let location = {};
  let date = moment.utc().local().format('YYYY-MM-DDTHH:mm:ss.SSS');
  const timeZone = momentTimezone.tz.guess();
  //  if (geoPosition && selectedCity === "Near me") {
  if (
    selectedCity.value === "Near me" ||
    selectedCity.value === arabicContent.nearMe
  ) {
    location = {
      // geocode: {
      //   latitude: geoPosition.coords.latitude,
      //   longitude: geoPosition.coords.longitude
      // }
      geocode: selectedCity.geoCode
    };
  } else {
    if (selectedCity && selectedCity.label && selectedCity.label.length > 0) {
      let city =
        lang === "en"
          ? localStorage.getItem("selectCountry")
          : localStorage.getItem("selectCountryArabic");
      city = city && JSON.parse(city);
      let citygeoCode = city ? city.geoCode : null;

      let selectedCityGeoCode = citygeoCode
        ? citygeoCode
        : selectedCity.geoCode;
      // location = {
      //   geocode: selectedCityGeoCode
      // };

      let selectedCityValue = selectedCity.value;
      location = {
        city: selectedCityValue
      };
    } else {
      location = {
        geocode: null
      };
    }
  }

  headers["User-Access-Token"] = localStorage.getItem("TOKEN");
  // const response = await fetch(
  //   Config.domain + "" + Config.apiVersion + "choice/list",
  //   {
  //     method: "POST",
  //     headers,
  //     body: JSON.stringify({
  //       category: selectedCategory.value,
  //       choiceCount: 30,
  //       lang: lang,
  //       location: location,
  //       includeCampaigns: true,
  //       servedAt: JSON.parse(date)
  //     })
  //   }
  // );
  // localStorage.setItem("api_status_home", response.status);

  // return await response.json();
  const url = "choice/list" ;
  let body = JSON.stringify({
    category: selectedCategory?.value === "visa" ? 'all' : (selectedCategory.value || 'all'),
    choiceCount: 30,
    campaignCount: 60,
    lang: lang,
    location: selectedCategory?.value === "visa" ? 
    {city: 'Singapore'} : location,
    includeCampaigns: true,
    servedAt: `${date}Z`,
    applicableOfferSource: selectedCategory?.value === "visa" ? "VISA" : "LIFESTYLE",
    timeZone,
  })
  try {
    const response  = await axiosOpen.post(url, body, { headers, timeout: 15000});
    const {data, status} = response
    localStorage.setItem("api_status_home", status);
      return data;
    }
    catch(error){
      if (error.code === 'ECONNABORTED') {
        localStorage.setItem("api_status_home", error.response.status);
      } else {
        localStorage.setItem("api_status_home", error.response.status);
      }
}
}

export async function getCampaignChoice(campaignId) {
  headers["User-Access-Token"] = localStorage.getItem("TOKEN");
  // const response = await fetch(
  //   Config.domain + "" + Config.apiVersion + "choice/list",
  //   {
  //     method: "POST",
  //     headers,
  //     body: JSON.stringify({
  //       choiceCount: 30,
  //       choiceList: [
  //         {
  //           choiceListType: "CAMPAIGN"
  //         }
  //       ],
  //       lang: lang,
  //       campaignId: campaignId
  //     })
  //   }
  // );

  // return await response.json();
  const url = "choice/list";
  let body = 
    JSON.stringify({
      choiceCount: 30,
      choiceList: [
        {
          choiceListType: "CAMPAIGN"
        }
      ],
      lang: lang,
      campaignId: campaignId
    });
    const { data }  = await axiosOpen.post(url, body, { headers });
    return data;
}

export async function getExpiredItemDetail(itemId) {
  let date = moment.utc().local().format('YYYY-MM-DDTHH:mm:ss.SSS');
  let opt = localStorage.getItem("selectCountry");
  opt = opt && JSON.parse(opt);
  let geoCode = opt?.geoCode;
   let label = opt?.label;
   const url = Config.domain +
   "" +
   Config.apiVersion +
   "items/" +
   itemId +
   "?lang=" +
   lang +
   (sessionStorage.getItem("actMenu") === "visa" ? "&city=" +"Singapore" :
   (label === "Near me"
   ? "&latitude=" + geoCode.latitude + "&longitude=" + geoCode.longitude
   : label ? "&city=" +label : ""))+
   "&servedAt=" +date+'Z'+"&expiredOffersPage=" + true ;
   headers["User-Access-Token"] = localStorage.getItem("TOKEN");
   try{
     const { data }  = await axiosOpen.get(url, { headers,timeout: 30000 });
   return data;
   }
   catch(error){
     console.error(error);
     if (error.response.status) {
       localStorage.setItem("api_status_merchant", error.response.status);
       throw error
     }
   }
 }

export async function getItemDetail(itemId) {
  let date = moment.utc().local().format('YYYY-MM-DDTHH:mm:ss.SSS'); 
  let opt = localStorage.getItem("selectCountry");
  opt = opt && JSON.parse(opt);
  let geoCode = opt?.geoCode;
  let label = opt?.label;
  const url = Config.domain +
  "" +
  Config.apiVersion +
  "items/" +
  itemId +
  "?lang=" +
  lang +
  (label === "Near me"
  ? "&latitude=" + geoCode.latitude + "&longitude=" + geoCode.longitude
  : label ? "&city=" +label : "")+
  "&servedAt=" +
  date+'Z';
  headers["User-Access-Token"] = localStorage.getItem("TOKEN");
  // const response = await fetch(
  //   Config.domain +
  //     "" +
  //     Config.apiVersion +
  //     "items/" +
  //     itemId +
  //     "?lang=" +
  //     lang +
  //     "&servedAt=" +
  //     JSON.parse(date),
  //   {
  //     method: "GET",
  //     headers
  //   }
  // );
  // localStorage.setItem("api_status_merchant", response.status);

  // return await response.json();
  try{
    const { data }  = await axiosOpen.get(url, { headers,timeout: 30000 });
    return data;
    }
    catch(error){
      console.error(error);
    }
}

export async function getSimilarItem(itemId, count, activeMenu = "") {
  headers["User-Access-Token"] = localStorage.getItem("TOKEN");
  const url = Config.domain + "" + Config.apiVersion + "items/similar"
  let date = moment.utc().local().format('YYYY-MM-DDTHH:mm:ss.SSS');
  let loc = JSON.parse(localStorage.getItem("selectCountry"));
  let body = {
    count: count,
    location:
    (sessionStorage.getItem("actMenu") === "visa" ? {city: 'Singapore'} :
     loc
      ? loc.value.toLowerCase() === "near me"
        ? { geocode: loc?.geoCode }
        : { city: loc?.value }
      : { city: "" }),
    lang: lang,
    itemId: itemId,
    servedAt: `${date}Z`,
    applicableOfferSource: activeMenu === "" ? "ALL" : activeMenu === "visa" ? "VISA" : "LIFESTYLE"
  };
  // const response = await fetch(
  //   Config.domain + "" + Config.apiVersion + "items/similar",
  //   {
  //     method: "POST",
  //     headers,
  //     body: JSON.stringify(body)
  //   }
  // );
  // return await response.json();
  const { data }  = await axiosOpen.post(url, body, { headers });
  return data;
}

// =========================================(PrivacyPolicy)=================================================

export async function getPolicy() {
  // const response = await fetch(
  //   Config.domain + Config.apiVersion + "privacy/docs?type=privacy_policy",
  //   {
  //     method: "GET",
  //     headers
  //   }
  // );

  // return await response.json();
  const url = "privacy/docs?type=privacy_policy&lang=" + lang;
  const { data } = await axiosProtected.get(url);

  return data;
}

// =========================================(Terms)=================================================

export async function getTerms() {
  // const response = await fetch(
  //   Config.domain + Config.apiVersion + "privacy/docs?type=terms_of_service",
  //   {
  //     method: "GET",
  //     headers
  //   }
  // );

  // return await response.json();
  const url = "privacy/docs?type=terms_of_service&lang=" + lang;
  const { data } = await axiosProtected.get(url);

  return data;
}

export async function getVouchdetails() {

  let date = moment.utc().local().format('YYYY-MM-DDTHH:mm:ss.SSS');

  var requestData = {

    servedAt: `${date}Z`,

    lang:lang

  };

  headers["User-Access-Token"] = localStorage.getItem("TOKEN");

  const url =  Config.domain +""+ Config.apiVersion + "user/vouchers";

  let body = JSON.stringify(requestData);

  try {

    const response = await axiosOpen.get(url, { headers, data: body, timeout: 30000 });

    return response.data;

  }  catch(error){

    if (error.code === 'ECONNABORTED') {

      localStorage.setItem("api_status_wishlist", error?.response?.status);

    } else {

      localStorage.setItem("api_status_wishlist", error?.response?.status);

    }

    return error

  }

}

// =========================================(App Feedback)=================================================
export async function updateAppfeedback(requestData) {
  headers["User-Access-Token"] = localStorage.getItem("TOKEN");
  // const response = await fetch(
  //   Config.domain + Config.apiVersion + "user/feedback",
  //   {
  //     method: "POST",
  //     headers,
  //     body: JSON.stringify(requestData)
  //   }
  // );

  // return await response.json();
  const url =  Config.domain + Config.apiVersion + "user/feedback";
  let body =  JSON.stringify(requestData)
  const { data }  = await axiosOpen.post(url, body, { headers });
  return await data;
}

// =========================================(Get Claimed Offers )=================================================
export async function getWishList(interaction, item) {
  headers["User-Access-Token"] = localStorage.getItem("TOKEN");
  // const response = await fetch(
  //   Config.domain +
  //     Config.apiVersion +
  //     "user/interaction?interactionType=" +
  //     interaction +
  //     "&itemType=" +
  //     item +
  //     "&lang=" +
  //     lang,
  //   {
  //     method: "GET",
  //     headers
  //   }
  // );
  // localStorage.setItem("api_status_wishlist", response.status);
  // return await response.json();
  let date = moment.utc().local().format('YYYY-MM-DDTHH:mm:ss.SSS');
  const url =  Config.domain +
  Config.apiVersion +
  "user/interaction?interactionType=" +
  interaction +
  "&itemType=" +
  item +
  "&lang=" +
  lang +
  "&hideGroupByCategory="+true +"&servedAt=" +date+'Z';
  try{
  const response  = await axiosOpen.get(url, { headers ,timeout: 30000});
  const {data, status} = response;
    localStorage.setItem("api_status_wishlist", status);
    return data;
    }
    catch(error){
      if (error.code === 'ECONNABORTED') {
        localStorage.setItem("api_status_wishlist", error?.response?.status);
      } else {
        localStorage.setItem("api_status_wishlist", error?.response?.status);
      }
      return error
    }
}

export async function getCampaigns() {
  // headers["User-Access-Token"] = localStorage.getItem('TOKEN');
  // const response = await fetch(]
  //   Config.domain +
  //     Config.apiVersion +
  //     "user/campaigns?campaignType=EVENT",
  //   {
  //     method: "GET",
  //     headers
  //   }
  // );

  // return await response.json();
  let opt = localStorage.getItem("selectCountry");
  let cat = sessionStorage.getItem("actMenu")
  ? (sessionStorage.getItem("actMenu") === "home" || sessionStorage.getItem("actMenu") === "all offers" || sessionStorage.getItem("actMenu") === "visa")
      ? "all"
      : sessionStorage.getItem("actMenu")
    : "all";
  opt = opt && JSON.parse(opt);
  let geoCode = opt?.geoCode;
  let label = opt?.label;
  let date = moment.utc().local().format('YYYY-MM-DDTHH:mm:ss.SSS');
  const url =
    "user/campaigns?campaignType=EVENT&category=" +
    cat +
    "&lang=" +
    lang +
    (sessionStorage.getItem("actMenu") === "visa" ? "&city=" +"Singapore" :
    (label === "Near me"
      ? "&latitude=" + geoCode.latitude + "&longitude=" + geoCode.longitude
      : label ? "&city=" +label : "")) +
    "&servedAt=" +
    date+'Z' + "&applicableOfferSource=" + (sessionStorage.getItem("actMenu") === "visa" ? "VISA" : "LIFESTYLE");
  const { data = [] } = await axiosProtected.get(url);

  return data;
}

export async function getUserCampaigns(userId) {
  // headers["User-Access-Token"] = localStorage.getItem('TOKEN');
  // const response = await fetch(
  //   Config.domain +
  //     Config.apiVersion +
  //     "user/campaigns?campaignType=EVENT",
  //   {
  //     method: "GET",
  //     headers
  //   }
  // );

  // return await response.json();
  let cat = sessionStorage.getItem("actMenu")
    ? sessionStorage.getItem("actMenu") === ("home" || "all offers"|| "visa")
      ? "all"
      : sessionStorage.getItem("actMenu")
    : "all";
  const url =
    "user/campaigns?campaignType=EVENT&category=" +
    cat +
    "&knownUserId=" +
    userId +
    "&lang=" +
    lang + "&applicableOfferSource=" + (sessionStorage.getItem("actMenu") === "visa" ? "VISA" : "LIFESTYLE");
  const { data } = await axiosProtected.get(url);

  return data;
}

export async function interaction(integractionType,itemType,itemId) {
 
  var requestData = {
    interactionType: integractionType,
    itemType: itemType,
    value: itemId
  };
  // headers["User-Access-Token"] = localStorage.getItem('TOKEN');
  // headers["User-Access-Token"] = localStorage.getItem("TOKEN");

  // const response = await fetch(
  //   Config.domain + Config.apiVersion + "user/interact",
  //   {
  //     method: "POST",
  //     headers,
  //     body: JSON.stringify(requestData)
  //   }
  // );
  // return await response.json();
  const url =  Config.domain + Config.apiVersion + "user/interact";
  let body = JSON.stringify(requestData);
  const data  = await axiosOpen.post(url, body, { headers });
  return data;
}

// =========================================( Search )=================================================
let cancelToken;
export async function getTypeHead(category, city, word) {
  //Check if there are any previous pending requests
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel("Operation canceled due to new request.");
  }

  let location = {};
  if (city.value === "Near me" || city.value === arabicContent.nearMe) {
    location = {
      geocode: city.geoCode
    };
  } else {
    if (city && city.value && city.value.length > 0) {
      location = {
        city: city?.value
      };
    } else {
      location = {
        geocode: null
      };
    }
  }
  let date = moment.utc().local().format('YYYY-MM-DDTHH:mm:ss.SSS');

  let body = {
    location: location,
    lang: lang,
    searchTextLang: lang,
    searchWord: word,
    servedAt: `${date}Z`
  };

  //Save the cancel token for the current request
  cancelToken = axios.CancelToken.source();
  headers["User-Access-Token"] = localStorage.getItem("TOKEN");
  try {
    const { data } = await axiosOpen.post(
      Config.domain + Config.apiVersion_2 + "merchant/typeAhead",
      body,
      { headers, cancelToken: cancelToken.token }
    );
    return data;
  } catch (error) {
    //  console.log(error);
  }
}

export async function searchKeywordFunc(requestData) {
  headers["User-Access-Token"] = localStorage.getItem("TOKEN");
  // const response = await fetch(
  //   Config.domain + Config.apiVersion_2 + "merchant/search",
  //   {
  //     method: "POST",
  //     headers,
  //     body: JSON.stringify(requestData)
  //   }
  // );
  // localStorage.setItem("api_status_search", response.status);

  // return await response.json();
  const url = Config.domain + Config.apiVersion_2 + "merchant/search";
  let body = JSON.stringify(requestData);
  try{
  const response  = await axiosOpen.post(url, body, { headers , timeout: 30000 });
  const {data, status} = response;
  localStorage.setItem("api_status_search", status);
  return data;
  }
  catch(error){
    if (error.code === 'ECONNABORTED') {
      localStorage.setItem("api_status_search", error.response.status);
    } else {
      localStorage.setItem("api_status_search", error.response.status);
    }
  }
}

export async function allOffers(requestData) {
  headers["User-Access-Token"] = localStorage.getItem("TOKEN");
  // const response = await fetch(Config.domain + Config.apiVersion_2 + "items", {
  //   method: "POST",
  //   headers,
  //   body: JSON.stringify(requestData)
  // });

  // return await response.json();
  const url = Config.domain + Config.apiVersion_2 + "items";
  let body = JSON.stringify(requestData)
  const data  = await axiosOpen.post(url, body, { headers });
  return data;
}

export async function clearRecentSearch() {
  // const response = await fetch(
  //   Config.domain + Config.apiVersion_2 + "merchant/search",
  //   {
  //     method: "DELETE",
  //     headers
  //   }
  // );

  // return await response.json();
  const url = "merchant/search";
  const { data } = await axiosProtected.delete(url, {
    headers: headerwotoken
  });

  return data;
}

export async function getRecentSearch() {
  // const response = await fetch(
  //   Config.domain + Config.apiVersion_2 + "merchant/search/recent?limit=6",
  //   {
  //     method: "GET",
  //     headers
  //   }
  // );

  // return await response.json();
  const url = `merchant/search/recent?limit=6&lang=${lang}`;
  const { data } = await axiosProtected.get(url);

  return data;
}

export async function getPopularSearchTags() {
  const url = `merchant/popular/search?lang=${lang}`;
  const { data } = await axiosProtected.get(url);
  return data;
}

// =========================================(Personal Details)=================================================

export async function updatePersonaldetail(requestData) {
  headers["User-Access-Token"] = localStorage.getItem("TOKEN");
  const options = {
    headers
  };
  const { data } = await axios.patch(
    Config.domain + Config.apiVersion + "user/profile",
    requestData,
    options
  );
  return data;
}

export async function updateUser() {
  headers["User-Access-Token"] = localStorage.getItem("TOKEN");
  const options = {
    headers
  };
  const { data } = await axiosOpen.patch(
    Config.domain + Config.apiVersion + "user/profile",
    {},
    options
  );
  return data;
}

export async function getProfileDetails() {
  // headers["User-Access-Token"] = localStorage.getItem('TOKEN');
  // const response = await fetch(
  //   Config.domain + Config.apiVersion + "user/profile",
  //   {
  //     method: "GET",
  //     headers
  //   }
  // );

  // return await response.json();
  const url = "user/profile";
  const { data } = await axiosProtected.get(url);

  return data;
}

// =========================================(error handling)=================================================

export function getError(err) {
  return err.message
    ? err.message
    : err.data && err.data.message
    ? err.data.message
    : "Something went wrong";
}

// =========================================(random token generation)=================================================

export function randomTokenGen(len) {
  var text = "";

  var charset = "abcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < len; i++)
    text += charset.charAt(Math.floor(Math.random() * charset.length));

  return text;
}

// =======================================(check if image exists in url)===================================================

export const checkColor = async (clearbit) => {
  // var image = new Image();
  // var url_image = clearbit;
  // image.src = url_image;
  // if (image.width == 0) {
  //    return false;
  // } else {
  //    return true;
  // }
  return await axios
    .get(encodeURI(clearbit))
    .then((result) => {
      if (result.status === 200) {
        return Promise.resolve(true);
      } else {
        return Promise.resolve(false);
      }
    })
    .catch((error) => {
      return Promise.resolve(false);
    });

  // .then(res=>{
  //   if(res&&res.data){
  //   if(res.data.status===200){
  //     imgUrl=true;
  //     return imgUrl
  //   }
  //   else{
  //     imgUrl=false
  //     return imgUrl
  //   }
  // }
  // }).catch(err=>{
  // if(err&&err.response&&err.response.status===404){
  //   imgUrl=false
  //   return imgUrl
  // }})
  // return imgUrl
};

export const tobase64 = (imgSrc) => {
  const proxy =
    "https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&refresh=2592000&url=";
  return imageToBase64(imgSrc)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

// =======================================(Get FAQ)===================================================

export async function getFAQ() {
  const url = "docs/faqs?lang=" + lang;
  const { data } = await axiosProtected.get(url);

  return data;
}

export async function consent(consent) {
  headers["User-Access-Token"] = localStorage.getItem('TOKEN');
  const url = Config.domain + Config.apiVersion + "user/consent";
  let body = {consent: JSON.stringify(consent)};
  const data = await axiosOpen.post(url, body, { headers });
  return data;
}
export async function notification() {
  headers["User-Access-Token"] = localStorage.getItem("TOKEN");
  const { data } = await await axiosProtected.get('notification',{ headers});
  return data;
}

export async function getCategories() {
  const url = Config.domain + Config.apiVersion + "taste/categories?lang=" + lang;
  headers["User-Access-Token"] = localStorage.getItem("TOKEN");
  try {
    const { data } = await axiosOpen.get(url, { headers });
    return data;
  }
  catch (error) {
    console.error(error);
    if (error.response.status) {
      localStorage.setItem("api_status_merchant", error.response.status);
      throw error
    }
  }
}
export async function getTags(categories) {
  const url = Config.domain + Config.apiVersion + "taste/tags?lang=" + lang;
  headers["User-Access-Token"] = localStorage.getItem("TOKEN");
  try {
    const { data } = await axiosOpen.post(url, { categories }, { headers });
    return data;
  }
  catch (error) {
    console.error(error);
    if (error.response.status) {
      localStorage.setItem("api_status_merchant", error.response.status);
      throw error
    }
  }
}
export async function getMerchants(categories) {
  const url = Config.domain + Config.apiVersion + "taste/merchants?lang=" + lang;
  headers["User-Access-Token"] = localStorage.getItem("TOKEN");
  try {
    const { data } = await axiosOpen.post(url, { categories }, { headers });
    return data;
  }
  catch (error) {
    console.error(error);
    if (error.response.status) {
      localStorage.setItem("api_status_merchant", error.response.status);
      throw error
    }
  }
}
export async function updateTastePreference(categories, submit) {
  const url = Config.domain + Config.apiVersion + "taste/preference";
  headers["User-Access-Token"] = localStorage.getItem("TOKEN");
  try {
    const { data } = await axiosOpen.post(url, {preferences: categories, status: submit ? true: false}, { headers });
    return data;
  }
  catch (error) {
    console.error(error);
    if (error.response.status) {
      localStorage.setItem("api_status_merchant", error.response.status);
      throw error
    }
  }
}
