import { notification, consent } from "../../service";

export const requestNotificationPermission = () => {
  if ("Notification" in window) {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Notification permission granted.");
        consent(true)
          .then(() => {
            notificationFunc();
            localStorage.removeItem("notification-consent");
          })
          .catch(() => {
            console.log("Consent api failed");
          });
      } else if (permission === "denied") {
        consent(false).catch(() => {
          console.log("Consent api failed");
        });
        localStorage.setItem("notification-consent", "denied");
        console.log("Notification permission denied.");
      } else if (permission === "default") {
        localStorage.setItem("notification-consent", "default");
      }
    });
  } else {
    console.log("This browser does not support notifications.");
  }
};
// Function to show notification with image and click event
export const showNotificationWithImage = (title, body, imageUrl, clickUrl) => {
  if (Notification.permission === "granted") {
    const options = {
      body: body,
      icon: imageUrl,
      data: { url: clickUrl }
    };
    const notification = new Notification(title, options);
    notification.onclick = function (event) {
      event.preventDefault(); // Prevent the browser from focusing the Notification's tab
      window.open(
        `${window.location.origin}/home/merchant/${clickUrl}`,
        "_blank"
      );
    };
  }
};
export const notificationFunc = () => {
  setTimeout(() => {
    notification()
      .then((data) => {
        const notificationData = data?.notifications;
        if (notificationData !== undefined) {
          // setCookies(cookie)
          notificationData.map((item) => {
            showNotificationWithImage(
              item.merchantName,
              item.message,
              item.merchantLogo,
              item.merchantUId
            );
          });
        }
      })
      .catch(() => {
        console.log("notification api failed");
      });
  }, [1500]);
};
