/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Constant
export const navItemTitle = {
  restaurants: "Restaurants",
  entertainment: "Entertainment",
  travel: "Travel",
  retail: "Retail",
  fashion: "Fashion & Acessories",
  arts: "Arts & Crafts",
  beauty: "Beauty & personal care",
  health: "Health & Household",
  home: "Home & Kitchen",
  // allOffers: "All Offers"
};

export const menuBarTitle = {
  bestOffers: "Best Offers",
  info: "Info",
  menu: "Menu",
  reviews: "Reviews",
  orderOnline: "Order Online",
  bookTable: "Book a Table",
  photos: "Photos",
};

export const offerItemHeader = {
  offerDetails: "Offer Details",
  redeem: "How to redeem",
  termsAndCondition: "Terms & Conditions",
  merchantAndStores:"Merchant & Stores "
};

export const tokenHeader = {
  TOKEN: "TOKEN",
}

export const arabicContent = {
  shareText: "يزداد الحب بالمشاركة، لذا دع أصدقاءك والمجتمع الاجتماعي يعرفون",
  copylink: "أو انسخ الرابط",
  copy: "ينسخ",
  copied:"نسخ",
  recommendscore: "درجة التوصية",
  alloffers: "كل العروض",
  home: "الصفحة الرئيسية",
  backtoresult: "رجوع إلى النتائج",
  online: "عبر الإنترنت",
  instore: "داخل-المتجر",
  bankOnline: "البنك وعبر الإنترنت",
  bankInstore: "البنك والمتجر",
  like: "المزيد من هذا ",
  adblike: "عرض المزيد مثل هذا",
  dislike: "لست مهتمًا",
  contactus: "اتصل بنا :",
  contactUsMob: "اتصل بنا",  
  mername: "آلة الطيران",
  mercate: "الأزياء والاكسسوارات",
  merdesc: "هو تطبيق للتسوق عبر الإنترنت للبقالة وموقع للتجارة الإلكترونية يركز بشكل خاص على الفواكه والخضروات الطازجة عالية الجودة ، بالإضافة إلى المنتجات العضوية والمنتجات العضوية الطازجة",
  learnmore: "يتعلم أكثر",
  offername: "حتى 5.00٪ كاش باك في أمازون",
  offerInfo1: "هذا العرض متاح لبطاقات ADIB. لتخليص هذا العرض في ",
  offerInfo2: "، الرجاء إدخال الرمز الترويجي أدناه عند الخروج",
  offerDetails: "تفاصيل العرض ",
  features: "سمات",
  eligibility: "جدارة - أهلية",
  feesAndCharges: "الرسوم والمصاريف",
  redeem: "كيفية الاسترداد",
  termsAndCondition: "الشروط والأحكام",
  merchantAndStores:"التاجر والمتاجر",
  description: "1. هذا العرض ساري حتى 2023-12-31T00: 00: 00.000Z \n \n2. يمكن الاستفادة من هذا العرض في فئات الموضة. \n \n3. لا يمكن استرداد هذا العرض إلا من خلال موقع الويب الخاص بالعلامة التجارية. \n \n4. ستؤدي المعاملة الناجحة والتي تم التحقق منها إلى استرداد نقدي بنسبة 1.50٪. \n \n",
  howToRedeem: "1. انقر فوق 'مطالبة' أسفل صفحة العرض. \n \n2. انقر فوق 'استرداد الآن' لإعادة توجيهك إلى موقع التاجر. \n \n3. عند الخروج على موقع التاجر ، تأكد من لصق رمز القسيمة. (تلميح: يتم نسخ رمز القسيمة تلقائيًا إلى الحافظة الخاصة بك) \n \n",
  termsAndConditions: "1. هذا العرض ساري حتى 2021-08-12T00: 00: 00.000Z \n \n2. هذا العرض ساري على https://www.kandmore.com/riyadh/en/\n\n3. لن يتم تغطية الفقد العرضي للقسيمة. \n \n4. العرض قابل للتطبيق على المشتريات / الفئات المحددة. \n \n5. القسائم غير قابلة للتحويل. \n \n6. يمكن الاستفادة من هذا العرض في فئات البقالة والأطعمة وعروض القسيمة \n \n7. القسائم قابلة للتطبيق فقط على مشتريات البطاقة. \n \n8. لا يمكن استبدال القسائم إذا قام المدعي بحذف رسائل البريد الإلكتروني. \n \n9. يتم إعادة توجيه العميل إلى موقع الطرف الثالث. سيتم تطبيق شروط موقع الطرف الثالث على تجربة التسوق. \n \n",
  redeemOffer: "استرداد هذا العرض",
  availOffer: "استفد من هذا العرض الآن",
  redeemNow: "تخليص الآن",
  redeemText1: "لتخليص هذا العرض في ",
  redeemText2: "عرض استرداد النقود الخاص بك حتى",
  redeemText3: "تم تطبيق استرداد النقود بنجاح!",
  redeemText4: "للاستفادة من عرض الاسترداد النقدي ، يرجى استخدام بطاقة ADIB في نقاط البيع",
  redeemText5: "25000 + مطالب بها وأكثر من 900 مقفلة اليوم. لتخليص هذا العرض في ",
  redeemText6: " هذا العرض ساري حتى ",
  claimed: "ادعى",
  claim: "مطالبة",
  similarOffHead: "عروض مماثلة قد تعجبك، وفقًا لاطلاعاتك الأخيرة",
  hooray: "الصيحة!",
  loading: "التحميل",
  redeemMsg1: "رمزك الخاص بـ ",
  redeemMsg2: "تم تنشيط رمزك الخاص بـ Flying Machine!",
  redeemMsg3: " تم الكشف عنها!",
  hasactivated: " تم تفعيله!",
  redeemCodeDesc1: "بعد استرداد قيمة الرمز الخاص بك ، سيكون صالحًا لمدة 7 أيام قادمة. استخدمه في متجر متصل أو غير متصل بالإنترنت.",
  redeemCodeDesc2: "تم تفعيل الخصم في البطاقة (xxxxx) استخدم البطاقة في نقاط البيع للاستفادة من العرض.",
  emiOpt: "ينطبق خيار Emi أيضًا على هذا العرض",
  redeemYourCode: "استبدال التعليمات البرمجية",
  redeemNow: "تخليص الآن",
  redirectMsg: "ستتم إعادة توجيهك إلى موقع التاجر",
  proceed: "يتابع",
  cancel: "يلغي",
  code: "الشفرة",
  activated: "مفعل",
  tasteReason: "نظرًا لأنك تحب خدمة التوصيل والكاميرات والملحقات وأجهزة الكمبيوتر والإلكترونيات ، فقد يعجبك هذا أيضًا",
  aboutus: "معلومات عنا",
  faq: "التعليمات",
  git: "لصاوت ىلع ىقبا",
  contact: "الاتصال",
  internationalpresence: "يلودلا روضحلا",
  termAndCond: "البنود و الظروف",
  privPolicy: "سياسة خاصة",
  custSupport: "دعم العملاء",
  ftrdisclaimer: "إخلاء المسؤولية: maya.ai عبارة عن منصة لتوصيل العروض التي يقدمها التجار لعملاء البنك. يقوم البنك بإبلاغ العملاء بالعروض المقدمة من التجار فقط ولا يقوم ببيع / تقديم أي من هذه المنتجات / الخدمات. يقوم البنك فقط بتسهيل الدفع لعملائه من خلال توفير خدمات بوابة الدفع. البنك لا يضمن ولا يقوم بأي تمثيل. البنك غير مسؤول عن بيع / جودة / ميزات المنتجات / الخدمات بموجب العروض.",
  adbftrdisclaimer: "إخلاء المسؤولية: Smartdeals هي عبارة عن منصة للاتصال بالعروض التي يقدمها التجار لعملاء مصرف أبوظبي الإسلامي. يقوم مصرف أبوظبي الإسلامي بإبلاغ العملاء بالعروض المقدمة من التجار فقط ولا يقوم ببيع / تقديم أي من هذه المنتجات / الخدمات. يقوم مصرف أبوظبي الإسلامي فقط بتسهيل الدفع لعملائه من خلال توفير خدمات بوابة الدفع. مصرف أبوظبي الإسلامي لا يضمن ولا يقدم أي تمثيل. مصرف أبوظبي الإسلامي غير مسؤول عن بيع / جودة / ميزات المنتجات / الخدمات بموجب العروض.",
  adbCopyright: "حقوق النشر محفوظة لمصرف أبوظبي الإسلامي. ",
  copyrightsreserved: "حقوق النشر محفوظة",
  reserved: "محجوز",
  ftrText2: "من الأفضل مشاهدة الموقع في Chrome V23 + و Firefox V15 + و Safari 6+ و IE10 + بدقة 1024 × 768 بكسل.",
  ftrText3: "(من 9 إلى 21 ساعة ، من الإثنين إلى السبت فقط)",
  privacy: "خصوصية",
  sitemap: "خريطة الموقع",
  terms: "مصطلحات",
  allright: "كل الحقوق محفوظة",
  allrights: "جميع الحقوق",
  claSampTxt: "الأشياء الجيدة لا تنتظر! استرد هذه العروض قبل انتهاء صلاحيتها",
  claimNameTxt: "اشترِ 1 واحصل على 1 مجانًا على برجر ومكسرات",
  noclaimOffer: "لم تتم المطالبة بأي عروض حتى الآن",
  noClaimTxt: "لا تريد أن تفوت الفرص المناسبة؟ احصل على الصفقات والعروض التي تحبها على الفور",
  myWishlist:"قائمة امنياتي",
  wishlisted:"التاجر والعروض 'مدرجة في قائمة الرغبا' بواسطتك",
  sortBy:"ترتيب حسب",
  mapView:"عرض الخريطة",
  category:"فئة",
  price:"السعر",
  locations:"المواقع",
  rating:"تقييم",
  moreFilters:"المزيد من التصفية",
  merchantType:"نوع التاجر",
  offerType:"نوع العرض",
  discount:"خصم",
  cashback:"استرداد النقود",
  deals:"صفقات",
  cardType:"نوع البطاقة",
  debitCard:"بطاقة ائتمان",
  clearAll:"امسح الكل" ,
  showOffer:"اعرض أكثر من 300 عرض",
  allCategories:"جميع الفئات",
  noWishlist: "لا توجد عناصر في قائمة المفضلات الخاصة بك",
  noWishlistSub: "أضف العلامات التجارية والمنتجات والعروض التي ترغب في الحصول عليها أو تقديمها كهدية لمن تحب. حان الوقت لتصبح مبدعًا!",
  noredemp: "لا يوجد عمليات استرداد حتى الآن",
  noredempTxt: "هل تشعر بأنك تهدر وقتك؟ احصل على العروض والصفقات التي تحبها واستردها على الفور!",
  redemptionHea: "عمليات الاسترداد",
  redempTxt: "تحقق من جميع الاسترداد النقدي والمدخرات التي كسبتها من خلال",
  redeemed: "افتدى",
  spend: "أنفق",
  spent: "أنفق",
  saving: "إنقاذ",
  transID: "رقم المعاملة: ",
  descrip: "وصف",
  offers: "عروض",
  redSample: "20٪ استرداد نقدي على تذكرة المعرض لمدة 3 أيام",
  totSaving: "درهم إماراتي",
  totSpent: "درهم إماراتي",
  save: "حفظ",
  reviewAndSugg: "مراجعة واقتراح",
  rateExp: "قيم تجربتك",
  appFeedbackTxt: "الرجاء تحديد موضوع أدناه وإعلامنا بما يقلقك",
  feedPlacehold: "يرجى تخصيص بعض الوقت لمشاركة أفكارك معنا",
  alertTxt: "الرجاء كتابة مراجعة والاقتراح",
  rateTheExp: "قيم التجربة",
  reportBug: "أبلغ عن الأخطاء",
  attach:"إرفاق",
  custTxt: "، كيف يمكننا مساعدتك اليوم؟",
  needHelp: "هل تريد المزيد من المساعدة؟",
  custTxt2: "قد يساعدك استعراض الموضوعات الشائعة في قسم الأسئلة الشائعة على حل مشكلتك على الفور. ما زلت لا تجد ما تبحث عنه؟ لا تتردد في مراسلتنا وسنتعامل مع مخاوفك.",
  writetous: "اكتب لنا",
  status: "حالة",
  reqid: "طلب معرف",
  subject: "موضوعات",
  action: "عمل",
  reply: "الرد",
  cusStatus: "تم الحل",
  on: "تشغيل",
  cusItemTxt: "لكن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار  النشوة وتمجيد الألم",
  wishlisted:"التاجر والعروض 'مدرجة في قائمة الرغبا' بواسطتك",
  sortBy:"ترتيب حسب",
  mapView:"عرض الخريطة",
  category:"فئة",
  price:"السعر",
  locations:"المواقع",
  rating:"تقييم",
  moreFilters:"المزيد من التصفية",
  merchantType:"نوع التاجر",
  offerType:"نوع العرض",
  discount:"خصم",
  cashback:"استرداد النقود",
  coupon: "قسيمة",
  deals:"صفقات",
  cardType:"نوع البطاقة",
  debitCard:"بطاقة ائتمان",
  clearAll:"امسح الكل" ,
  showOffer:"اعرض أكثر من 300 عرض",
  allCategories:"جميع الفئات",
  noWishlist:"لا توجد عناصر في قائمة الرغبات الخاصة بك",
  noWishlistSub:"أضف العلامات التجارية والمنتجات والعروض التي تطمح في الحصول عليها أو هدية لمن تحب. حان الوقت لتصبح مبدعًا!",
  searchLoc: "البحث عن المنطقة، اسم الشارع..",
  searchCity: "ابحث عن المدينة",
  getCurrentLoc: "احصل على الموقع الحالي ",
  gps: "استخدام نظام تحديد المواقع",
  recentLoc:"المواقع الأخيرة",
  recommendLoc: "المواقع الموصى بها",
  abuDhabi:"أبو ظبي",
  abuDhabiSub:"الإمارات العربية المتحدة",
  noChoiceFound: "لم يتم العثور على الخيار المطلوب",
  nearMe:"بجانبي",
  myaccount: "حسابي",
  notifTxt: "يتم نشر تحديثات مثيرة حول صفقاتك وعروضك هنا",
  trycafe: 'جرّب "مقهى" أو "متاجر بيع بالتجزئة"',
  trycafe2: 'عما تبحث؟ جرّب "مقهى" أو "متاجر بيع بالتجزئة"',
  perfdeal: "لنجد لك أفضل العروض والصفقات في",
  perfDealCountry: " أبو ظبي",
  recentSearches: "عمليات البحث الأخيرة",
  mypersonalizedOff: "عروضي الشخصية ",
  expandTxt: "قم بالتوسيع للحصول على التفاصيل",
  claimNow: "يدعون الآن",
  processing: "يتم المعالجة",
  received: "تم الاستلام",
  rejected: "مرفوض",
  approved: "وافق",
  pending: "ريثما",
  countries:"الدول",
  langauges:"اللغات",
  backToMainMenu:"العودة إلى القائمة الرئيسية",
  choose:"يختار",
  popularSearches:"عمليات البحث الشعبية",
  search:"بحث"
}

export const arabicTempText={
  bannerText:"يجب ألا تكون الأدوية باهظة الثمن.",
  category:"الصحة والعافية",
  cardTitle:"بروتين بلدي",
  cardSubTitle:"30٪ خصم على جميع الدورات عبر الإنترنت.",
  learnMore:"يتعلم أكثر",
  online: "عبر الإنترنت",
  instore: "داخل-المتجر",
  expires:"تنتهي : ",
  carouselHeader:"صفقات اليوم ، وفر على هذه الصفقات الرائعة قبل أن تنتهي",
  tasteMatchScore:"طعم نتيجة المباراة",
  knowMore:"تعرف أكثر"
}

export const arabicHeaderMenu = {
  accountDetails:'تفاصيل الحساب',
  myTasteProfile:'الملف الشخصي ذوقي',
  claimedOffers:'العروض المطالب بها',
  redemptions:'عمليات الاسترداد',
  appFeedback:'ملاحظات التطبيق',
  customerSupport:'دعم العملاء',
  faq:'التعليمات',
  faqTitle:"أسئلة مكررة",
  notificationSettings:'إعدادات الإشعار',
  privacyPolicy:'سياسة خاصة',
  termsAndConditions:'الأحكام والشروط',
  logout: 'تسجيل خروج'
}

export const arabicMoreFilterModal = {
  title:'المزيد من المرشحات',
  merchantType:'نوع التاجر',
  online:'عبر الإنترنت',
  inStore: 'داخل-المتجر',
  offerType:'نوع العرض',
  discount:'خصم',
  cashBack:'استرداد النقود',
  deals:'صفقات',
  cardType:'نوع البطاقة',
  debitCard:'بطاقة ائتمان',
  clearAll:'امسح الكل',
  showOffers:'عرض العروض'
}

export const arabicNotification = {
  title:'إعدادات الإشعار',
  subTitle:'إعدادات الإعلام والرسائل إلى الرجاء تحديد موضوع أدناه',
  headingOne:'إشعارات',
  pushNotification:'دفع الإخطار',
  pushNotificationDesc:'لكن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكا',
  emailNotification:'إعلام البريد الإلكتروني',
  emailNotificationDesc:'لكن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكا',
  smsNotification:'إخطار الرسائل القصيرة',
  smsNotificationDesc:'لكن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكا',
  proximityNotification:'إشعار القرب',
  proximityNotificationDesc:'لكن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكا',
  messageTitle:'رسالة',
  promotionalMessages:'الرسائل الترويجية',
  promotionalMessagesDesc:'لكن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكا',
  transactionalMessages:'رسائل المعاملات',
  transactionalMessagesDesc:'لكن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكا',
  reminderMessages:'رسائل التذكير والتنبيه',
  reminderMessagesDesc:'لكن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكا'
}

export const otherSearch = [
  {
    item:{
    id:"763fc31a-7fd8-3e9d-a217-5746d626fd6d",
    name:"Sun & Sand Sports Llc",
    category:"retail stores",
    itemType:"MERCHANT",
    active:true,
    properties:{
    brandLogo:"https://dpimages.crayondata.com/high-res-image/resized/BE25773_Sun_and_Sports_Logo.png",
    curatedImage:"https://dpimages.crayondata.com/high-res-image/resized/offer/BE25773_Sunandsports_Merchant_Card.png",
    externalId:"BE25773",
    image:"https://dpimages.crayondata.com/high-res-image/resized/offer/BE25773_Sunandsports_Merchant_Card.png",
    merchantBannerImage:"https://dpimages.crayondata.com/high-res-image/resized/BE25773_SunandSports_Banner.png",
    url:"https://en-ae.sssports.com/home",
    tasteMatchScore:72
    },
    offers:[
    {
    id:"102953",
    name:"Get up to 20% off on your purchase at Sun and Sand Sports",
    description:"Get up to 20% off on your purchase at Sun and Sand Sports till 08-02-2023",
    validFrom:"2022-03-08T00:00:00.389Z",
    validTo:"2023-02-08T23:59:59.389Z",
    partner:true,
    isOnline:true,
    sensitiveFlag:false,
    offerActive:true,
    properties:{
    cashbackValue:0.0,
    curatedOfferImage:"https://dpimages.crayondata.com/high-res-image/resized/SSSports_Offer_Image_2560x1600.jpg",
    currency:"USD",
    howToRedeem:"1.\tClick on the 'Claim Now' button to proceed to the merchant page.\n2.\tPurchase products/services which are valid as per the T&C of the offer.\n3.\tPlease apply the coupon code at the checkout page before making the payment.\n4.\tPlease use a valid ADIB bank card to make the payment.",
    image:"https://dpimages.crayondata.com/high-res-image/resized/SSSports_Offer_Image_2560x1600.jpg",
    offerSource:"arabclicks",
    offerStatus:"active",
    offerType:"offline coupon",
    payoutType:"cpa_percentage",
    redemptionCode:"SA54",
    sensitiveFlag:false,
    termsAndConditions:[
    "1.The offer is valid upto 08-02-2023\n2.The offer is valid for Sports Apparel,Accessories only\n3.The offer gives 20% Off on purchase above AED 100 and 30% Off for purchase above AED 600\n4.The maximum discount that can be availed for this offer is AED 200\n5.The categories/products which are excluded from this offer are: N/A\n6.The offer is applicable for new and repeat users after login only\n7.The offer is valid on app & website purchases only\n8.Coupons are applicable for ADIB card users only\n9.Coupon is applicable for online purchase only\n10.Accidental loss of coupon shall not be covered \n11.Coupons are not transferable \n12.Coupons cannot be replaced if lost.\n13.Customer is redirected to third-party website - terms of third-party website will be applicable on the shopping experience\n14.Coupons cannot be clubbed with existing sale items or other active offers or coupons."
    ],
    url:"https://en-ae.sssports.com/home"
    }
    }
    ],
    tags:[
    "international",
    "shopping-mall",
    "multi-brand",
    "sporting-equipment",
    "online-and-in-store",
    "sports-and-outdoors",
    "premium"
    ],
    cities:[
    "all"
    ],
    isOnline:true,
    merchantType:[
    "online"
    ],
    isHyperLocal:false,
    searchScore:13.196000576019287
    },
    liked:false,
    wishlisted:false
  },
  {
    item:{
    id:"763fc31a-7fd8-3e9d-a217-5746d626fd6d",
    name:"Sun & Sand Sports Llc",
    category:"retail stores",
    itemType:"MERCHANT",
    active:true,
    properties:{
    brandLogo:"https://dpimages.crayondata.com/high-res-image/resized/BE25773_Sun_and_Sports_Logo.png",
    curatedImage:"https://dpimages.crayondata.com/high-res-image/resized/offer/BE25773_Sunandsports_Merchant_Card.png",
    externalId:"BE25773",
    image:"https://dpimages.crayondata.com/high-res-image/resized/offer/BE25773_Sunandsports_Merchant_Card.png",
    merchantBannerImage:"https://dpimages.crayondata.com/high-res-image/resized/BE25773_SunandSports_Banner.png",
    url:"https://en-ae.sssports.com/home",
    tasteMatchScore:72
    },
    offers:[
    {
    id:"102953",
    name:"Get up to 20% off on your purchase at Sun and Sand Sports",
    description:"Get up to 20% off on your purchase at Sun and Sand Sports till 08-02-2023",
    validFrom:"2022-03-08T00:00:00.389Z",
    validTo:"2023-02-08T23:59:59.389Z",
    partner:true,
    isOnline:true,
    sensitiveFlag:false,
    offerActive:true,
    properties:{
    cashbackValue:0.0,
    curatedOfferImage:"https://dpimages.crayondata.com/high-res-image/resized/SSSports_Offer_Image_2560x1600.jpg",
    currency:"USD",
    howToRedeem:"1.\tClick on the 'Claim Now' button to proceed to the merchant page.\n2.\tPurchase products/services which are valid as per the T&C of the offer.\n3.\tPlease apply the coupon code at the checkout page before making the payment.\n4.\tPlease use a valid ADIB bank card to make the payment.",
    image:"https://dpimages.crayondata.com/high-res-image/resized/SSSports_Offer_Image_2560x1600.jpg",
    offerSource:"arabclicks",
    offerStatus:"active",
    offerType:"offline coupon",
    payoutType:"cpa_percentage",
    redemptionCode:"SA54",
    sensitiveFlag:false,
    termsAndConditions:[
    "1.The offer is valid upto 08-02-2023\n2.The offer is valid for Sports Apparel,Accessories only\n3.The offer gives 20% Off on purchase above AED 100 and 30% Off for purchase above AED 600\n4.The maximum discount that can be availed for this offer is AED 200\n5.The categories/products which are excluded from this offer are: N/A\n6.The offer is applicable for new and repeat users after login only\n7.The offer is valid on app & website purchases only\n8.Coupons are applicable for ADIB card users only\n9.Coupon is applicable for online purchase only\n10.Accidental loss of coupon shall not be covered \n11.Coupons are not transferable \n12.Coupons cannot be replaced if lost.\n13.Customer is redirected to third-party website - terms of third-party website will be applicable on the shopping experience\n14.Coupons cannot be clubbed with existing sale items or other active offers or coupons."
    ],
    url:"https://en-ae.sssports.com/home"
    }
    }
    ],
    tags:[
    "international",
    "shopping-mall",
    "multi-brand",
    "sporting-equipment",
    "online-and-in-store",
    "sports-and-outdoors",
    "premium"
    ],
    cities:[
    "all"
    ],
    isOnline:true,
    merchantType:[
    "online"
    ],
    isHyperLocal:false,
    searchScore:13.196000576019287
    },
    liked:false,
    wishlisted:false
  },
  {
    item:{
    id:"763fc31a-7fd8-3e9d-a217-5746d626fd6d",
    name:"Sun & Sand Sports Llc",
    category:"retail stores",
    itemType:"MERCHANT",
    active:true,
    properties:{
    brandLogo:"https://dpimages.crayondata.com/high-res-image/resized/BE25773_Sun_and_Sports_Logo.png",
    curatedImage:"https://dpimages.crayondata.com/high-res-image/resized/offer/BE25773_Sunandsports_Merchant_Card.png",
    externalId:"BE25773",
    image:"https://dpimages.crayondata.com/high-res-image/resized/offer/BE25773_Sunandsports_Merchant_Card.png",
    merchantBannerImage:"https://dpimages.crayondata.com/high-res-image/resized/BE25773_SunandSports_Banner.png",
    url:"https://en-ae.sssports.com/home",
    tasteMatchScore:72
    },
    offers:[
    {
    id:"102953",
    name:"Get up to 20% off on your purchase at Sun and Sand Sports",
    description:"Get up to 20% off on your purchase at Sun and Sand Sports till 08-02-2023",
    validFrom:"2022-03-08T00:00:00.389Z",
    validTo:"2023-02-08T23:59:59.389Z",
    partner:true,
    isOnline:true,
    sensitiveFlag:false,
    offerActive:true,
    properties:{
    cashbackValue:0.0,
    curatedOfferImage:"https://dpimages.crayondata.com/high-res-image/resized/SSSports_Offer_Image_2560x1600.jpg",
    currency:"USD",
    howToRedeem:"1.\tClick on the 'Claim Now' button to proceed to the merchant page.\n2.\tPurchase products/services which are valid as per the T&C of the offer.\n3.\tPlease apply the coupon code at the checkout page before making the payment.\n4.\tPlease use a valid ADIB bank card to make the payment.",
    image:"https://dpimages.crayondata.com/high-res-image/resized/SSSports_Offer_Image_2560x1600.jpg",
    offerSource:"arabclicks",
    offerStatus:"active",
    offerType:"offline coupon",
    payoutType:"cpa_percentage",
    redemptionCode:"SA54",
    sensitiveFlag:false,
    termsAndConditions:[
    "1.The offer is valid upto 08-02-2023\n2.The offer is valid for Sports Apparel,Accessories only\n3.The offer gives 20% Off on purchase above AED 100 and 30% Off for purchase above AED 600\n4.The maximum discount that can be availed for this offer is AED 200\n5.The categories/products which are excluded from this offer are: N/A\n6.The offer is applicable for new and repeat users after login only\n7.The offer is valid on app & website purchases only\n8.Coupons are applicable for ADIB card users only\n9.Coupon is applicable for online purchase only\n10.Accidental loss of coupon shall not be covered \n11.Coupons are not transferable \n12.Coupons cannot be replaced if lost.\n13.Customer is redirected to third-party website - terms of third-party website will be applicable on the shopping experience\n14.Coupons cannot be clubbed with existing sale items or other active offers or coupons."
    ],
    url:"https://en-ae.sssports.com/home"
    }
    }
    ],
    tags:[
    "international",
    "shopping-mall",
    "multi-brand",
    "sporting-equipment",
    "online-and-in-store",
    "sports-and-outdoors",
    "premium"
    ],
    cities:[
    "all"
    ],
    isOnline:true,
    merchantType:[
    "online"
    ],
    isHyperLocal:false,
    searchScore:13.196000576019287
    },
    liked:false,
    wishlisted:false
  },
]