/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Footer Component
import React, { PureComponent } from "react";

import Image from "../Image";
import Icons from "../Icons";

import GooglePlay from "../../assets/img/google_play.png";
import AppImage from "../../assets/img/app_store.png";
import contact from "../../assets/img/contact.png"
import { withRouter } from "react-router";
import moment from "moment";
import {arabicContent} from "../../constants/index";
import uiConfig from "../../uiConfig";
import config from "../../service/config"
const lang = localStorage.getItem("language");

class Footer extends PureComponent {
  /* Other Render Functions */
  _renderTitle(title) {
    return <h5 className="ftr-sub">{title}</h5>;
  }

  routeToProfile=(activeItem)=>{
    this.props.setActiveItem(activeItem);
    sessionStorage.setItem("currPage",activeItem);
    this.props.history.push("/home/profile", activeItem)
  }
  /* Main Render Functions */
  render() {
       
    return (   
      <>
      {this.props.history.location.pathname!=="/login"&&
      <div className="col-md-12 p-0 ftr-main" style={{backgroundColor:uiConfig.config.whitelabel.footer.background}}>
          <div className="display-flex">
            <div className="justify-start">
              {/* <div>{lang === 'AR' ? arabicContent.aboutus : `About Us`}</div> */}
              <div style={uiConfig.config.whitelabel.footer.heading}
              className={`cursor ${lang === 'AR' ? "ftr-text-div-ar" : ""}`}
              onClick={()=>{this.routeToProfile("faq")}}
              >
                {lang === 'AR' ? arabicContent.faq : `FAQs`}
              </div>
              <div style={uiConfig.config.whitelabel.footer.heading}
                className={`ftr-text-div cursor ${lang === 'AR' ? "ftr-text-div-ar" : ""}`}
                onClick={()=>{this.routeToProfile("termsAndCondition")}}
              >
                {lang === 'AR' ? arabicContent.termAndCond : `Terms & Conditions`}
              </div>
              <div style={uiConfig.config.whitelabel.footer.heading}
                className={`ftr-text-div cursor ${lang === 'AR' ? "ftr-text-div-ar" : ""}`}
                onClick={()=>{this.routeToProfile("privacy")}}
              >
                {lang === 'AR' ? arabicContent.privPolicy : `Privacy Policy`}
              </div>
              {/* <div className={`ftr-text-div ${lang === 'AR' ? "ftr-text-div-ar" : ""}`}>
                {lang === 'AR' ? arabicContent.custSupport : `Customer Support`}
              </div> */}
            </div>
            <div className="justify-end">
            {config.clientName === "MAYA" && <div className="contact-div">
                <span><img src={contact} alt="contact"/></span> 
                <span className={`contact-phone ${lang === 'AR' ? "contact-phone-ar" : ""}`}>18604251188 / 9986896688</span>
              </div>}
            </div>
          </div>

        <div className="flex footer-alignment">
          <div className={config.clientName === "MAYA"? "col-md-9 p-0":"col-md-12 p-0"}>
            {config.clientName === "HDFC" ? <div className="ftr-disc-text" style={{color:uiConfig.config.whitelabel.footer.description}}>{lang === 'AR' ? arabicContent.ftrdisclaimer : `Disclaimer:  Smart Buy is a platform for communication of offers extended by Merchants to HDFC bank's Customers. HDFC is only communicating the offers extended by Merchants to its Customers and not selling/rendering any of these products/services. HDFC is merely facilitating the payment to its Customers by providing the Payment Gateway Services. HDFC is neither guaranteeing nor making any representation of the same. HDFC is not responsible for sales/quality/features of the products/services under the offers.`}</div>
            :<div className="ftr-disc-text" style={{color:uiConfig.config.whitelabel.footer.description}}>{lang === 'AR' ? arabicContent.ftrdisclaimer : `Disclaimer:  maya.ai  is a platform for communication of offers extended by Merchants to Bank's Customers. Bank is only communicating the offers extended by Merchants to its Customers and not Selling/Rendering any of these Products/Services.  Bank is merely facilitating the payment to its Customers by providing the Payment Gateway Services.  Bank is neither guaranteeing nor making any representation. Bank is not responsible for Sale/Quality/Features of the Products/Services under the offers.`}</div>
            }
            <div className="col-md-12 p-0 ftr-site-text" style={{color:uiConfig.config.whitelabel.footer.description}}>{lang === 'AR' ? arabicContent.ftrText2 : `Site is best viewed in Chrome V23+, Firefox V15+, Safari 6+ and IE10+ at 1024 x 768 pixels resolution.`}</div>
          </div>

          {config.clientName === "MAYA" && <div className="col-md-3 p-0 contact">
            <div style={{color:uiConfig.config.whitelabel.footer.description}} className={`ftr-support ${lang === 'AR' ? "ftr-support-ar" : ""}`}>{lang === 'AR' ? arabicContent.ftrText3 : `(9 to 21 hrs, Mon to Sat Only)`}</div>
            <div style={{color:uiConfig.config.whitelabel.footer.description}} className={`ftr-sup-website cursor ${lang === 'AR' ? "ftr-sup-website-ar" : ""}`}><a href="mailto:support@maya.ai">support@maya.ai</a></div>   
          </div>}
        </div> 

        <div className="col-md-12 p-0 border-line" />
        <div className="col-md-12 p-0 mt-20 flex wrap-ftr">
          <div className="flex-start ftr-txt" style={{color:uiConfig.config.whitelabel.footer.foottext}}>
          <span className={`f-left ${lang === 'AR' ? "flex-row-reverse" : ""}`}>
            <span>{lang === 'AR' ? '' : `©`}</span>
            <span> {moment().format("YYYY")} </span>
            {config.clientName === "HDFC" ? <span> {`${config.clientName} Smartbuy, Inc. `}</span> : <span> {`${config.clientName} Bank Ltd`}</span>}
            {lang === 'AR' ? arabicContent.allright : `All rights reserved`} · 
          </span>
          <span className="f-right">
          <span className="cursor ml-1" onClick={()=>{
            this.routeToProfile("privacy")
           }}>   {lang === 'AR' ? arabicContent.privacy : `Privacy`} · </span>
           <span className="cursor" onClick={()=>{
            this.routeToProfile("termsAndCondition")
           }}>   {lang === 'AR' ? arabicContent.terms : `Terms`} · </span>{lang === 'AR' ? arabicContent.sitemap : `Sitemap`}
          </span>
          </div>
          <div className="flex-end ftr-txt">
              <Icons name="mayatext" width={160} height={''} />
          </div>

          </div>
          
      </div>
    }
    </>
      
    );
  }

}


export default withRouter(Footer)