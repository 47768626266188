/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Checkbox
import React from "react";
import PropTypes from "prop-types";

const CheckBox = ({ name, value, label, handleCheckBox, disabled ,style}) => {
  return (
    <div className="form-group" style={style}>
      <label
        className={["container-checkbox", disabled ? "disabled" : ""].join(" ")}
      >
        {label}
        <input
          type="checkbox"
          checked={value}
          disabled={disabled}
          onChange={(e) => handleCheckBox(name, e.target.checked)}
        />
        <span className="checkmark"></span>
      </label>
    </div>
  );
};

export default CheckBox;

CheckBox.defaultProps = {
  value: false,
  label: ""
};

CheckBox.propsTypes = {
  value: PropTypes.bool.isRequired,
  label: PropTypes.string
};
