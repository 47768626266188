import React from "react";
import uiConfig from "../../uiConfig";
import Maya from "../../assets/lottieImages/site-loader-maya.png";
import Adib from "../../assets/lottieImages/site-loader-adib.png";
import HDFC from "../../assets/lottieImages/site-loader-hdfc.png";
import CountUp from 'react-countup';
import "./style.scss";

function CommonLoader() {
  const lang = localStorage.getItem("language");

  const logomatch = {
    maya: Maya,
    adib: Adib,
    hdfc: HDFC
  };

  return (
    // <div className="loader m-0">
    //   <div className="new_loader">
    //     <img
    //       src={uiConfig.config.whitelabel.clientName === 'maya' ? Maya : Adib}
    //       title="loader"
    //       alt="loader"
    //       className="img-responsive mx-auto"
    //       width="400px"
    //       height="250px"
    //     />
    //     <div>
    //       <p className="text-center">
    //         Sit tight while we do some big data crunching...
    //       </p>
    //     </div>
    //   </div>
    // </div>
    <div className="page-loader">
      <div className="page-loader-wrap" style={{ background: uiConfig.config.whitelabel.components.siteLoader.bgColor }}>
        <div className="page-counter">
          <CountUp start={0} end={100} delay={0} duration={18}>
            {({ countUpRef }) => (
              <div>
                <span ref={countUpRef} style={{ color: uiConfig.config.whitelabel.components.siteLoader.counterColor }} className={lang === 'AR' ? "counter-number-right" : "counter-number"} /><span className={lang === 'AR' ? "percentage-symbol-right" : "percentage-symbol"} style={{ color: uiConfig.config.whitelabel.components.siteLoader.counterColor }}>%</span>
              </div>
            )}
          </CountUp>
        </div>
        <div className="page-loader-image" style={{ height: '50%' }} >
          <img
            src={logomatch[uiConfig.config.whitelabel.clientName]}
            title="loader"
            alt="loader"
            className="img-responsive mx-auto"
            width="200px"
            height="150px"
          />
          <div className="page-footer-text pft" >
            {lang === 'AR' ? <h3>محرك التخصيص الذي يعمل بالذكاء الاصطناعي والمخصص لفهم الأذواق البشرية</h3> : <h3><span>Personalized</span> offer discovery with your favourite brands<span></span></h3>}

          </div>
        </div>
      </div>
    </div>
  );
}

export default CommonLoader;