import React, { PureComponent } from "react";
import Modal from "react-modal";
import Icons from "../Icons";
import CheckBox from "../CheckBox";
import Loader from "../WishListed/listloader";
import closeIcon from "../../assets/img/close_login.png";
import tcSuccess from "../../assets/img/tc_success.png";
import tcSkip from "../../assets/img/tc_skip.png";
import {
  getCategories,
  getTags,
  getMerchants,
  updateTastePreference
} from "../../service/index";

import "./index.scss";

const customStyles = {
  overlay: {
    background: "rgba(0, 0, 0, 0.4)",
    zIndex: 1000
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "970px",
    height: "520px",
    padding: "0px",
    background: "#FFFFFF",
    borderRadius: "8px",
    border: "none"
  }
};

class TasteCollectionPopup extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      section: "category",
      categories: [],
      selectedCategory: "",
      tags: null,
      merchants: null,
      showSuccessModal: false,
      showSkippedModal: false,
      timeoutId: 0
    };
  }

  componentDidMount() {
    if (localStorage.getItem("subCategory")) {
      let { categories, tags, merchants, selectedCategory } = JSON.parse(
        localStorage.getItem("subCategory")
      );
      this.setState({
        section: "subCategory",
        categories,
        tags,
        merchants,
        selectedCategory
      });
      localStorage.removeItem("subCategory");
    } else {
      getCategories()
        .then((result) => {
          let categories = result?.categories;
          this.setState({ categories }, ()=>{
            this.onClickNext(true)
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }

  componentDidUpdate() {}

  onClickNext = (intialLoad) => {
    const { categories, section } = this.state;
    if (categories?.filter((x) => x?.selected)?.length >= 3) {
      let selectedCategories = categories
        ?.filter((x) => x?.selected)
        ?.map((x) => x?.value);
      getTags(selectedCategories)
        .then((result) => {
          this.setState({ tags: result?.tags });
        })
        .catch((error) => {
          console.log("error", error);
        });
      getMerchants(selectedCategories)
        .then((result) => {
          this.setState({ merchants: result?.merchants });
        })
        .catch((error) => {
          console.log("error", error);
        });
      this.setState({
        section: intialLoad ? section : "subCategory",
        selectedCategory: selectedCategories[0]
      });
    }
  };

  onClickSubmit = (skip) => {
    const { categories, tags, merchants, section, selectedCategory } =
      this.state;
      const { getRecentSearchData } = this.props;
    if (skip && section === "subCategory") {
      localStorage.setItem(
        "subCategory",
        JSON.stringify({ categories, tags, merchants, selectedCategory })
      );
    }
    let selectedCategories = categories
      ?.filter((x) => x?.selected)
      ?.map((x) => x?.value);
    let temp = selectedCategories.map((selectedCategory) => {
      return {
        categoryName: selectedCategory,
        tags:
          tags && tags[selectedCategory]
            ? tags[selectedCategory]
                ?.filter((tag) => tag?.selected)
                ?.map((tag) => tag?.name)
            : [],
        merchants:
          merchants && merchants[selectedCategory]
            ? merchants[selectedCategory]
                ?.filter((merchant) => merchant?.selected)
                ?.map((merchant) => merchant?.id)
            : []
      };
    });
    updateTastePreference(temp, skip ? false : true)
      .then((result) => {
        console.log("updateTastePreference", result);
        const timeoutId = setTimeout(() => {
          this.props.setTcPopup(false);
        }, 10000);
        if (skip) this.setState({ showSkippedModal: true, timeoutId });
        else 
        {
        this.setState({ showSuccessModal: true, timeoutId });
        getRecentSearchData();
      }
      })
      .catch((error) => {
        console.log("error", error);
        this.props.setTcPopup(false);
      });
  };

  onSelectTagsandMerchants = (val, selected, index) => {
    let { selectedCategory } = this.state;
    let temp = { ...val };
    temp[selectedCategory][index].selected = !selected;
    this.setState({ temp });
  };

  toggleCategorySelection = (index) => {
    let temp = [...this.state.categories];
    temp[index].selected = !temp[index].selected;
    this.setState({ categories: temp });
  };

  render() {
    const {
      section,
      selectedCategory,
      categories,
      tags,
      merchants,
      showSuccessModal,
      showSkippedModal,
      timeoutId
    } = this.state;
    const { setTcPopup } = this.props;
    const selectedCategories =
      categories?.filter((x) => x?.selected)?.map((x) => x?.value) || [];

    const hasEnoughTags = selectedCategories.every((category) => {
      return (
        tags && tags[category]?.filter((tag) => tag?.selected)?.length >= 2
      );
    });

    const hasEnoughMerchants = selectedCategories.every((category) => {
      return (
        merchants &&
        merchants[category]?.filter((merchant) => merchant?.selected)?.length >=
          2
      );
    });

    const enableSubmit =
      tags && merchants && hasEnoughTags && hasEnoughMerchants;

    return showSuccessModal || showSkippedModal ? (
      <div className="success-modal">
        <div className="sm-content">
          <div
            className="close-btn"
            onClick={() => {
              clearTimeout(timeoutId);
              this.props.setTcPopup(false);
            }}
          >
            <img loading="lazy" src={closeIcon} width={32} height={32} />
          </div>
          <div className="gif-content">
            {showSkippedModal ? (
              <img
                loading="lazy"
                src={tcSkip}
                width={85}
                height={85}
                className="tc-skip"
              />
            ) : (
              <img loading="lazy" src={tcSuccess} className="tc-success" />
            )}
          </div>
          <div className="main-content">
            {showSkippedModal
              ? "Skipped Taste Collection"
              : "Taste Collection Successful!"}
          </div>
          <div className="sub-content">
            {showSkippedModal
              ? "No problem! You can complete the Taste Collection flow by clicking on ‘Taste Preference’ under ‘My Profile’"
              : "Thanks for letting us know your preferences. We are generating recommendations based on your preference. This might take a few secs."}
          </div>
        </div>
      </div>
    ) : (
      <Modal isOpen={true} style={customStyles} contentLabel="Login Modal">
        <div className="taste-modal">
          <div className="top-sec">
            <div
              className="close-btn"
              onClick={() => {
                this.onClickSubmit(true);
              }}
            >
              <img src={closeIcon} width="32px" height="32px" />
            </div>
            <button
              className="btn mr-10 tskp"
              onClick={() => {
                this.onClickSubmit(true);
              }}
            >
              Skip
            </button>
          </div>
          {section === "category" && categories?.length > 0 ? (
            <div className="cat-section">
              <div className="cat-title">
                Want to find the most relevant deals easily? Tell us about your
                preference and let us do the magic
              </div>
              <div className="cat-sub">
                Select your top 3 favourite categories
              </div>
              <div className="cat-list">
                {categories.map((cat, index) => (
                  <div
                    className={
                      "cat-item cursor " + (cat?.selected ? "selected" : "")
                    }
                    key={index}
                  >
                    <div className="flex-end">
                      <CheckBox
                        label={null}
                        name={cat?.value}
                        value={cat?.selected}
                        handleCheckBox={(e) => {
                          this.toggleCategorySelection(index);
                        }}
style={{marginTop:"14px" ,marginRight:"7px" ,marginRight:"5px" , position:'absolute'}}
                      />
                    </div>
                    <div
                      className="category-img"
                      onClick={() => {
                        this.toggleCategorySelection(index);
                      }}
                    >
                      <img
                        src={
                          cat?.selected ? cat?.highlightedImageUrl : cat?.webUrl
                        }
                        alt="cat-icon"
                        id={cat?.value}
                      />
                      <div className="cat-name text-center">{cat?.label}</div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="warning-text">* Select atleast 3 categories</div>
              <div className="bot-sec">
                <div className="cat-sel">
                  {selectedCategories?.length}/{categories?.length} Categories
                  Selected
                </div>
                <div className="button-sec">
                  <button
                    className="btn mr-10 skp"
                    onClick={() => {
                      this.onClickSubmit(true);
                    }}
                  >
                    Skip
                  </button>
                  <button
                    className="btn nxt"
                    style={{
                      backgroundColor:
                        selectedCategories?.length >= 3 ? "#FF9231" : "#E5E7EB",
                      color:
                        selectedCategories?.length >= 3 ? "#FFFFFF" : "#000"
                    }}
                    onClick={()=>{this.onClickNext(false)}}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          ) : tags && merchants ? (
            <div className="sub-section">
              <div
                className="back-btn cursor"
                onClick={() => {
                  this.setState({ section: "category" });
                }}
              >
                <Icons name="arrleft" width={15} fill="#000" />
                Back
              </div>
              <div className="cat-title">Taste Collection</div>
              <div className="cat-sub">
                Select your prefered Tags & Merchants
              </div>
              <div className="nav-tab">
                {categories
                  ?.filter((x) => x?.selected)
                  ?.map((cat, index) => (
                    <div
                      className={
                        "nav-item cursor " +
                        (selectedCategory === cat?.value ? "selected" : "")
                      }
                      key={index}
                      onClick={() => {
                        this.setState({ selectedCategory: cat?.value });
                      }}
                    >
                      <img
                        src={
                          selectedCategory === cat?.value
                            ? cat?.highlightedImageUrl
                            : cat?.webUrl
                        }
                        alt="cat-icon"
                        width={20}
                        height={20}
                        id={cat?.value}
                      />
                      {cat?.label}
                    </div>
                  ))}
              </div>
              <div className="tag-section">
                <div className="tag-title">
                  1. Select what you look for in a {selectedCategory} merchant
                </div>
                <div className="tags">
                  {tags &&
                    tags[selectedCategory]?.map((tag, index) => (
                      <div
                        className={
                          "tag-item cursor " + (tag?.selected ? "selected" : "")
                        }
                        key={index}
                        onClick={() => {
                          this.onSelectTagsandMerchants(
                            tags,
                            tag?.selected,
                            index
                          );
                        }}
                      >
                        {tag?.name}
                      </div>
                    ))}
                </div>
                <div className="warning-text">
                  * Select atleast 2 categories
                </div>
              </div>
              <div className="merchants-section">
                <div className="merchant-title">
                  2. Select your preferred merchants
                </div>
                <div className="merchants">
                  {merchants &&
                    merchants[selectedCategory]?.map((merchant, index) => (
                      <div
                        className={
                          "merchant-item cursor " +
                          (merchant?.selected ? "selected" : "")
                        }
                        key={index}
                        onClick={() => {
                          this.onSelectTagsandMerchants(
                            merchants,
                            merchant?.selected,
                            index
                          );
                        }}
                      >
                        <img
                          src={merchant?.logo}
                          alt="cat-icon"
                          id={merchant?.id}
                        />
                      </div>
                    ))}
                </div>
              </div>
              <div className="bot-sec">
                <div className="cat-sel"></div>
                <div className="button-sec">
                  <button
                    className="btn mr-10 skp"
                    onClick={() => {
                      this.onClickSubmit(true);
                    }}
                  >
                    Skip
                  </button>
                  <button
                    className="btn nxt"
                    style={{
                      backgroundColor: enableSubmit ? "#FF9231" : "#E5E7EB",
                      color: enableSubmit ? "#FFFFFF" : "#000"
                    }}
                    onClick={() => {
                      if (enableSubmit) this.onClickSubmit();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="loader-comp">
              <Loader />
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

export default TasteCollectionPopup;
