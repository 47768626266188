/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Helper
import moment from "moment";
import "moment/min/locales";
import 'moment/locale/ar';
import 'moment/locale/en-au'

export const deepEqual = (one, two) => {
  return JSON.stringify(one) === JSON.stringify(two);
};

export const currencyFormatter = (data) => {
  return `$ ${data}.00`;
};

export const formatDate = (date) => {
  return moment(date).locale("en").format("YYYY-MM-DD");
};

export const formatDateTime = (date) => {
  return moment(date).locale("en").format("DD MMM YYYY HH:mm");
};

export const formatTime = (date, time) => {
  let newDate = new Date(`${date} ${time}`);
  let convertedTime = moment(newDate).locale("en").format("hh:mm A");
  return convertedTime;
};

export const sliceTime = (time) => {
  return time.slice(0, 5);
};

export const formatDay = (date) => {
  var weekDayName = moment(date).format("dddd");
  return weekDayName.slice(0, 3);
};
export const formatDateDDMMMYYH = (date) => {
  return moment(date).locale("en").format("DD-MMM-YY");
};

export const formatDateDDMMM = (date) => {
  return moment(date).locale("en").format("DD-MMM");
};

export const formatDateDDMMMYYYY = (date) => {
  return moment.utc(date).locale("en").format("DD MMM YYYY ");
};

export const formatDateProfile = (date) => {
  return moment(date).locale("en").format("MMMM D, YYYY ");
};
export const formatDateProfileMonth = (date) => {
  return moment.utc(date).format("MMM D, YYYY ");
};
export const formatDateProfileMonthArabic = (date) => {
moment.updateLocale('ar',{
  preparse: function (string) {
    return string;
  },
  postformat: function (string) {
    return string;
  }

})
  return moment.utc(date).locale('ar').format("YYYY MMM DD ");
};
export const formatMonth = (date) => {
  return moment(date).locale("en").format("MMM YYYY ");
};

export const formatMonthAR = (date) => {
  return moment(date).locale("ar").format("MMM ")+moment(date).locale("en").format(" YYYY ");
};

export const formatDateDDMMMYY = (date) => {
  return moment(date).locale("en").format("DD MMM YY");
};
export const formatDateDDMMMYYWithApostrophe = (date) => {
  return moment(date).format("DD MMM ' YY");
};
export const formatDateDDMMYY = (date) => {
  return moment(date).locale("en").format("DD/MM/YY");
};
export const formatDateAndTimeTimeStamp = (date, time) => {
  return date + "T" + time.substring(0, 8);
};

export const formatDateArabicDDMYYYY = (date) => {
  let mon = moment.utc(date).locale("ar_SA").format('MMMM');
  return moment.utc(date).format(`YYYY ${mon} DD`);
}

export const dayDifference = (date) => {
  let a = moment();
  let b = moment(date);
  return b.diff(a, 'days');
};

export const hourDifference = (date) => {
  let a = moment();
  let b = moment(date);
  return b.diff(a, 'hours');
};

export const formatTimeHourMinute = (date) => {
  const now = moment(moment().format('YYYY-MM-DD HH:mm:ss'));
  const end = moment(moment(date).utc().format('YYYY-MM-DD HH:mm:ss'));
  // let duration = end.diff(now, 'seconds');
  // return moment({}).seconds(duration).format("H [hours] mm [min]");
  const duration = moment.duration(end.diff(now));

  //Get Hours
  const hours = duration.hours();
  const hoursFormatted = `${hours} hours `;

  //Get Minutes
  const mints = duration.minutes();
  const minFormatted = `${mints} min`;

  return [hoursFormatted, minFormatted].join('');
};

export const formatDayHour = (date) => {
  let now = moment().utcOffset(0, true);
  let end = moment.utc(date).utcOffset(0, true)
  const duration = moment.duration(end.diff(now));

  //Get Days
  const days = Math.floor(duration.asDays()); // .asDays returns float but we are interested in full days only
  const daysFormatted = days ? `${days} day ` : ''; // if no full days then do not display it at all

  //Get Hours
  const hours = duration.hours();
  const hoursFormatted = `${hours} hrs`;

  return [daysFormatted, hoursFormatted].join('');
};

export const formatPrice = (price) => {
  return new Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(
    price
  );
};

export const toTitleCase = (str) => {
  if(str)
  return str.replace(/\w\S*/g, function(txt){
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export const truncateStr = (str, n, width) => {
  if(str) {
    if(width > 800) {
      return (str.length > n) ? str.substr(0, n-1) + '...' : str;
    }else {
      return (str.length > n) ? str.substr(0, n)+ " " + '...' : str;
    }
  }
};

export const getQuery = (paramsString,val) =>{
  return new URLSearchParams(paramsString).get(val)
}

export const eventBus = {
  on(event, callback) {
    document.addEventListener(event, (e) => callback(e.detail));
  },
  dispatch(event, data) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event, callback) {
    document.removeEventListener(event, callback);
  },
};

export default eventBus;

export const itemList = [
  {
    item: {
      id: "28a4abf6-d6e6-4a0b-b72b-64e90bd487cf",
      name: "Trip.com",
      description:
        "Choose from over 1.2 million hotels in more than 200 countries, 30 million real guest reviews. Book flights to over 5000 destinations worldwide.",
      category: "travel",
      sponsored: false,
      transactionCount: 0,
      properties: {
        image:
          "https://media.go2speed.org/brand/files/arabclicks/183/adv_tripcom_icon.jpg",
        externalId: "AC317827579905",
        distance: 0.0,
        url: "https://www.trip.com/"
      },
      offers: [
        {
          id: "183",
          name: "trip.com",
          description:
            "This Offer is valid till 3/27/2023\nThis offer can be availed in  Travel categories.\nThis offer can be redeemed through the brand's mobile app as well as the website.\nSuccessful and verified transaction will result in 1.40% cashback.",
          validFrom: "2020-12-08T00:00:00.389Z",
          validTo: "2023-03-27T23:59:59.389Z",
          partner: true,
          isOnline: true,
          properties: {
            longDescription: "",
            redemptionCode: "",
            image:
              "https://media.go2speed.org/brand/files/arabclicks/183/adv_tripcom_icon.jpg",
            payoutType: "",
            shortDescription: "",
            cashbackValue: "1.40%",
            offerStatus: "active",
            url: "",
            termsAndConditions: [
              "This Offer is valid till 3/27/2023\nThis offer is valid at None\nOffer is applicable for selected purchases/categories. This offer can be availed in  Travel categories only.\nCustomer is redirected to third party website. Terms of third party website will be applicable on the shopping experience. \nCashback is subject to merchant approval, returns, refunds, bank approval etc.\nCashback is only applicable on the usage of $BANKNAME$ credit card and debit card purchases. \nApproved cashback will be credited within 90 days of successful purchase."
            ],
            offerType: "Cashback",
            offerSource: "arabclicks",
            howToRedeem:
              "Click on 'Claim' at the bottom of the Offer Page. \nClick on 'Redeem Now' to get redirected to the merchant website. \nAll you need to do is shop in the categories that the cashback is eligible for. \nAfter completing the transaction, monitor the status of your cashback in the Purchase History page in the Bazaar App.",
            redemptionSteps: "",
            offerSourceImage: ""
          }
        }
      ],
      tags: [
        "international",
        "car-rental",
        "train-ticket-booking",
        "travel-agency",
        "hotel-booking",
        "travel-agencies-and-operators",
        "air-ticket-booking",
        "online"
      ],
      cities: ["all"],
      isOnline: true
    },
    scoredItem: null,
    liked: null,
    wishlisted: false
  },
  {
    item: {
      id: "2aadf07d-9a66-4c8d-bad0-120975179e57",
      name: "Emirates Holidays",
      description:
        "Emirates Holidays provides great value premium, tailor-made holidays across the globe with our award-winning Emirates Airline.",
      category: "travel",
      sponsored: false,
      transactionCount: 0,
      properties: {
        image:
          "https://media.go2speed.org/brand/files/arabclicks/242/adv_emiratesholidays_icon.jpg",
        externalId: "AC807453851648",
        distance: 0.0,
        url: "https://www.emiratesholidays.com/eg_en/offers/special-promo"
      },
      offers: [
        {
          id: "420",
          name: "Emirates Holidays",
          description:
            "This offer is eligible for first time users only.\nThis Offer is valid till 12/31/2022\nThis offer can be availed in  Travel, Recreation & Leisure categories.\nThis offer can be redeemed only through the brand Website.\nSuccessful and verified transaction will result in 0.02 cashback.",
          validFrom: "2020-12-08T00:00:00.389Z",
          validTo: "2022-12-31T23:59:59.389Z",
          partner: true,
          isOnline: true,
          properties: {
            longDescription: "",
            redemptionCode: "",
            image:
              "https://media.go2speed.org/brand/files/dcm/420/Emirates Holidays Logo.png",
            payoutType: "cpa_percentage",
            shortDescription: "",
            cashbackValue: "2.00%",
            offerStatus: "active",
            url: "",
            termsAndConditions: [
              "This Offer is valid till 12/31/2022\nThis offer is valid at None\nOffer is applicable for selected purchases/categories. This offer can be availed in  Travel, Recreation & Leisure categories only.\nCustomer is redirected to third party website. Terms of third party website will be applicable on the shopping experience. \nCashback is subject to merchant approval, returns, refunds, bank approval etc.\nCashback is only applicable on the usage of $BANKNAME$ credit card and debit card purchases. \nApproved cashback will be credited within 90 days of successful purchase."
            ],
            offerType: "Cashback",
            offerSource: "DCM",
            howToRedeem:
              "Click on 'Claim' at the bottom of the Offer Page. \nClick on 'Redeem Now' to get redirected to the merchant website. \nAll you need to do is shop in the categories that the cashback is eligible for. \nAfter completing the transaction, monitor the status of your cashback in the Purchase History page in the Bazaar App.",
            redemptionSteps: "",
            offerSourceImage: ""
          }
        }
      ],
      tags: [
        "travel-agencies-and-operators",
        "tour-operator",
        "air-ticket-booking"
      ],
      cities: ["all"],
      isOnline: true
    },
    scoredItem: null,
    liked: null,
    wishlisted: false
  },
  {
    item: {
      id: "45547087-e4b5-40d6-84bd-1a45fd116d80",
      name: "Viator",
      description:
        "Book Tours & Activities from Viator Travel With An Insider. Skip the Line Tickets. 345,000+ Things to Do. 24/7 Live Support.",
      category: "travel",
      sponsored: false,
      transactionCount: 0,
      properties: {
        image:
          "https://media.go2speed.org/brand/files/arabclicks/311/adv_viator_icon.jpg",
        externalId: "AC1142461300738",
        distance: 0.0,
        url: "https://www.viator.com"
      },
      offers: [
        {
          id: "311",
          name: "viator",
          description:
            "This Offer is valid till 7/17/2023\nThis offer can be availed in  Travel, Entertainment & Restaurants categories.\nThis offer can be redeemed through the brand's mobile app as well as the website.\nSuccessful and verified transaction will result in 1.50% cashback.",
          validFrom: "2020-12-08T00:00:00.389Z",
          validTo: "2023-07-17T23:59:59.389Z",
          partner: true,
          isOnline: true,
          properties: {
            longDescription: "",
            redemptionCode: "",
            image:
              "https://media.go2speed.org/brand/files/arabclicks/311/adv_viator_icon.jpg",
            payoutType: "",
            shortDescription: "",
            cashbackValue: "1.50%",
            offerStatus: "active",
            url: "https://go.arabclicks.com/aff_c?offer_id=311&aff_id=68962",
            termsAndConditions: [
              "This Offer is valid till 7/17/2023\nThis offer is valid at https://go.arabclicks.com/aff_c?offer_id=311&aff_id=68962\nOffer is applicable for selected purchases/categories. This offer can be availed in  Travel, Entertainment & Restaurants categories only.\nCustomer is redirected to third party website. Terms of third party website will be applicable on the shopping experience. \nCashback is subject to merchant approval, returns, refunds, bank approval etc.\nCashback is only applicable on the usage of $BANKNAME$ credit card and debit card purchases. \nApproved cashback will be credited within 90 days of successful purchase."
            ],
            offerType: "Cashback",
            offerSource: "arabclicks",
            howToRedeem:
              "Click on 'Claim' at the bottom of the Offer Page. \nClick on 'Redeem Now' to get redirected to the merchant website. \nAll you need to do is shop in the categories that the cashback is eligible for. \nAfter completing the transaction, monitor the status of your cashback in the Purchase History page in the Bazaar App.",
            redemptionSteps: "",
            offerSourceImage: ""
          }
        }
      ],
      tags: [
        "international",
        "travel-agencies-and-operators",
        "tour-operator",
        "online"
      ],
      cities: ["all"],
      isOnline: true
    },
    scoredItem: null,
    liked: null,
    wishlisted: false
  },
  {
    item: {
      id: "4811b79d-158f-41dc-8f1b-9bddbdf9c2b9",
      name: "Lightinthebox",
      description:
        "Global Online Shopping for Dresses, Home & Garden, Electronics, Wedding Apparel.",
      category: "retail stores",
      sponsored: false,
      transactionCount: 0,
      properties: {
        image:
          "https://media.go2speed.org/brand/files/dcm/1294/46958_05cc8b06d2aabed16e27fff499dbe6c74119ce9c_lightinthebox-com_m.png",
        externalId: "DC1408749273090",
        distance: 0.0,
        url: "https://www.lightinthebox.com/"
      },
      offers: [
        {
          id: "1294",
          name: "Lightinthebox",
          description:
            "This offer is eligible for first time users only.\nThis Offer is valid till 4/2/2039\nThis offer can be availed in  Fashion and Accessories, Games & Toys, Sports & Fitness, Electronics categories.\nThis offer can be redeemed only through the brand Website.\nUse None Coupon Code to avail the offer.",
          validFrom: "2020-12-08T00:00:00.389Z",
          validTo: "2039-04-02T23:59:59.389Z",
          partner: true,
          isOnline: true,
          properties: {
            longDescription: "",
            redemptionCode: "",
            image:
              "https://media.go2speed.org/brand/files/dcm/1294/46958_05cc8b06d2aabed16e27fff499dbe6c74119ce9c_lightinthebox-com_m.png",
            payoutType: "cpa_percentage",
            shortDescription: "",
            cashbackValue: "0.00%",
            offerStatus: "active",
            url: "",
            termsAndConditions: [
              "This Offer is valid till 4/2/2039\nThis offer is valid at None\nAccidental loss of coupon shall not be covered.\nOffer is applicable for selected purchases/categories.\nCoupons are not transferable.\nThis offer can be availed in  Fashion and Accessories, Games & Toys, Sports & Fitness, Electronics categories\nCoupons are only applicable on $NETWORK$ purchases.\nCoupons cannot be replaced if emails are deleted by the claimant.\nCustomer is redirected to third party website. Terms of third party website will be applicable on the shopping experience."
            ],
            offerType: "Coupon",
            offerSource: "DCM",
            howToRedeem:
              "Click on 'Claim' at the bottom of the Offer Page.  \nClick on 'Redeem Now' to get redirected to the merchant website. \nAt checkout on the merchant website, make sure to paste the coupon code. (Hint: The coupon code is automatically copied to your clipboard)",
            redemptionSteps: "",
            offerSourceImage: ""
          }
        }
      ],
      tags: [
        "computer-and-accessories",
        "large-product-range",
        "mobile-phones",
        "regular-retail-store",
        "bicycle-and-accessories",
        "basic-service",
        "apparel-and-accessories",
        "perfumery",
        "cosmetics-and-skincare",
        "sports-and-outdoors",
        "multi-brand",
        "toys",
        "books-and-music",
        "home-and-kitchen",
        "health-and-beauty",
        "accessories-store",
        "kitchen-accessories",
        "housewares",
        "musical-instrument",
        "sporting-equipment",
        "personal-care",
        "pet-and-pet-supplies",
        "kids-utilities",
        "apparel-and-accessories",
        "toys-and-games",
        "delivery-service",
        "pet-supplies",
        "online"
      ],
      cities: ["all"],
      isOnline: true
    },
    scoredItem: null,
    liked: null,
    wishlisted: false
  },
  {
    item: {
      id: "6fdeb72e-3306-4740-bae1-07280bb7e68f",
      name: "Combat Sports",
      description:
        "Gear for MMA, Muay Thai, Kickboxing, BJJ and other martial arts. All fighters welcome, from beginner to pro. The Original and Still the Best!",
      category: "retail stores",
      sponsored: false,
      transactionCount: 0,
      properties: {
        image:
          "https://media.go2speed.org/brand/files/dcm/873/Combat sports-logo.JPG",
        externalId: "DC1700807049218",
        distance: 0.0,
        url: "https://combatsportsme.com/"
      },
      offers: [
        {
          id: "873",
          name: "Combat Sports",
          description:
            "This offer is eligible for first time users only.\nThis Offer is valid till 5/15/2023\nThis offer can be availed in  Sports & Fitness categories.\nThis offer can be redeemed only through the brand Website.\nSuccessful and verified transaction will result in 5 AED cashback.",
          validFrom: "2020-12-08T00:00:00.389Z",
          validTo: "2023-05-15T23:59:59.389Z",
          partner: true,
          isOnline: true,
          properties: {
            longDescription: "",
            redemptionCode: "",
            image:
              "https://media.go2speed.org/brand/files/dcm/873/Combat sports-logo.JPG",
            payoutType: "cpa_flat",
            shortDescription: "",
            cashbackValue: "5 AED",
            offerStatus: "active",
            url: "",
            termsAndConditions: [
              "This Offer is valid till 5/15/2023\nThis offer is valid at None\nOffer is applicable for selected purchases/categories. This offer can be availed in  Sports & Fitness categories only.\nCustomer is redirected to third party website. Terms of third party website will be applicable on the shopping experience. \nCashback is subject to merchant approval, returns, refunds, bank approval etc.\nCashback is only applicable on the usage of $BANKNAME$ credit card and debit card purchases. \nApproved cashback will be credited within 90 days of successful purchase."
            ],
            offerType: "Cashback",
            offerSource: "DCM",
            howToRedeem:
              "Click on 'Claim' at the bottom of the Offer Page. \nClick on 'Redeem Now' to get redirected to the merchant website. \nAll you need to do is shop in the categories that the cashback is eligible for. \nAfter completing the transaction, monitor the status of your cashback in the Purchase History page in the Bazaar App.",
            redemptionSteps: "",
            offerSourceImage: ""
          }
        }
      ],
      tags: [
        "sports-and-outdoors",
        "large-product-range",
        "multi-brand",
        "online-and-in-store",
        "regular-retail-store",
        "basic-service",
        "apparel-and-accessories",
        "apparel-and-accessories",
        "delivery-service",
        "sporting-equipment"
      ],
      cities: ["all"],
      isOnline: true
    },
    scoredItem: null,
    liked: null,
    wishlisted: false
  },
  {
    item: {
      id: "766348d1-52bf-459b-8dac-36cbd8de298d",
      name: "Fordeal",
      description:
        "Incredible online shopping center. Free shipping! Big discounted! Cash on Delivery! 24 hours intimate customer service.",
      category: "retail stores",
      sponsored: false,
      transactionCount: 0,
      properties: {
        image:
          "https://media.go2speed.org/brand/files/dcm/1250/fordeal logo.jpg",
        externalId: "DC635655159808",
        distance: 0.0,
        url: "https://fo.app.link/IDkaV3yEW2"
      },
      offers: [
        {
          id: "1250",
          name: "Fordeal",
          description:
            "This offer is eligible for first time users only.\nThis Offer is valid till 12/26/2040\nThis offer can be availed in  Marketing categories.\nThis offer can be redeemed through the brand's mobile app as well as the website.\nUse None Coupon Code to avail the offer.",
          validFrom: "2020-12-08T00:00:00.389Z",
          validTo: "2040-12-26T23:59:59.389Z",
          partner: true,
          isOnline: true,
          properties: {
            longDescription: "",
            redemptionCode: "",
            image:
              "https://media.go2speed.org/brand/files/dcm/1250/fordeal logo.jpg",
            payoutType: "cpa_flat",
            shortDescription: "",
            cashbackValue: "",
            offerStatus: "active",
            url: "",
            termsAndConditions: [
              "This Offer is valid till 12/26/2040\nThis offer is valid at None\nAccidental loss of coupon shall not be covered.\nOffer is applicable for selected purchases/categories.\nCoupons are not transferable.\nThis offer can be availed in  Marketing categories\nCoupons are only applicable on $NETWORK$ purchases.\nCoupons cannot be replaced if emails are deleted by the claimant.\nCustomer is redirected to third party website. Terms of third party website will be applicable on the shopping experience."
            ],
            offerType: "Coupon",
            offerSource: "DCM",
            howToRedeem:
              "Click on 'Claim' at the bottom of the Offer Page.  \nClick on 'Redeem Now' to get redirected to the merchant website. \nAt checkout on the merchant website, make sure to paste the coupon code. (Hint: The coupon code is automatically copied to your clipboard)",
            redemptionSteps: "",
            offerSourceImage: ""
          }
        }
      ],
      tags: [
        "toys-and-games",
        "apparel-and-accessories",
        "mobile-phones",
        "footwear-store",
        "home-appliances",
        "travel-utilities",
        "multi-brand",
        "kitchen-appliances",
        "home-and-kitchen",
        "art-and-craft-supplies",
        "cosmetics-and-skincare",
        "perfumery",
        "luggage-store",
        "school-and-office-supplies",
        "personal-care",
        "computer-and-electronics",
        "delivery-service",
        "toys",
        "opticals",
        "other-products",
        "apparel-and-accessories",
        "home-audio-and-accessories",
        "regular-retail-store",
        "computer-and-accessories",
        "games",
        "stationery",
        "home-furnishings",
        "health-and-beauty",
        "automobile-and-accessories",
        "others",
        "kids-utilities",
        "online-and-in-store",
        "basic-service",
        "travel-accessories",
        "international"
      ],
      cities: ["all"],
      isOnline: true
    },
    scoredItem: null,
    liked: null,
    wishlisted: false
  },
  {
    item: {
      id: "7cb9df25-8c06-43e1-8107-3d17498b5030",
      name: "City Sightseeing Dubai",
      description:
        "Discover Dubai with City Sightseeing©. Book online tickets for our Hop-On Hop-Off bus tours, activities, attractions, cruises and make the most of your trip.",
      category: "travel",
      sponsored: false,
      transactionCount: 0,
      properties: {
        image:
          "https://media.go2speed.org/brand/files/dcm/907/BM_CitySightseeing_logo.png",
        externalId: "DC455266533376",
        distance: 0.0,
        url: "https://www.citysightseeing-dubai.com/ae/english"
      },
      offers: [
        {
          id: "907",
          name: "City Sightseeing Dubai",
          description:
            "This offer is eligible for first time users only.\nThis Offer is valid till 6/4/2023\nThis offer can be availed in  Travel,  Online Services, Recreation & Leisure categories.\nThis offer can be redeemed only through the brand Website.\nSuccessful and verified transaction will result in 0.015 cashback.",
          validFrom: "2020-12-08T00:00:00.389Z",
          validTo: "2023-06-04T23:59:59.389Z",
          partner: true,
          isOnline: true,
          properties: {
            longDescription: "",
            redemptionCode: "",
            image:
              "https://media.go2speed.org/brand/files/dcm/907/BM_CitySightseeing_logo.png",
            payoutType: "cpa_percentage",
            shortDescription: "",
            cashbackValue: "1.50%",
            offerStatus: "active",
            url: "",
            termsAndConditions: [
              "This Offer is valid till 6/4/2023\nThis offer is valid at None\nOffer is applicable for selected purchases/categories. This offer can be availed in  Travel,  Online Services, Recreation & Leisure categories only.\nCustomer is redirected to third party website. Terms of third party website will be applicable on the shopping experience. \nCashback is subject to merchant approval, returns, refunds, bank approval etc.\nCashback is only applicable on the usage of $BANKNAME$ credit card and debit card purchases. \nApproved cashback will be credited within 90 days of successful purchase."
            ],
            offerType: "Cashback",
            offerSource: "DCM",
            howToRedeem:
              "Click on 'Claim' at the bottom of the Offer Page. \nClick on 'Redeem Now' to get redirected to the merchant website. \nAll you need to do is shop in the categories that the cashback is eligible for. \nAfter completing the transaction, monitor the status of your cashback in the Purchase History page in the Bazaar App.",
            redemptionSteps: "",
            offerSourceImage: ""
          }
        }
      ],
      tags: [
        "tour-operator",
        "regional",
        "travel-agencies-and-operators",
        "online"
      ],
      cities: ["all"],
      isOnline: true
    },
    scoredItem: null,
    liked: null,
    wishlisted: false
  },
  {
    item: {
      id: "9a584605-b92b-4716-96f1-475ebd237d63",
      name: "Marhaba Services",
      description:
        "Marhaba provides a meet & greet service and airport lounges for arrivals, departures and transfers at Dubai, Al Maktoum and Bahrain International airports.",
      category: "travel",
      sponsored: false,
      transactionCount: 0,
      properties: {
        image:
          "https://media.go2speed.org/brand/files/dcm/925/marhaba-logo.png",
        externalId: "DC1185410973697",
        distance: 0.0,
        url: "https://www.marhabaservices.com/ae/english"
      },
      offers: [
        {
          id: "925",
          name: "Marhaba Services",
          description:
            "This offer is eligible for first time users only.\nThis Offer is valid till 6/4/2023\nThis offer can be availed in  Travel,  Online Services categories.\nThis offer can be redeemed only through the brand Website.\nSuccessful and verified transaction will result in 0.02 cashback.",
          validFrom: "2020-12-08T00:00:00.389Z",
          validTo: "2023-06-04T23:59:59.389Z",
          partner: true,
          isOnline: true,
          properties: {
            longDescription: "",
            redemptionCode: "",
            image:
              "https://media.go2speed.org/brand/files/dcm/925/marhaba-logo.png",
            payoutType: "cpa_percentage",
            shortDescription: "",
            cashbackValue: "2.00%",
            offerStatus: "active",
            url: "",
            termsAndConditions: [
              "This Offer is valid till 6/4/2023\nThis offer is valid at None\nOffer is applicable for selected purchases/categories. This offer can be availed in  Travel,  Online Services categories only.\nCustomer is redirected to third party website. Terms of third party website will be applicable on the shopping experience. \nCashback is subject to merchant approval, returns, refunds, bank approval etc.\nCashback is only applicable on the usage of $BANKNAME$ credit card and debit card purchases. \nApproved cashback will be credited within 90 days of successful purchase."
            ],
            offerType: "Cashback",
            offerSource: "DCM",
            howToRedeem:
              "Click on 'Claim' at the bottom of the Offer Page. \nClick on 'Redeem Now' to get redirected to the merchant website. \nAll you need to do is shop in the categories that the cashback is eligible for. \nAfter completing the transaction, monitor the status of your cashback in the Purchase History page in the Bazaar App.",
            redemptionSteps: "",
            offerSourceImage: ""
          }
        }
      ],
      tags: [
        "online-and-in-store",
        "international",
        "travel-agency",
        "travel-agencies-and-operators"
      ],
      cities: ["all"],
      isOnline: true
    },
    scoredItem: null,
    liked: null,
    wishlisted: false
  },
  {
    item: {
      id: "b424e49e-6bdf-4fb1-b3dd-2842bb42e258",
      name: "Get Your Guide",
      description:
        "Find, compare, and book sightseeing tours, attractions, excursions, things to do and fun activities from around the world.",
      category: "travel",
      sponsored: false,
      transactionCount: 0,
      properties: {
        image:
          "https://media.go2speed.org/brand/files/arabclicks/290/adv_getyourguide_icon.jpg",
        externalId: "AC618475290624",
        distance: 0.0,
        url: "https://www.getyourguide.com"
      },
      offers: [
        {
          id: "290",
          name: "get your guide",
          description:
            "This Offer is valid till 7/7/2023\nThis offer can be availed in  Travel categories.\nThis offer can be redeemed through the brand's mobile app as well as the website.\nSuccessful and verified transaction will result in 4.00% cashback.",
          validFrom: "2020-12-08T00:00:00.389Z",
          validTo: "2023-07-07T23:59:59.389Z",
          partner: true,
          isOnline: true,
          properties: {
            longDescription: "",
            redemptionCode: "",
            image:
              "https://media.go2speed.org/brand/files/arabclicks/290/adv_getyourguide_icon.jpg",
            payoutType: "",
            shortDescription: "",
            cashbackValue: "4.00%",
            offerStatus: "active",
            url: "https://go.arabclicks.com/aff_c?offer_id=290&aff_id=68962",
            termsAndConditions: [
              "This Offer is valid till 7/7/2023\nThis offer is valid at https://go.arabclicks.com/aff_c?offer_id=290&aff_id=68962\nOffer is applicable for selected purchases/categories. This offer can be availed in  Travel categories only.\nCustomer is redirected to third party website. Terms of third party website will be applicable on the shopping experience. \nCashback is subject to merchant approval, returns, refunds, bank approval etc.\nCashback is only applicable on the usage of $BANKNAME$ credit card and debit card purchases. \nApproved cashback will be credited within 90 days of successful purchase."
            ],
            offerType: "Cashback",
            offerSource: "arabclicks",
            howToRedeem:
              "Click on 'Claim' at the bottom of the Offer Page. \nClick on 'Redeem Now' to get redirected to the merchant website. \nAll you need to do is shop in the categories that the cashback is eligible for. \nAfter completing the transaction, monitor the status of your cashback in the Purchase History page in the Bazaar App.",
            redemptionSteps: "",
            offerSourceImage: ""
          }
        }
      ],
      tags: [
        "international",
        "travel-agencies-and-operators",
        "tour-operator",
        "air-ticket-booking",
        "online"
      ],
      cities: ["all"],
      isOnline: true
    },
    scoredItem: null,
    liked: null,
    wishlisted: false
  },
  {
    item: {
      id: "baf51dca-f177-41f5-8c96-ac9c304c9bb2",
      name: "Travelstart",
      description:
        "Find Cheap Travelstart Flights Online. Best Airfares & Taxes Included, Book Now! Compare All Airlines In One Search.",
      category: "travel",
      sponsored: false,
      transactionCount: 0,
      properties: {
        image:
          "https://media.go2speed.org/brand/files/arabclicks/522/adv_travelstart_icon.jpg",
        externalId: "AC1039382085632",
        distance: 0.0,
        url: "http://www.travelstart.com.eg/"
      },
      offers: [
        {
          id: "1001",
          name: "Travelstart",
          description:
            "This offer is eligible for first time users only.\nThis Offer is valid till 12/31/2039\nThis offer can be availed in  Travel, Recreation & Leisure categories.\nThis offer can be redeemed only through the brand Website.\nSuccessful and verified transaction will result in 75 AED cashback.",
          validFrom: "2020-12-08T00:00:00.389Z",
          validTo: "2039-12-31T23:59:59.389Z",
          partner: true,
          isOnline: true,
          properties: {
            longDescription: "",
            redemptionCode: "",
            image:
              "https://media.go2speed.org/brand/files/dcm/1001/TravelStart_Egypt_735065.jpg",
            payoutType: "cpa_flat",
            shortDescription: "",
            cashbackValue: "75 AED",
            offerStatus: "active",
            url: "",
            termsAndConditions: [
              "This Offer is valid till 12/31/2039\nThis offer is valid at None\nOffer is applicable for selected purchases/categories. This offer can be availed in  Travel, Recreation & Leisure categories only.\nCustomer is redirected to third party website. Terms of third party website will be applicable on the shopping experience. \nCashback is subject to merchant approval, returns, refunds, bank approval etc.\nCashback is only applicable on the usage of $BANKNAME$ credit card and debit card purchases. \nApproved cashback will be credited within 90 days of successful purchase."
            ],
            offerType: "Cashback",
            offerSource: "DCM",
            howToRedeem:
              "Click on 'Claim' at the bottom of the Offer Page. \nClick on 'Redeem Now' to get redirected to the merchant website. \nAll you need to do is shop in the categories that the cashback is eligible for. \nAfter completing the transaction, monitor the status of your cashback in the Purchase History page in the Bazaar App.",
            redemptionSteps: "",
            offerSourceImage: ""
          }
        },
        {
          id: "522",
          name: "travelstart",
          description:
            "This Offer is valid till 1/12/2023\nThis offer can be availed in  Travel categories.\nThis offer can be redeemed only through the brand Website.\nSuccessful and verified transaction will result in 21 AED cashback.",
          validFrom: "2020-12-08T00:00:00.389Z",
          validTo: "2023-01-12T23:59:59.389Z",
          partner: true,
          isOnline: true,
          properties: {
            longDescription: "",
            redemptionCode: "",
            image:
              "https://media.go2speed.org/brand/files/arabclicks/522/adv_travelstart_icon.jpg",
            payoutType: "",
            shortDescription: "",
            cashbackValue: "21 AED",
            offerStatus: "active",
            url: "https://go.arabclicks.com/aff_c?offer_id=522&aff_id=68962",
            termsAndConditions: [
              "This Offer is valid till 1/12/2023\nThis offer is valid at https://go.arabclicks.com/aff_c?offer_id=522&aff_id=68962\nOffer is applicable for selected purchases/categories. This offer can be availed in  Travel categories only.\nCustomer is redirected to third party website. Terms of third party website will be applicable on the shopping experience. \nCashback is subject to merchant approval, returns, refunds, bank approval etc.\nCashback is only applicable on the usage of $BANKNAME$ credit card and debit card purchases. \nApproved cashback will be credited within 90 days of successful purchase."
            ],
            offerType: "Cashback",
            offerSource: "arabclicks",
            howToRedeem:
              "Click on 'Claim' at the bottom of the Offer Page. \nClick on 'Redeem Now' to get redirected to the merchant website. \nAll you need to do is shop in the categories that the cashback is eligible for. \nAfter completing the transaction, monitor the status of your cashback in the Purchase History page in the Bazaar App.",
            redemptionSteps: "",
            offerSourceImage: ""
          }
        }
      ],
      tags: [
        "international",
        "car-rental",
        "travel-agency",
        "hotel-booking",
        "travel-agencies-and-operators",
        "air-ticket-booking",
        "online"
      ],
      cities: ["all"],
      isOnline: true
    },
    scoredItem: null,
    liked: null,
    wishlisted: false
  }
];
