/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

import React from "react";
import {createRoot} from "react-dom/client";
//redux
import Routes from "./routes";
// //css
import "./assets/css/bootstrap.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/css/font-awesome.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//scss
import "./assets/scss/index.scss";

const root = createRoot(document.getElementById("root"));

root.render(<div id="main" className="mainapp"><Routes /></div>)

if(localStorage.getItem("language") && localStorage.getItem("language") == "AR"){
  document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
} else {
  localStorage.setItem("language","EN");
}
