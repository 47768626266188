import ContentLoader from "react-content-loader";

const ListLoader = (props) => (
    <ContentLoader 
        speed={1}
        width="100%"
        height={1230}
        backgroundColor="#F1F1F3"
        foregroundColor="#F9F9FA"
        rtl={props.rtl}
    >
        <rect x="0" y="48" rx="8" ry="8" width="270" height="180" />
        <rect x="0" y="240" rx="4" ry="4" width="90" height="12" />
        <rect x="0" y="265" rx="4" ry="4" width="80" height="22" />
        <rect x="0" y="295" rx="4" ry="4" width="235" height="14" />
        <rect x="0" y="315" rx="4" ry="4" width="90" height="14" />
        <rect x="0" y="345" rx="4" ry="4" width="95" height="12" />
        <rect x="300" y="48" rx="8" ry="8" width="270" height="180" />
        <rect x="300" y="240" rx="4" ry="4" width="90" height="12" />
        <rect x="300" y="265" rx="4" ry="4" width="80" height="22" />
        <rect x="300" y="295" rx="4" ry="4" width="235" height="14" />
        <rect x="300" y="315" rx="4" ry="4" width="90" height="14" />
        <rect x="300" y="345" rx="4" ry="4" width="95" height="12" />
        <rect x="584" y="48" rx="8" ry="8" width="270" height="180" />
        <rect x="597" y="240" rx="4" ry="4" width="90" height="12" />
        <rect x="597" y="265" rx="4" ry="4" width="80" height="22" />
        <rect x="597" y="295" rx="4" ry="4" width="235" height="14" />
        <rect x="597" y="315" rx="4" ry="4" width="90" height="14" />
        <rect x="597" y="345" rx="4" ry="4" width="95" height="12" />
        <rect x="864" y="48" rx="8" ry="8" width="270" height="180" />
        <rect x="877" y="240" rx="4" ry="4" width="90" height="12" />
        <rect x="877" y="265" rx="4" ry="4" width="80" height="22" />
        <rect x="877" y="295" rx="4" ry="4" width="235" height="14" />
        <rect x="877" y="315" rx="4" ry="4" width="90" height="14" />
        <rect x="877" y="345" rx="4" ry="4" width="95" height="12" />
        <rect x="1144" y="48" rx="8" ry="8" width="270" height="180" />
        <rect x="1158" y="240" rx="4" ry="4" width="90" height="12" />
        <rect x="1158" y="265" rx="4" ry="4" width="80" height="22" />
        <rect x="1158" y="295" rx="4" ry="4" width="235" height="14" />
        <rect x="1158" y="315" rx="4" ry="4" width="90" height="14" />
        <rect x="1158" y="345" rx="4" ry="4" width="95" height="12" />

        
        <rect x="0" y="458" rx="8" ry="8" width="270" height="180" />
        <rect x="0" y="650" rx="4" ry="4" width="90" height="12" />
        <rect x="0" y="675" rx="4" ry="4" width="80" height="22" />
        <rect x="0" y="705" rx="4" ry="4" width="235" height="14" />
        <rect x="0" y="725" rx="4" ry="4" width="90" height="14" />
        <rect x="0" y="755" rx="4" ry="4" width="95" height="12" />
        <rect x="300" y="458" rx="8" ry="8" width="270" height="180" />
        <rect x="300" y="650" rx="4" ry="4" width="90" height="12" />
        <rect x="300" y="675" rx="4" ry="4" width="80" height="22" />
        <rect x="300" y="705" rx="4" ry="4" width="235" height="14" />
        <rect x="300" y="725" rx="4" ry="4" width="90" height="14" />
        <rect x="300" y="755" rx="4" ry="4" width="95" height="12" />
        <rect x="584" y="458" rx="8" ry="8" width="270" height="180" />
        <rect x="597" y="650" rx="4" ry="4" width="90" height="12" />
        <rect x="597" y="675" rx="4" ry="4" width="80" height="22" />
        <rect x="597" y="705" rx="4" ry="4" width="235" height="14" />
        <rect x="597" y="725" rx="4" ry="4" width="90" height="14" />
        <rect x="597" y="755" rx="4" ry="4" width="95" height="12" />
        <rect x="864" y="458" rx="8" ry="8" width="270" height="180" />
        <rect x="877" y="650" rx="4" ry="4" width="90" height="12" />
        <rect x="877" y="675" rx="4" ry="4" width="80" height="22" />
        <rect x="877" y="705" rx="4" ry="4" width="235" height="14" />
        <rect x="877" y="725" rx="4" ry="4" width="90" height="14" />
        <rect x="877" y="755" rx="4" ry="4" width="95" height="12" />
        <rect x="1144" y="458" rx="8" ry="8" width="270" height="180" />
        <rect x="1158" y="650" rx="4" ry="4" width="90" height="12" />
        <rect x="1158" y="675" rx="4" ry="4" width="80" height="22" />
        <rect x="1158" y="705" rx="4" ry="4" width="235" height="14" />
        <rect x="1158" y="725" rx="4" ry="4" width="90" height="14" />
        <rect x="1158" y="755" rx="4" ry="4" width="95" height="12" />
{/* 
        
        <rect x="0" y="858" rx="8" ry="8" width="270" height="180" />
        <rect x="0" y="1050" rx="4" ry="4" width="90" height="12" />
        <rect x="0" y="1075" rx="4" ry="4" width="80" height="22" />
        <rect x="0" y="1105" rx="4" ry="4" width="235" height="14" />
        <rect x="0" y="1125" rx="4" ry="4" width="90" height="14" />
        <rect x="0" y="1155" rx="4" ry="4" width="95" height="12" />
        <rect x="300" y="858" rx="8" ry="8" width="270" height="180" />
        <rect x="300" y="1050" rx="4" ry="4" width="90" height="12" />
        <rect x="300" y="1075" rx="4" ry="4" width="80" height="22" />
        <rect x="300" y="1105" rx="4" ry="4" width="235" height="14" />
        <rect x="300" y="1125" rx="4" ry="4" width="90" height="14" />
        <rect x="300" y="1155" rx="4" ry="4" width="95" height="12" />
        <rect x="584" y="858" rx="8" ry="8" width="270" height="180" />
        <rect x="597" y="1050" rx="4" ry="4" width="90" height="12" />
        <rect x="597" y="1075" rx="4" ry="4" width="80" height="22" />
        <rect x="597" y="1105" rx="4" ry="4" width="235" height="14" />
        <rect x="597" y="1125" rx="4" ry="4" width="90" height="14" />
        <rect x="597" y="1155" rx="4" ry="4" width="95" height="12" />
        <rect x="864" y="858" rx="8" ry="8" width="270" height="180" />
        <rect x="877" y="1050" rx="4" ry="4" width="90" height="12" />
        <rect x="877" y="1075" rx="4" ry="4" width="80" height="22" />
        <rect x="877" y="1105" rx="4" ry="4" width="235" height="14" />
        <rect x="877" y="1125" rx="4" ry="4" width="90" height="14" />
        <rect x="877" y="1155" rx="4" ry="4" width="95" height="12" />
        <rect x="1144" y="858" rx="8" ry="8" width="270" height="180" />
        <rect x="1158" y="1050" rx="4" ry="4" width="90" height="12" />
        <rect x="1158" y="1075" rx="4" ry="4" width="80" height="22" />
        <rect x="1158" y="1105" rx="4" ry="4" width="235" height="14" />
        <rect x="1158" y="1125" rx="4" ry="4" width="90" height="14" />
        <rect x="1158" y="1155" rx="4" ry="4" width="95" height="12" /> */}
    </ContentLoader>
);

export default ListLoader;