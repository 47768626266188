/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Swithc Component
import React from "react";
import uiConfig from "../../uiConfig";

const ToggleSwitch = ({
  keyId,
  disabled,
  isSelected = false,
  handleToggle
}) => {
  const language = localStorage.getItem("language");
  return (
    <>
      <input
        checked={isSelected}
        onChange={(e) => handleToggle(e)}
        className="react-switch-checkbox"
        disabled={disabled}
        type="checkbox"
        id={keyId}
      />
      <label
        className={["react-switch-label", isSelected ? "active" : "", language === 'AR' ? 'arabic-dirc-label':''].join(" ")}
        htmlFor={keyId}
      //  style={isSelected ?uiConfig.config.whitelabel.common_background_color:""}
      >
        <span
          className={["react-switch-button", isSelected ? "active" : "", language === 'AR' ? 'arabic-dirc-span':''].join(
            " "
          )}
        ></span>
      </label>
    </>
  );
};

export default ToggleSwitch;
