/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// SearchBar Component
import React, { PureComponent } from "react";
import Autosuggest from "react-autosuggest";
import { arabicContent } from "../../constants";
import {
  getTypeHead,
  searchKeywordFunc,
  getError
} from "../../service";
import disableScroll from "disable-scroll";
import input_search from "../../assets/img/input_search.png";

import { tagOptions } from "../helper/options";
import "./style.scss";

export default class SearchBar extends PureComponent {
  state = {
    language: localStorage.getItem("language"),
    value: "",
    country: JSON.parse(localStorage.getItem("selectCountry")) || {"label":"Dubai","value":"Dubai"},
    suggestionSelected: false,
    typeAhead: [],
    suggestions: []
  };

  componentDidMount() {
    this.setState({ language: localStorage.getItem("language") });
    this.textInput = React.createRef();
  }

  onFocus = (e) => {
    e.stopPropagation();
    if (document.getElementById("header-content") !== null) {
      var headerWidth = document.getElementById("header-content").offsetWidth;
      var searchwidth = headerWidth / 1.2;
      document.getElementById("searchDiv").style.width = searchwidth + "px";
      document.documentElement.style.overflowY = "hidden";
      document.body.style.overflowY = "hidden";
    } else {
      document.documentElement.style.overflowY = "hidden";
      document.body.style.overflowY = "hidden";
    }
    localStorage.removeItem("selectedFilters");
  };

  onClickRecentSearch = (suggestion) => {
    const { history, selectedCity } = this.props;

    this.setState({ value: "", suggestions: [] });
    disableScroll.off();
    this.props._onClickSearch();
    history.push("/home/search", {
      searchKeyword: suggestion,
      selectedCity: selectedCity,
      clicked: Number(localStorage.getItem('keywordsearchcount')) + 1
    });
    localStorage.setItem('keywordsearchcount', Number(localStorage.getItem('keywordsearchcount')) + 1);
    document.documentElement.style.overflowY = "auto";
    document.body.style.overflowY = "auto";
  };

  onClickSearchText = (v) => {
    const { history, selectedCity } = this.props;
    this.props._onClickSearch();
    history.push("/home/search", {
      searchKeyword: v,
      selectedCity: selectedCity
    });
    this.setState({ suggestionSelected: true });
  };

  getSuggestionValue = (suggestion) => suggestion.name;

  onSuggestionsFetchRequest = () => { };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  oneSuggestionSelected = async (event, { suggestion }) => {
    if (suggestion.type == "item") {
      const { history, emptyTypeAhead } = this.props;
      if (event && event.keyCode !== 13) {
        this.setState({
          value: "",
          suggestionSelected: true,
          suggestions: []
        });
        disableScroll.off();
        if (history) {
          this.onSuggestionsClearRequested();
          await this.props.setActiveMenu("");
          emptyTypeAhead && emptyTypeAhead();
          this.props._onClickSearch();
          history.push(`/home/merchant/${suggestion.id}`);
        }
      }
      document.documentElement.style.overflowY = "auto";
      document.body.style.overflowY = "auto";
    } else {
      this.setState({ suggestionSelected: true });
      this.onClickRecentSearch(suggestion.name);
    }
    this.props.getRecentSearchData();
    localStorage.removeItem("selectedFilters");
  };

  onSuggestionsFetchRequested = (value) => {
    const { country } = this.state;
    if (value.length >= 1) {
      if (this.getTypeHeadItem) {
        this.getTypeHeadItem(country, value);
      }
    }
  };

  getSuggestions = (value) => {
    if (value.length >= 2) {
      const { typeAhead } = this.state;
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;
      return inputLength === 0 ? [] : typeAhead;
    } else {
      return [];
    }
  };

  getTypeHeadItem = (city, query) => {
    var activeMenu = "all";
    if (
      this.props.activeMenu == "home" ||
      this.props.activeMenu == "allOffers" ||
      this.props.activeMenu == ""
    ) {
      activeMenu = "all";
    } else {
      activeMenu = this.state.activeMenu;
    }
    getTypeHead(activeMenu, city, query)
      .then((res) => {
        if (res && res.length > 0 && !this.state.suggestionSelected) {
          this.setState({ typeAhead: res }, () => {
            this.setState({ suggestions: this.getSuggestions(query) });
          });
        }
      })
      .catch((err) => alert(getError(err)));
  };

  onlySpaces(str) {
    return str.trim().length === 0;
  }

  _onkeyDownContent = (ev) => {
    const { history } = this.props;
    const { emptyTypeAhead, selectedCity } = this.props;
    if (ev && ev.keyCode === 13 && ev.target.value.trim()) {
      this.setState({
        value: "",
        suggestionSelected: true,
        suggestions: []
      });
      this.props._onClickSearch();
      history.push("/home/search", {
        searchKeyword: ev.target.value,
        selectedCity: selectedCity,
        clicked: Number(localStorage.getItem('keywordsearchcount')) + 1
      });
      localStorage.setItem('keywordsearchcount', Number(localStorage.getItem('keywordsearchcount')) + 1);
      document.documentElement.style.overflowY = "auto";
      document.body.style.overflowY = "auto";
      this.props.getRecentSearchData();
    } else if (ev && ev.keyCode === 9) {
      emptyTypeAhead && emptyTypeAhead();
      this.setState({ value: "", suggestions: [] });
      document.documentElement.style.overflowY = "auto";
      document.body.style.overflowY = "auto";
    } else if (ev && ev.keyCode === 8 && ev.target.value.length <= 1) {
      emptyTypeAhead && emptyTypeAhead();
      this.setState({ value: "", suggestions: [] });
    }
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      suggestionSelected: false,
    });
    this.onSuggestionsFetchRequested(newValue);
  };

  /* Main Render Functions */
  render() {
    const {
      searchValue,
      searchCountry,
      reacentSearch,
      popularSearch
    } = this.props;

    const { language, value, suggestions, suggestionSelected } = this.state;

    const inputProps = {
      placeholder: language === "AR" ? `ما الذي تبحث عنه؟ جرّب "مقهى" أو "متاجر البيع بالتجزئة"` : 'Try “Cafe” or “Retail stores”',
      value: value,
      onChange: this.onChange,
      onFocus: this.onFocus,
      onKeyDown: this._onkeyDownContent,
      autoFocus: this.textInput,
      ref: this.textInput,
    };

    return (
      <div className="search-bar-divv">
        <div className="top-part">
          <h1 className="margin-0 main-title" >
            {searchCountry === "near me" ? "  Let’s find you the perfect offers & deals in Near me" : <></>}
            {searchCountry !== "near me" &&
              <>
                {language === "AR" ? " لنجد لك أفضل العروض في " : "Let’s find you the perfect offers & deals in "}
                <span className="location">{searchCountry}</span> </>}
          </h1>
          <span className="close-icon">
            {" "}
            <span
              className="bg-close_icon_hed_mobile"
            // onClick={() => {
            //   handleIconClick("search");
            // }}
            ></span>
          </span>
        </div>
        <div className="search-container">
          <div className={`${language === "AR" ? "arCustm" : "enCustm"} auto-suggest-wrap`}>
            <div className={`${language === "AR" ? "arIcon" : "enIcon"} icon-wrap`}>
              <span className="bg-search_icon_auto">
                <img
                  src={input_search}
                  alt="search"
                />
              </span>
            </div>
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequest}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              onSuggestionSelected={this.oneSuggestionSelected}
              getSuggestionValue={this.getSuggestionValue}
              renderSuggestion={this.getSuggestionValue}
              inputProps={inputProps}
              focusInputOnSuggestionClick={false}
              alwaysRenderSuggestions={true}
            />
            {/* {value && value.length > 1 && (
              <div
                className={
                  language === "AR"
                    ? "search-text-ar cursor"
                    : "search-text cursor"
                }
                onClick={() => this.onClickSearchText(value)}
              >
                <span>{language=== "AR"? "البحث":"Search"}</span>
              </div>
            )} */}
          </div>
        </div>
        {value.length < 2 &&
          reacentSearch &&
          reacentSearch?.length > 0 &&
          (window.innerWidth > 768 || value.length < 2) && (
            <div className="tag-options recent">
              <h4 className={`${language === "AR" && "arPop"} recent-title`}>{language === "AR" ? "عمليات البحث الأخيرة" : "Recent Searches"}</h4>
              <div className={`${language === "AR" && "ar"} recent-list`}>
                {reacentSearch.map((tagOption, idx) => (
                  <div
                    key={`${idx + 1}`}
                    onClick={() =>
                      this.onClickRecentSearch(tagOption.searchText)
                    }
                  >
                    {tagOption.searchText}
                  </div>
                ))}
              </div>
            </div>
          )}
        {value.length < 2 && popularSearch && popularSearch?.length > 0 && (
          <div
            className={
              window.innerWidth > 768 || value.length < 2
                ? "tag-options sec"
                : "tag-options"
            }
          >
            <h4 className={`${language === "AR" && "arPop"} recent-title`}>{language === "AR" ? "عمليات بحث شائعة" : "Popular Searches"}</h4>
            <div className="flex-item flex">
              {popularSearch &&
                popularSearch.length > 0 &&
                popularSearch.map((tagOption) => (
                  <div
                    key={`${tagOption}`}
                    className={`${language === "AR" && "arCont"} card-cont search-card mbl flex-center cursor`}
                    onClick={() => this.onClickRecentSearch(tagOption)}
                  >
                    {tagOption}
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}