/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Heading Component
import React, { Component } from "react";
import CheeseburgerMenu from "cheeseburger-menu";
import Autosuggest from "react-autosuggest";
import disableScroll from 'disable-scroll';

// Import Component
import Image from "../Image";
import Select from "../Select";
import Icon from "../Icons";
import NavBar from "../Navbar";
import Profile from "../Profile";
import SearchBar from "../SearchBar";
import { deepEqual } from "../../pages/helper";
// Helper
import { languageOption } from "../helper/options";

import MayaLogo from "../../assets/img/logo.png";
import AdibLogo from "../../assets/img/adib_logo.png";
import HdfcLogo from "../../assets/img/HDFC_logo.png";
import { accessTokenHelper } from "../../helper/token";
import { getCampaigns, getError, getTypeHead, searchKeywordFunc } from "../../service";
import history from "../../routes/history"
import { useHistory, withRouter } from "react-router";
import uiConfig from "../../uiConfig";
import LocationImage from "../../assets/img/location.png";
import location_img from "../../assets/img/locationSlider.png"
import Icons from "../Icons";
import recent_location from "../../assets/img/recent_location.png";
import active_recent_location  from "../../assets/img/active_recent_location.png";
import select_recent_location  from "../../assets/img/select_recent_location.png"
import recomend_location from "../../assets/img/recomend_location.png"
import SearchIcon from "../../assets/img/searchIcon.png";
import heartIcon from "../../assets/img/heartIcon.png";
import {arabicContent, arabicHeaderMenu, tokenHeader} from "../../constants/index"
import config from "../../service/config";
import HeaderMobile from "../HeaderMobile";
import { randomTokenGen } from "../../service";
import {getRecentCity} from '../../service';


const authed = () => sessionStorage.getItem("USERID");

const imgPath="/images/"+uiConfig.config.whitelabel.clientName+"_logo.png"
const imgHeight = uiConfig.config.whitelabel.logo.height;
const imgWidth = uiConfig.config.whitelabel.logo.width;
var logoMatch = {
  maya: MayaLogo,
  adib: AdibLogo,
  hdfc: HdfcLogo
}

//Import Config
var MatomoTracker = require('matomo-tracker');
var matomo = new MatomoTracker(config.siteId, config.siteURL);

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryValue: localStorage.getItem("language") && localStorage.getItem("language") == "EN" ? "english" : "arabic" ,
      country: "",
      searchCountry: "",
      selectedCity: null,
      searchValue: "",
      invisible: false,
      width: 0,
      menuOpen: false,
      value: "",
      suggestions: [],
      countryListUpdated: [],
      typeAhead:[],
      suggestionSelected:false,
      notificationOpen: false,
      isCountryModalOpen:false,
      filteredCountryList:this.props.countryList,
      showGps:true,
      openModal:false,
      userAuthed: false,
      recentLocation:[],
      language: localStorage.getItem("language") || "EN"
    }

    this.watchID = null;
    this.textInput = React.createRef();
  }

  async componentDidMount() {
    this.setState({language: localStorage.getItem("language")||"EN"});
    this.updateWindowDimensions();
      window.addEventListener("resize", this.updateWindowDimensions);
    if(this.props.countryList&&this.props.countryList.length>0){
      this.setState({
        filteredCountryList:this.props.countryList
      })
      var countries = this.props.countryList;
      var newCountryList = [];
      countries.map((country, index) => {
        if(!country.custom){
          newCountryList.push(country);
        }
      })
      var newcountry2 = [];
      newcountry2.push({custom: true });
      newCountryList.map((country2, index) => {
          newcountry2.push(country2);
      })
      newcountry2.push({label: "Near me", value: this.state.language === 'EN' ? "Near me" : "بالقرب مني"});
      //countries.push({custom: true });
      const  countryList  = newcountry2;
      this.setState({countryListUpdated: newcountry2});

    //  const selOption=this.state.language==="EN"?localStorage.getItem('selectCountry'):localStorage.getItem('selectCountryArabic')
      const selOption=localStorage.getItem('selectCountry');
      const firstItem = selOption ? selOption : countryList[1];
      const mapValue = selOption ? JSON.parse(firstItem) : countryList[1];
      if(mapValue){
        this.setState({
          country: mapValue.value,
          selectedCity: mapValue,
          searchCountry: mapValue.label
        });
      }
      

      this.watchID = navigator.geolocation.watchPosition((position) => {
        localStorage.setItem(
          "currentLocation",
          JSON.stringify({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          })
        );
      });
    }
    this.updateAuth();
  }

  componentDidUpdate(prevProps) {
    var countries = this.props.countryList;
    if(this.props.countryList&&this.props.countryList.length>0){
    var newCountryList = [];
    if(countries) {
      countries.map((country, index) => {
        if(!country.custom){
          newCountryList.push(country);
        }
      })
      var newcountry2 = [];
      newcountry2.push({custom: true });
      newCountryList.map((country2, index) => {
          newcountry2.push(country2);
      })
      newcountry2.push({label: "Near me", value: this.state.language === 'EN' ? "Near me" : "بالقرب مني"});
      //countries.push({custom: true });
      const  countryList  = newcountry2;

      // let enLoc = localStorage.getItem('selectCountry');
      // let res = countryList.filter(obj => {
      //   if(obj.geoCode){
      //     return Object.values(obj.geoCode)[0] === String(JSON.parse(enLoc)?.geoCode?.latitude);
      //   }
      // }) || null;

    //  const selOption = this.state.language==="EN"?localStorage.getItem('selectCountry'):localStorage.getItem('selectCountryArabic');
      const selOption =localStorage.getItem('selectCountry');
      const firstItem = selOption ? selOption : countryList[1];
      const mapValue = selOption ? JSON.parse(firstItem) : countryList[1];
      if (!deepEqual(prevProps, this.props)&&this.state.country.length===0) {
        if(mapValue){
          this.setState({
            country: mapValue.value ,
            selectedCity: mapValue,
            searchCountry: mapValue.label,
            countryListUpdated: newcountry2
          });
        }
      }
      this.watchID = navigator.geolocation.watchPosition((position) => {
        localStorage.setItem(
          "currentLocation",
          JSON.stringify({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          })
        );
      });
    }}
  }
  
  updateAuth = () => {
    if(authed()){
      this.setState({openModal:false,userAuthed:true});
    }
    else{
      this.setState({openModal:true,userAuthed:false});
    }
  }
  
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  /* Onclick Function */
  _onChangeSelect = (opt) => {
    localStorage.setItem("language",opt.value === "english" ? "EN" : "AR");
    this.setState({
      countryValue: opt ? opt.value : ""
    });
    matomo.track({
      url: window.location.href,
      e_c: localStorage.getItem("TOKEN"),
      e_a: 'CHANGE_LANGUAGE',
      e_n: opt.value === "english" ? "EN" : "AR",
    });
  
  //   window.location.href = '/home';
    setTimeout(()=>{
      this.props.history.go(0);
    },100)
  };

  _openLogin = () => {
      this.setState({openModal:true})
  }

  _closeLogin = (type) => {
    if(type === "submit"){
      history.push('/home');
    }
    this.setState({openModal:false})
  }
  showLocation = () =>{
    navigator.permissions.query({ name: 'geolocation' })
    .then((result) => {
      if(result.state === "denied" || result.state === "prompt") {
          alert("Geolocation is not enabled. Please enable to use this feature");
      } else {
        this.setState({ country: "Near me" })
        const { getHomeList, history } = this.props;
        var browserLocation = localStorage.getItem("currentLocation");
        var latlong = JSON.parse(browserLocation);
        var opt = {"label":"Near me","value":"Near me","geoCode":{"latitude":latlong?.latitude,"longitude":latlong?.longitude}};
        this.setState({
          country: opt ? opt.value : "",
          searchCountry: opt ? opt.label : "",
          selectedCity: opt,
          filteredCountryList:this.props.countryList
        });
        if(this.state.language==="EN") {
          localStorage.setItem('selectCountry',JSON.stringify({"label":"Near me","value":"Near me","geoCode":{"latitude":latlong?.latitude,"longitude":latlong?.longitude}}));
        } else {
          localStorage.setItem('selectCountry',JSON.stringify({"label":arabicContent.nearMe,"value":arabicContent.nearMe,"geoCode":{"latitude":latlong?.latitude,"longitude":latlong?.longitude}}));
        }

        if (getHomeList) {
          getHomeList(opt);
          getCampaigns(opt)
        }
        history.push(`/home`);       
        this.props.setActiveMenu(sessionStorage.getItem("actMenu") || "home")
      }
    });
  }

  getRecentLocation(){
    getRecentCity()
      .then(data => {
        if(data.length > 0){
          this.setState({recentLocation:data});
        }
      })
  }

  _onChangeCountrySelect = async (opt) => {
    const { getHomeList, history } = this.props;

    if(opt&&opt.label){
      this.setState({
        country: opt ? opt.value : "",
        searchCountry: opt ? opt.label : "",
        selectedCity: opt,
        filteredCountryList:this.props.countryList
      });
    // if(this.state.language==="EN") {
      localStorage.setItem('selectCountry',JSON.stringify(opt));
    //  } else {
    //   localStorage.setItem('selectCountryArabic',JSON.stringify(opt));
    //  }
        this.closeCountryModal()
  
        if (getHomeList) {
          getHomeList(opt);
          getCampaigns()
        }
        if(history){
          history.push(`/home`);
          this.props.setActiveMenu(sessionStorage.getItem("actMenu") || "home")
        }
      } else {
        this.closeCountryModal()
        if(navigator.geolocation) {
          this.showLocation();
        }
      }
  };

  /* _handleSelect = (opt) => {
    const { history } = this.props;
    this.setState({ searchValue: opt ? opt.value : "" });
    history.push("/home/search", opt.value);
  };

  filterOptions = (inputValue) => {
    return searchOptions.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  _loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(this.filterOptions(inputValue));
    }, 1000);
  };  */

  _onClickItem = () => {
    this.setState({ invisible: true });
    // disableScroll.on();
  };

  _onRemoveItem = () => {
    this.setState({ invisible: false, value: "" });
    disableScroll.off();
    document.documentElement.style.overflowY='auto'
    document.body.style.overflowY = 'auto';
  };

  _notificationPopup = () => {
    this.setState({ notificationOpen: !this.state.notificationOpen});
  };

  _onkeyDown = (ev) => {
    if (ev && ev.keyCode === 27) {
      this._onRemoveItem();
    }
  };

  closeMenu = () => {
    this.setState({ menuOpen: false });
  };

  onOpenMenu = () => {
    this.setState({ menuOpen: true });
  };

  _onClickProfile = () => {
    const { history } = this.props;
    history.push(`/home/profile`, "myProfile");
  };

  _onClickClaimed = () => {
    const { history } = this.props;
    history.push(`/home/profile`, "claimed");
  };

  _onClickRedemption = () => {
    const { history } = this.props;
    history.push("/home/profile", "redemption");
  };

  _onClickHome = () => {
    const { history } = this.props;
    this.props.setActiveMenu("home")
    this.props.onChangeTab("home")
    let userId = localStorage.getItem(tokenHeader.TOKEN)
    if(userId){
      history.push({pathname:"/home",userId:userId})
    }
    else{
      history.push(`/home`, "home");
    }
  };

  _onClickTasteProfile = () => {
    const { history } = this.props;
    history.push("/home/profile", "tasteProfile");
  };

  _onClickProfileItem = (activeItem) => {
    const { history } = this.props;
    sessionStorage.setItem("currPage",activeItem)
    this.props.setActiveItem(activeItem)
    history.push("/home/profile", activeItem);
    this.setState({ menuOpen: false });
  };

  onLogout = () => {
    const { history } = this.props;
    localStorage.clear();
    sessionStorage.clear();
    accessTokenHelper.del();
    history.push("/login");
  };

  onLogoutHDFC = () => {
    const { history } = this.props;
    sessionStorage.removeItem('USERID');
    this.updateAuth();
    history.push("/home");
    this.props.setActiveItem('home');
    this.setState({ menuOpen: false });
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      suggestionSelected:false
    });
    this.onSuggestionsFetchRequested(newValue)
  };

  onFocus = () => {
    if(document.getElementById('header-content') !== null){
    var headerWidth = document.getElementById("header-content").offsetWidth;
    var searchwidth = headerWidth/1.2;
    document.getElementById("searchDiv").style.width = searchwidth+"px";
    this.setState({ invisible: true });
    // disableScroll.on();
    document.documentElement.style.overflowY='hidden'
    document.body.style.overflowY = 'hidden';
    } else {
      document.documentElement.style.overflowY='hidden'
      document.body.style.overflowY = 'hidden';
    }
  };

  //When click outside the suggestion section will hide
  onCloseSearchBar = () =>{
    this.setState({ invisible: false,value: "" });
    disableScroll.off();
    document.documentElement.style.overflowY='auto'
    document.body.style.overflowY = 'auto';
  }

  //Language Change - Mobile View
  onLanguageChange =()=>{
    let lang = this.state.language === 'EN' ? 'AR' : this.state.language === 'AR' ? "EN" :''
    localStorage.setItem("language",lang);
    this.setState({
      countryValue: lang === 'EN' ? 'english' : lang === 'AR' ? 'arbic':''
    });
    matomo.track({
      url: window.location.href,
      e_c: localStorage.getItem("TOKEN"),
      e_a: 'CHANGE_LANGUAGE',
      e_n: lang,
    });
  //  window.location.href = '/home';
    setTimeout(()=>{
      this.props.history.go(0);
    },100)
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = (value) => {
    if(value.length>=2) {
      const { typeAhead } = this.state;
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;
      return inputLength === 0
        ? []
        : typeAhead
    } else {
      return []
    }
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue = (suggestion) => suggestion.name;

  // Use your imagination to render suggestions.
  renderSuggestion = (suggestion) => <div>{suggestion.name}</div>;

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ( value ) => {
    const { country } = this.state;
    if(value.length>=1) { 
      if (this.getTypeHeadItem) {
        this.getTypeHeadItem(country, value);
      }
    }
  };

  openCountry=(isCountryModalOpen)=>{
    this.getRecentLocation();
    this.onCloseSearchBar()
    const minWidth = 933;
    this.setState({isCountryModalOpen,filteredCountryList:this.props.countryList},()=>{
      document.documentElement.style.overflowY='hidden'
      document.body.style.overflowY = 'hidden';
      if(minWidth <= this.state.width) {
        document.body.style.padding='0px'
      }
    })
  }

  getTypeHeadItem = (city, query) => {
    var activeMenu = "all";
  
    if(this.props.activeMenu == "home" || this.props.activeMenu == "allOffers" || this.props.activeMenu == "") {
      activeMenu = "all";
    } else {
      activeMenu = this.state.activeMenu;
    }
    getTypeHead(activeMenu, city, query)
      .then((res) => {
        if (res && res.response && res.response && !this.state.suggestionSelected) {
          this.setState({ typeAhead: res.response },()=>{
            this.setState({ suggestions:this.getSuggestions(query) })
          });
        }
      })
      .catch((err) => alert(getError(err)));
  };

  onSuggestionsFetchRequest=()=>{
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  oneSuggestionSelected = async(event, { suggestion }) => {
    if(suggestion.type == 'item'){
      const { history } = this.props;
      const { emptyTypeAhead } = this.props;
      if (event && event.keyCode !== 13) {
        this.setState({ invisible: false, value: "" ,suggestionSelected:true });
        disableScroll.off();
        if (history) {
          this.onSuggestionsClearRequested()
          await this.props.setActiveMenu("");
          emptyTypeAhead&&emptyTypeAhead();
          history.push(`/home/merchant/${suggestion.id}`);
          this.textInput.current.blur();
        }
      }
      document.documentElement.style.overflowY = 'auto'
      document.body.style.overflowY = 'auto';
    } else {
      this.onClickRecentSearch(suggestion.name);
    }
    this.props.getRecentSearchData()
  };
  
  onClickRecentSearch = (suggestion) => {
    const { history } = this.props;

    this.setState({ invisible: false, value: "" });
    disableScroll.off();
    history.push("/home/search", {
      searchKeyword: suggestion,
      selectedCity: this.state.selectedCity
    });
    this.textInput.current.blur();
    document.documentElement.style.overflowY = 'auto'
    document.body.style.overflowY = 'auto';
  };

  _onkeyDownContent = (ev) => {
    const { history } = this.props;
    const { emptyTypeAhead } = this.props;
    if (ev && ev.keyCode === 13) {
      this.setState({ invisible: false, value: "",suggestionSelected:true });
    //  disableScroll.off();
      history.push("/home/search", {
        searchKeyword: ev.target.value,
        selectedCity: this.state.selectedCity,
      });
      this.textInput.current.blur();
      document.documentElement.style.overflowY = 'auto'
      document.body.style.overflowY = 'auto';
      this.props.getRecentSearchData();
    } else if(ev&&ev.keyCode===8&&ev.target.value.length<=1) {
      emptyTypeAhead&&emptyTypeAhead();
      this.setState({ invisible: true, value: "",suggestions:[] });
    }  
  };

  _renderCountryData=(title)=>{
    const {filteredCountryList,language,recentLocation} = this.state;

    if(title === "recent locations"){
      return(
        <>
      <div className="recent-location">
        <div className="gps-title">{title}</div>
      {recentLocation.map((country,index)=>{

        return( country &&
        <div className="box">
         {index === 0 ?
         <>
         <div><img src={active_recent_location} width={18} height={18} /> </div>
         <div className="recent-gps-text" onClick={()=>this._onChangeCountrySelect(country)}>
           <div className="sub-text" style={{color: "#005AB7"}}>{country?.label}</div>
         <div className="support-text active">{country?.countryLabel}</div>
         </div>
         <img src={select_recent_location} width={16} height={12}/>
         </> :
         <><div><img src={recent_location} width={18} height={18} /> </div>
        <div className="recent-gps-text" onClick={()=>this._onChangeCountrySelect(country)}>
          <div className="sub-text">{country?.label}</div>
        <div className="support-text" >{country?.countryLabel}</div>
        </div></>
        } 
        </div>) })}
      </div>
      </>
      )
    }
    else{
    return(
      <>
      <div className="recent-location">
        <div className="gps-title">{title}</div>

      {filteredCountryList.map(country=><div className="box">
        <div>
          {title==="recent locations"?<img src={recent_location} width={18} height={18} />
          :<img src={recomend_location} width={18} height={18} />} </div>
        <div className="recent-gps-text" onClick={()=>this._onChangeCountrySelect(country)}>
          <div className="sub-text">{country.label}</div>
        <div className="support-text">{country.countryLabel}</div></div>
        </div> )}
      </div>
      </>
    )}
  }

  closeCountryModal=()=>{
    this.setState({
      isCountryModalOpen:false,
      filteredCountryList:this.props.countryList,
      showGps:true
    },()=>{ 
      document.documentElement.style.overflowY='unset';
      document.body.style.overflowY = 'unset';
      document.body.style.paddingRight = '0px';
    })
  }

  searchCountry=(val)=>{
    const {countryList}=this.props;
    //  if(val.length>3)
    // {
    let filteredCountryList= countryList.filter(data =>
      data.value?.toLowerCase().includes(val.toLowerCase()) || data.countryLabel?.toLowerCase().includes(val.toLowerCase())
    );

    this.setState({
      filteredCountryList
    })
    if(val.length===0){
      this.setState({showGps:true})
    } else {
      this.setState({showGps:false})
    }
    // }
    // else{
    //   this.setState({
    //     filteredCountryList:[]
    //   })
    // }
  }

  _renderCountryModal() {
    const minWidth = 933;
    const { settings,showGps,language} = this.state;
  
    // Removed notification/settings Icon for mobile view
    if (minWidth >= this.state.width) {
      return (
        <div className={`col-md-12 p-0 not-loc-modal`}>
        <div className={`col-md-4 p-0 main ${language==="AR"&&"main-arabic"}`}>
          <div className="not-cont col-md-12 p-0">
            <div className="col-md-12 header p-0 flex-start">
              <div
                className="cancel-icn flex-center cursor"
                onClick={this.closeCountryModal}
              >
                <Icon fill="#000" width={15} height={15} name="cancel" />
              </div>
            </div>
            <div className="search-location">
              {/* <input type="text" placeholder={language==="EN"?"Search for area, street name..":arabicContent.searchLoc} onChange={(e)=>this.searchCountry(e.target.value)}/> */}
              <input type="text" placeholder={language === "EN" ? "Search for city" : arabicContent.searchCity} onChange={(e) => this.searchCountry(e.target.value)} />
            </div>

            {showGps&&<div className="gps">
              <div className="box">
              <div><Icons name="location" width={18} height={18} /></div>
              <div className="gps-text" onClick={()=>this._onChangeCountrySelect()}>
                <div className="sub-text">{language==="EN"?"Get current location":arabicContent.getCurrentLoc}</div>
              <div className="support-text">{language==="EN"?"USING GPS":arabicContent.gps}</div></div>
              </div> 
            </div>}

            { this._renderCountryData("recent locations") }
            {this._renderCountryData(language==="EN"?"Recommended Locations":arabicContent.recommendLoc)}
          </div>
        </div>
      </div>
      );
    }

    return (
      <div className="col-md-12 p-0 not-loc-modal">
        <div className={`col-md-4 p-0 main ${language==="AR"&&"main-arabic"}`}>
          <div className="not-cont col-md-12 p-0">
            <div className="col-md-12 header p-0 flex-start">
              <div
                className="cancel-icn flex-center cursor"
                onClick={()=>this.closeCountryModal()}
              >
                <Icon fill="#000" width={15} height={15} name="cancel" />
              </div>
            </div>
            <div className="search-location">
              {/* <input type="text" placeholder={language==="EN"?"Search for area, street name..":arabicContent.searchLoc} onChange={(e)=>this.searchCountry(e.target.value)}/> */}
              <input type="text" placeholder={language === "EN" ? "Search for city" : arabicContent.searchCity} onChange={(e) => this.searchCountry(e.target.value)} />
            </div>
    
            {showGps&& <div className="gps">
              <div className="box">
              <div><img src={location_img} width={18} height={18} /></div>
              <div className="gps-text" onClick={()=>this._onChangeCountrySelect()}>
              <div className="sub-text">{language==="EN"?"Get current location":arabicContent.getCurrentLoc}</div>
                <div className="support-text">{language==="EN"?"USING GPS":arabicContent.gps}</div></div>
              </div> 
            </div>}

          {this._renderCountryData("recent locations") }
          {this._renderCountryData(language==="EN"?"Recommended Locations":arabicContent.recommendLoc)}
          </div>
        </div>
      </div>
    );
  }

  /* Other Render Functions */
  _renderMenuItems(title, onClick) {
    return (
      <div className="col-md-12 menu-item-mobile cursor p-0" onClick={onClick}>
        <h5 className="profile-info mb-16 mt-0">{title}</h5>
      </div>
    );
  }

  _renderNormalView() {
    const { countryValue, country, invisible, value, suggestions,isCountryModalOpen, language } = this.state;
    const {
      onChangeTab,
      activeMenu,
      history,
      countryList,
      categoryList
    } = this.props;
    const minWidth = 933;
  //  console.log("country",country)
    const inputProps = {
      placeholder: language === "EN" ? "Try “Cafe” or “Retail stores”" : arabicContent.trycafe,
      value,
      onChange: this.onChange,
      onBlur:this.onBlur,
      onFocus: this.onFocus,
      onKeyDown: this._onkeyDownContent,
      ref: this.textInput,
      style: {
        backgroundColor: uiConfig.config.whitelabel.header.bg
      }
    };
    let categoriesList=categoryList.length>0?categoryList:JSON.parse(sessionStorage.getItem('catList'));
    if (minWidth < this.state.width) {
      return (
        <div className="col-md-12 p-0 header">
          <div className="col-md-12 flex p-0 header-content" id="header-content" style={{ backgroundColor: uiConfig.config.whitelabel.header.bg }}>
            <div
              className="flex cursor"
              onClick={this._onClickHome}
            >
              <img src={logoMatch[uiConfig.config.whitelabel.clientName]} className={config.clientName==="HDFC"?`bazaar-header-logo-hdfc`:`bazaar-header-logo${language == "AR" ? "mr-0":""}`} height="42"/>
            </div>
            <div className="country-select p-0" onClick={()=>this.openCountry(true)}>
            <div className="flex">
            <div className={language == "EN" ? "flex-center" : "flex-center arabic-location-icon"}>
              <Image src={LocationImage} alt="image" w={15} h={20}/>
            </div>
            <div className="location-text">
              {country}
            </div>
        </div>
           
              {/* <Select
                isCountrySelect
                value={country}
                options={this.state.countryListUpdated}
                onChange={this._onChangeCountrySelect}
              /> */}
        </div>

            <div
              className={`${
                invisible
                  ? "half-div"
                  : "half-div searchDiv"
              } p-0`}
              id="searchDiv"
              style={{}}
            >
              <div className={language == "EN" ? "search-icon" : "search-icon arabic-search"} onClick={this._onClickItem}>
                {/* <i className="fa fa-search"></i> */}
                <img src={SearchIcon} width="16"/>
              </div>
              <div className={`${invisible ? "col-md-10" : "col-md-11"} p-0`}>
                {suggestions.length !== 0  &&invisible && <div className="opacity-div" />}
                {/* <Select
                  isAutoComplete
                  cacheOptions
                  placeholder="Try “Cafe” or “Retail stores”"
                  defaultOptions
                  options={searchOptions}
                  loadOptions={this._loadOptions}
                  value={searchValue}
                  onChange={this._handleSelect}
                /> */}
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequest}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                  onSuggestionSelected={this.oneSuggestionSelected}
                  getSuggestionValue={this.getSuggestionValue}
                  renderSuggestion={this.getSuggestionValue}
                  inputProps={inputProps}
                  focusInputOnSuggestionClick={false}
                />
              </div>
              {invisible && (
                <div
                  className="flex-center search-icon search-close-button"
                  onClick={this._onRemoveItem}
                >
                  <Icon name="cancel" fill="#000" width={16} height={16} />
                </div>
              )}
            </div>
              <div className={`${
                invisible ? "none" : language == "EN" ? "headerRightDiv" : "headerLeftDiv"
              }`}>
                <div
                  className={`${
                    invisible ? "none" : ""
                  } cursor hide-item p-0 flex-center wishlistedDiv`}
                  onClick={() => {this.props.setActiveMenu(" ");history.push("/home/wishlisted")}}
                >
                  <img src={heartIcon} width="20"/>
                </div>
                { config.clientName !== "HDFC" &&
                <div
                  className={`${invisible ? "none" : ""} ${language == "EN" ? "p-0 hide-item mobile-country-select headerCountry" : "p-0 hide-item mobile-country-select headerLeftCountry arabic-align"} `}
                >
                  <Select
                    isCustomSelect
                    value={countryValue}
                    options={languageOption}
                    onChange={this._onChangeSelect}
                  />
                </div>
                }
                  <div
                    className={`${
                      invisible ? "none-profile" : ""
                    } p-0 hide-item mobile-profile-dropdown`}
                  >
                  {config.clientName === "HDFC"?
                  <Profile
                      openLogin={this._openLogin}
                      userAuthed={this.state.userAuthed}
                      updateAuth={this.updateAuth}
                      userData={this.props.userData}
                      history={history}
                      updatePersonaldetails={this.props.updatePersonaldetails}
                      notificationItems={this.props.notificationItems}
                      saveDetailsLoader={this.saveDetailsLoader}
                      setActiveItem={this.props.setActiveItem}
                      notificationPopup={this._notificationPopup}
                    />: <Profile
                    userData={this.props.userData}
                    history={history}
                    updatePersonaldetails={this.props.updatePersonaldetails}
                    notificationItems={this.props.notificationItems}
                    saveDetailsLoader={this.saveDetailsLoader}
                    setActiveItem={this.props.setActiveItem}
                    notificationPopup={this._notificationPopup}
                  />}
                  </div>
            </div>
          </div>               
          <NavBar
            onChangeTab={onChangeTab}
            activeMenu={activeMenu}
            categoryList={categoriesList}
          />
          {isCountryModalOpen&&this._renderCountryModal()}
        </div>
      );
    }

    return null;
  }

  _renderMobileView() {
    const { suggestions, country, invisible, value, notificationOpen,isCountryModalOpen,language,countryValue } = this.state;
    const {
      onChangeTab,
      activeMenu,
      history,
      countryList,
      categoryList
    } = this.props;

    const inputProps = {
      placeholder: language === "EN" ? "Try “Cafe” or “Retail stores”" : arabicContent.trycafe,
      value,
      onChange: this.onChange,
      onFocus: this.onFocus,
      onKeyDown: this._onkeyDownContent,
      ref: this.textInput
    };
 
    const minWidth = 933;
    const mobileWidth=480
    let categoriesList=categoryList.length>0?categoryList:JSON.parse(sessionStorage.getItem('catList'));
    if (minWidth >= this.state.width) {
      return (
        <div className="mt-10 header">
          <div className="header-mobile-view">
          <div style={{width: "100%", justifyContent: "space-between", display: "flex"}}>

           <div style={{display: "flex",alignItems:"center"}}>
            <div
              className={`${invisible && language === 'AR' ? "hide-image hide-arabic" : invisible ? "hide-image" : ""} image-item mr-10`}
              onClick={this._onClickHome}
            >
              <img
                src={logoMatch[uiConfig.config.whitelabel.clientName]}
                alt={uiConfig.config.whitelabel.clientName}
                className="mobile-logo-header"
              />
            </div>
            <div className="mr-15">
              <div className="select-wrap-filter">
                {/* <Select
                  isCountrySelectForMobile
                  value={country}
                  options={countryList}
                  onChange={this._onChangeCountrySelect}
                /> */}
              <div className="flex" onClick={()=>this.openCountry(true)}>
              <div className="flex-center ml-10">
                <Image src={LocationImage} alt="image" w={15} h={20} />
              </div>
              <div className="location-text">
                {country}
              </div>
          </div>
        </div>
      </div>
      </div>          
            <div style={{display: "flex"}}>
            <div className={`flex ${invisible ? "search-contain" : ""}  mr-10`}>
            <div  className={`${invisible ? "show-search-icon-mobile" : ""} search-icon`} onClick={this._onClickItem}>
                <i className="fa fa-search"></i>
              </div>
              <div className={`${invisible ? "show-search-mobile" : "hide-search-mobile"
                } ${language === 'AR' ? 'arabic-search' :''} hide-item search-content`}>
                {/* <Select
                  isAutoComplete
                  cacheOptions
                  placeholder="Try “Cafe” or “Retail stores”"
                  defaultOptions
                  options={searchOptions}
                  loadOptions={this._loadOptions}
                  value={searchValue}
                  onChange={this._handleSelect}
                /> */}
                {suggestions.length !== 0 && invisible&&<div className="opacity-div" />}
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                  onSuggestionSelected={this.oneSuggestionSelected}
                  getSuggestionValue={this.getSuggestionValue}
                  renderSuggestion={this.getSuggestionValue}
                  inputProps={inputProps}
                />
              </div>
              {invisible && (
                <div
                  className=" flex-center cursor icon-remove"
                  onClick={this._onRemoveItem}
                >
                  <Icon name="cancel" fill="#000" width={12} height={12} />
                </div>
              )}
            </div>
            <div
              className={`${
                invisible ? "none" : ""
              } cursor hide-item p-0 flex-center mr-10`}
              onClick={() =>{this.props.setActiveMenu(" ");history.push("/home/wishlisted")}}
            >
              <Icon name="group" width={21} height={18} />
            </div>
            {/* <div
                    className={`${
                      invisible ? "none" : ""
                    } cursor hide-item p-0 flex-center mr-10`}
                    onClick={this._notificationPopup}
                  >
                    <Icon name="notification" width={21} height={18} />
                  </div> */}

                  {/* <Profile

                      notificationOpen={notificationOpen}
                      notificationItems={this.props.notificationItems}
                      notificationPopup={this._notificationPopup}
                  />   */}
                    
            <CheeseburgerMenu
              isOpen={this.state.menuOpen}
              closeCallback={this.closeMenu}
              right={language === 'EN'}
            >
            {/* {config.isLoginEnabled==="true"&&this._renderMenuItems(language === 'EN' ? "Account Details": arabicHeaderMenu.accountDetails, () =>
                      this._onClickProfileItem("myProfile")
                    )}
                    {config.isLoginEnabled==="true"&&this._renderMenuItems(language === 'EN' ? "My Taste Profile" :arabicHeaderMenu.myTasteProfile, () =>
                      this._onClickProfileItem("tasteProfile")
                    )} */}
                    {!this.state.userAuthed && this._renderMenuItems("Login", 
                      () =>{
                              this.updateAuth();                      
                              this.setState({ menuOpen: false });
                           }
                    )}
                    {this._renderMenuItems(language === 'EN' ? "Claimed Offers" :arabicHeaderMenu.claimedOffers, () =>
                      this._onClickProfileItem("claimed")
                    )}
                    {/* {this._renderMenuItems(language === 'EN' ? "Redemptions" :arabicHeaderMenu.redemptions, () =>
                      this._onClickProfileItem("redemption")
                    )} */}
                    {this._renderMenuItems(language === 'EN' ? "App Feedback" :arabicHeaderMenu.appFeedback, () =>
                      this._onClickProfileItem("appFeedback")
                    )}
                    {/* {this._renderMenuItems(language === 'EN' ? "Customer Support":arabicHeaderMenu.customerSupport, () =>
                      this._onClickProfileItem("customerSupport")
                    )}
                    {this._renderMenuItems(language === 'EN' ? "Notification Settings" :arabicHeaderMenu.notificationSettings, () =>
                      this._onClickProfileItem("notification")
                    )} */}
                    {this._renderMenuItems(language === 'EN' ? "FAQs" :arabicHeaderMenu.faq, () =>
                      this._onClickProfileItem("faq")
                    )}
                    {this._renderMenuItems(language === 'EN' ? "Privacy Policy" :arabicHeaderMenu.privacyPolicy, () =>
                      this._onClickProfileItem("privacy")
                    )}
                    {this._renderMenuItems(language === 'EN' ? "Terms & Conditions" :arabicHeaderMenu.termsAndConditions, () =>
                      this._onClickProfileItem("termsAndCondition")
                    )}
              <div className="col-md-12 line" />
              <div className="col-md-12 p-0">
              {config.clientName !=="HDFC" && <h5 className="profile-info mt-16 mb-16 pr-1 pl-10 language-setting">
                    {language === 'EN' ? 'Language Settings' :'اعدادات اللغة'}
                    <ul>
                      <li className="cursor" style={{color:`${language === 'EN' ? uiConfig.config.whitelabel.brandColor:""}`}} onClick={this.onLanguageChange}>English</li>
                      <li className="cursor lang-direction-ltr" style={{color:`${language === 'AR' ? uiConfig.config.whitelabel.brandColor:""}`}} onClick={this.onLanguageChange}>Arabic (أرابيكاربيك)</li>
                    </ul>
              </h5>}
              </div>
              <div className="col-md-12 line" />
              {config.isLoginEnabled==="true"&&<div
                className="col-md-12 p-0 ml-10 cursor flex"
                onClick={this.onLogout}
              >
                <div className="col-md-6 p-0 flex-start">
                  <h5 className="profile-info red-text logout mb-0 mt-16 pr-1">
                    Logout
                  </h5>
                </div>
                <div className="col-md-6 text-right p-0 flex-end">
                  <h5 className="profile-info logout mb-0 mt-16">
                    <Icon
                      fill="#FF0000"
                      width={10}
                      height={10}
                      name="arrowRight"
                    />
                  </h5>
                </div>
              </div>}
              {config.clientName === "HDFC"&& this.state.userAuthed && <div
                className="col-md-12 p-0 ml-10 cursor flex"
                onClick={this.onLogoutHDFC}
              >
                <div className="col-md-6 p-0 flex-start">
                  <h5 className="profile-info logout mb-0 mt-16 pr-1" style={{color: uiConfig.config.whitelabel.brandColor}}>
                    Logout
                  </h5>
                </div>
                <div className="col-md-6 text-right p-0 flex-end">
                  <h5 className="profile-info logout mb-0 mt-16">
                    <Icon
                      fill={uiConfig.config.whitelabel.brandColor}
                      width={10}
                      height={10}
                      name="arrowRight"
                    />
                  </h5>
                </div>
              </div>}
            </CheeseburgerMenu>
            <div
              className={`${
                invisible ? "none" : ""
              } cursor hide-item flex-center pr-10`}
              onClick={this.onOpenMenu}
            >
              <Icon name="menu" width={18} height={18} />
            </div>
            </div>
          </div>
          </div>
          <NavBar
            onChangeTab={onChangeTab}
            activeMenu={activeMenu}
            categoryList={categoriesList}
          />
     {isCountryModalOpen&&this._renderCountryModal()}
        </div>
      );
    }
    
    return null;
  }

  /* Main Render Functions */
  render() {
    const { searchValue, searchCountry, suggestions } = this.state;
    const { reacentSearch } = this.props;

    return (
      <>        
        {this._renderNormalView()}
        {this._renderMobileView()}
        {this.state.invisible && suggestions.length === 0 && (
          <SearchBar
            searchValue={searchValue}
            reacentSearch={reacentSearch}
            searchCountry={searchCountry}
            onClickRecentSearch={this.onClickRecentSearch}
            onCloseSearchBar={this.onCloseSearchBar}
          />
        )}
      </>
    );
  }
}

export default withRouter(Header)
