/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Footer Component
import React, { PureComponent } from "react";

import Image from "../Image";
import Icons from "../Icons";

import GooglePlay from "../../assets/img/google_play.png";
import AppImage from "../../assets/img/app_store.png";
import contact from "../../assets/img/contact.png"
import { withRouter } from "react-router";
import moment from "moment";
import { arabicContent } from "../../constants/index";
import './style.scss'
import line_seperator from "../../assets/img/line_seperator.svg"
import chat_with_us from "../../assets/img/chat_with_us.png"
import adib_globe from "../../assets/img/adib_globe.png"

const lang = localStorage.getItem("language");

class Footer extends PureComponent {
  /* Other Render Functions */
  _renderTitle(title) {
    return <h5 className="ftr-sub">{title}</h5>;
  }

  routeToProfile = (activeItem) => {
    this.props.setActiveItem(activeItem);
    sessionStorage.setItem("currPage", activeItem);
    this.props.history.push("/home/profile", activeItem)
  }
  /* Main Render Functions */
  render() {
    return (
      <>
        {this.props.history.location.pathname !== "/login" &&
          <div className="col-md-12 footer" >
            <div className='footer-top' >
              {/* <span className='footer-top-content ml-15'>
                {lang === 'AR' ? arabicContent.aboutus : `About Us`}
              </span>
              <span><img src={line_seperator} /></span> */}
              <div>
                <span className='footer-top-content' onClick={() => { this.routeToProfile("faq") }}
                >
                  {lang === 'AR' ? arabicContent.faq : `FAQs`}
                </span>
                <span className="line-sep" ><img src={line_seperator} /></span>
                <span className='footer-top-content'
                  onClick={() => { this.routeToProfile("termsAndCondition") }}
                >
                  {lang === 'AR' ? arabicContent.termAndCond : `Terms & Conditions`}
                </span>
                <span className="line-sep" ><img src={line_seperator} /></span>
                <span className='footer-top-content'
                  onClick={() => { this.routeToProfile("privacy") }}
                >
                  {lang === 'AR' ? arabicContent.privPolicy : `Privacy Policy`}
                </span>
                <span className="line-sep" ><img src={line_seperator} /></span>
                {/* <span className='footer-top-content' onClick={() => { this.routeToProfile("customerSupport") }} > */}
                <span className='footer-top-content'>
                <a href="mailto:customerservice@adib.ae?subject=Smartdeals" className="aref" >{lang === 'AR' ? arabicContent.contactus+" " : `Contact us : `}<span style={{ textDecorationLine: 'underline' }} >customerservice@adib.ae</span></a>
                </span>
              </div>
              <div>
                <span >
                  <span><img src={adib_globe} /> </span>
                  <span className='footer-top-content cursor-default'>{ lang === 'AR' ? (` ADIB's  `)+arabicContent.internationalpresence : `ADIB's International Presence` }</span>
                </span>
              </div>
            </div>
            <div className='footer-inside' >
              <div className="footer-disc">{lang === 'AR' ? arabicContent.adbftrdisclaimer : `Disclaimer:  Smartdeals is a platform for communication of offers extended by Merchants to ADIB's Customers. ADIB is only communicating the offers extended by Merchants to its Customers and not selling/rendering any of these products/services. ADIB is merely facilitating the payment to its Customers by providing the Payment Gateway Services. ADIB is neither guaranteeing nor making any representation of the same. ADIB is not responsible for sales/quality/features of the products/services under the offers.`}</div>
              {/* <div className='site-content' >
                <span className="footer-disc" >
                  {lang === 'AR' ? arabicContent.ftrText2 : `Site is best viewed in Chrome V23+, Firefox V15+, Safari 6+ and IE10+ at 1024 x 768 pixels resolution.`}
                </span>
              </div> */}
              <div className="footer-line" />
              <div className='footer-bottom'>
                <span className='rights-reserved' >
                  <span>
                    <span>{lang === 'AR' ? '' : `©`}</span>
                    {/* <span> {moment().format("YYYY")} </span> */}
                    <span>{lang === 'AR' ? arabicContent.adbCopyright : ` Copyrights reserved ADIB. `}</span>
                    {lang === 'AR' ? arabicContent.allright : `All rights reserved`} ·
                  </span>
                  <span >
                    <span className="cursor" onClick={() => {
                      this.routeToProfile("privacy")
                    }}>   {lang === 'AR' ? arabicContent.privacy : `Privacy`} · </span>
                    <span className="cursor" onClick={() => {
                      this.routeToProfile("termsAndCondition")
                    }}>   {lang === 'AR' ? arabicContent.terms : `Terms`}</span>
                     {/* · </span>{lang === 'AR' ? arabicContent.sitemap : `Sitemap`} */}
                  </span>
                </span>
                <span className='maya-logo cursor' onClick={() => { window.open('https://maya.ai/') }} >
                  <Icons name="mayatextadib" width={160} height={''} />
                </span>
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}

export default withRouter(Footer)