/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

import { tokenHeader } from "../constants";
import Cookie from "../helper/cookie";
import config from "../service/config";

// Common Headers
const authHeader = Cookie.get(tokenHeader.TOKEN)

const headers = {
  "User-Access-Token": localStorage.getItem('TOKEN'),
  "Content-Type": "application/json",
  Authorization: config.apiKey
};

const headerwotoken = {
  'Content-Type': 'application/json',
  Authorization: config.apiKey
};

const authAPIHeader = {
  "User-Access-Token": localStorage.getItem('TOKEN'),
  "Content-Type": "application/json",
  api_key: config.authApiKey
}

export { headers, headerwotoken , authAPIHeader};