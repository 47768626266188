/**
 *
 * Disclaimer: Source code mentioned below is(are) Intellectual Property of
 * Crayon Data Holdings Limited (including its subsidiaries and affiliates).
 * Crayon Data Holdings Limited reserves right to own and control it the way
 * it may deem fit. You must refrain from use, access, read, modify, add or
 * delete, sell or use in any other package or programme pertaining to such
 * source code without explicit prior written approval of
 * Crayon Data Holding Limited. Breach of the same shall attract penalty as
 * applicable.
 *
 */

// SearchBar Component
import React, { PureComponent } from "react";
import Autosuggest from "react-autosuggest";

import { arabicContent } from "../../constants";
import { tagOptions } from "../helper/options";

import CloseIcon from "../../assets/img/close-icon.png";
import SearchIcon from "../../assets/img/search-gray.png";

export default class AdibSearchBar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      language: localStorage.getItem("language"),
      width: 0
    };
    this.searchWrapper = React.createRef();
  }

  componentDidMount() {
    this.setState({ language: localStorage.getItem("language") });
    document.addEventListener("mousedown", this.handleClickOutside);
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.searchWrapper.current.contains(event.target)) {
      return;
    }
    this.props.closeOutSide();
  };

  updateWindowDimensions = () => {
    this.setState({
      width: window.innerWidth
    });
  };

  //Show Search and Auto Suggestion component
  _renderSearchModal() {
    const { invisible, value, suggestions } = this.props;
    const { language } = this.state;
    const inputProps = {
      placeholder:
        language === "EN"
          ? "What are you looking for? Try “Cafe” or “Retail stores”"
          : arabicContent.trycafe2,
      value,
      onChange: this.props.onChange,
      onBlur: this.props.onBlur,
      onFocus: this.props.onFocus,
      onKeyDown: this.props._onkeyDownContent,
      autoFocus: this.props.refInput,
      ref: this.props.refInput
    };
    return (
      <div className="col-md-12 p-0">
        {suggestions.length !== 0 && invisible && (
          <div className="opacity-div" />
        )}
        <div className="auto-suggest-wrap">
          <div className="icon-wrap">
            <img src={SearchIcon} alt="icon" width="18" />
          </div>
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.props.onSuggestionsFetchRequest}
            onSuggestionsClearRequested={this.props.onSuggestionsClearRequested}
            onSuggestionSelected={this.props.oneSuggestionSelected}
            getSuggestionValue={this.props.getSuggestionValue}
            renderSuggestion={this.props.getSuggestionValue}
            inputProps={inputProps}
            focusInputOnSuggestionClick={false}
          />
          {value.length > 1 && (
            <div
              className={
                language === "AR"
                  ? "search-text-ar cursor"
                  : "search-text cursor"
              }
              onClick={() => this.props.onClickSearchText(value)}
            >
              <span>{language === "AR" ? arabicContent.search : "Search"}</span>
            </div>
          )}
        </div>
      </div>
    );
  }

  /* OnClick Function */
  _renderBaseSearchContainer() {
    const {
      searchValue,
      searchCountry,
      reacentSearch,
      onClickRecentSearch,
      value
    } = this.props;

    const { language, width } = this.state;
    const minWidth = 768;

    if (!searchValue) {
      return (
        <div
          className={
            language == "EN"
              ? "row ml-0 mr-0 base-container base-container-en"
              : "row ml-0 mr-0 base-container base-container-ar"
          }
          id="base-container"
          ref={this.searchWrapper}
        >
          <div className="header-search-box">
            <div
              className={
                language === "EN" ? "headerBoxLeft" : "headerBoxLeft_ar"
              }
            >
              <React.Fragment>
                {language == "EN" ? (
                  <div className="adib-search-header-mbl flex justify-between _ai-c">
                    <h1 className="margin-0 main-title">
                      Let’s find you the perfect offers & deals in{" "}
                      <span className="location">{searchCountry}</span>
                    </h1>
                    {width <= minWidth && (
                      <div
                        className="close-icon cursor mbl"
                        onClick={() => this.props.onCloseSearchBar()}
                      >
                        <img
                          src={CloseIcon}
                          alt="icon"
                          height={12}
                          width={12}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="adib-search-header-mbl">
                    <h1 className="margin-0 main-title">
                      {arabicContent.perfdeal} <span><u>{searchCountry}</u></span>
                    </h1>
                    {width <= minWidth && (
                      <span
                        className="close-icon cursor mbl"
                        onClick={() => this.props.onCloseSearchBar()}
                      >
                        <img
                          src={CloseIcon}
                          alt="icon"
                          height={12}
                          width={12}
                        />
                      </span>
                    )}
                  </div>
                )}
                <div className="search-container">
                  {this._renderSearchModal()}
                </div>
                {value.length < 2 && reacentSearch.length > 0 && (window.innerWidth > 768 || value.length < 2) && (
                  <div className="tag-options recent">
                    <h4 className="recent-title">
                      {language === "AR"
                        ? arabicContent.recentSearches
                        : "Recent Searches"}
                    </h4>
                    <div className="flex-item flex">
                      {reacentSearch.map((tagOption, idx) => (
                        <div
                          key={`${idx + 1}`}
                          className={
                            width <= minWidth
                              ? "card-cont mbl flex-center cursor"
                              : "card-cont flex-center cursor"
                          }
                          // style={{background:`${tagOption.color}`}}
                          onClick={() => onClickRecentSearch(tagOption.searchText)}
                        >
                          {tagOption.searchText}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {value.length < 2 && (
                  <div className={
                    reacentSearch.length > 0 && (window.innerWidth > 768 || value.length < 2)
                      ? "tag-options sec"
                      : "tag-options"
                  }>
                    <h4 className="recent-title">
                      {language === "AR"
                        ? arabicContent.popularSearches
                        : "Popular Searches"}
                    </h4>
                    <div className="flex-item flex">
                      {tagOptions.map((tagOption) => (
                        <div
                          key={`${tagOption.id}`}
                          className={
                            width <= minWidth
                              ? "card-cont mbl flex-center cursor"
                              : "card-cont flex-center cursor"
                          }
                          // style={{background:`${tagOption.color}`}}
                          onClick={() => onClickRecentSearch(tagOption.label)}
                        >
                          {language == "EN"
                            ? tagOption.label
                            : tagOption.arabicLabel}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </React.Fragment>
            </div>
            <div className="headerBoxRight">
              {width >= minWidth && (
                <div
                  className={
                    language === "EN"
                      ? "close-icon cursor text-right close-icon-flex"
                      : "close-icon cursor  close-icon-flex"
                  }
                  onClick={() => this.props.onCloseSearchBar()}
                >
                  <img src={CloseIcon} alt="icon" />
                </div>
              )}

              {/* {reacentSearch.length > 0 && (window.innerWidth > 768 || value.length < 2) && (
                <div className="recent-search-wrap">
                  {language == "EN" ? (
                    <h4 className="recent-title">Recent Searches</h4>
                  ) : (
                    <h4 className="recent-title">
                      {arabicContent.recentSearches}
                    </h4>
                  )}
                  {reacentSearch.length > 0 &&
                    reacentSearch.map((tagOption, idx) => (
                      <div
                        key={`${idx + 1}`}
                        className="recent-search cursor"
                        onClick={() =>
                          onClickRecentSearch(tagOption.searchText)
                        }
                      >
                        {tagOption.searchText}
                      </div>
                    ))}
                </div>
              )} */}
            </div>
          </div>
        </div>
      );
    }
    return null;
  }

  /* Main Render Functions */
  render() {
    // onClick={()=>this.props.onCloseSearchBar()}
    return (
      <div className="col-md-12 p-0 search-bar-div adib-search-bar">
        <div className="col-md-12 p-0 search-content">
          {this._renderBaseSearchContainer()}
        </div>
      </div>
    );
  }
}