/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Router Config
import React, { lazy } from "react";

const Home = lazy(() => import("../pages/Home"));
const MainPage = lazy(() => import("../pages/MainPage"));
const LoginPage = lazy(() => import("../pages/LoginPage"));
const MerchantDetails = lazy(() => import("../pages/MerchantDetails"));
const WishListed = lazy(() => import("../pages/WishListed"));
const ProfilePage = lazy(() => import("../pages/Profile"));
const SearchPage = lazy(() => import("../pages/SearchPage"));

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    name: "login",
    path: "/login",
    component: LoginPage,
    exact: true,
    authentication: false
  },
  {
    name: "main",
    path: "/",
    component: MainPage,
    exact: true,
    authentication: true,
  },
  {
    name: "home",
    path: "/home",
    component: Home,
    exact: true,
    authentication: true
  },
  {
    name: "merchantDetails",
    path: "/home/merchant/:id/:userId?",
    component: MerchantDetails,
    exact: true,
    authentication: true
  },
  {
    name: "wishlisted",
    path: "/home/wishlisted",
    component: WishListed,
    exact: true,
    authentication: true
  },
  {
    name: "profile",
    path: "/home/profile",
    component: ProfilePage,
    exact: true,
    authentication: true
  },
  {
    name: "search",
    path: "/home/search",
    component: SearchPage,
    exact: true,
    authentication: true
  },
  {
    name: "users",
    path: "/user/:userId",
    component: MainPage,
    exact: true,
    authentication: false,
  },{
  name: "festivaloffers",
  path: "/type/shopping",
  component: MainPage,
  exact: true,
  authentication: false,
},
  {
    name: "campaigns",
    path: "/campaigns/:campaignId/:userId",
    component: MainPage,
    exact: true,
    authentication: false,
  },
  {
    name: "campaigns",
    path: "/campaigns/:campaignId",
    component: MainPage,
    exact: true,
    authentication: false,
  },
  {
    name: "category",
    path: "/category/:category/:userId",
    component: MainPage,
    exact: true,
    authentication: false,
  },
  {
    name: "category",
    path: "/category/:category",
    component: MainPage,
    exact: true,
    authentication: false,
  },
  {
    name: "categorycampaign",
    path: "/categorycampaign/:category/:campaignId/:userId?",
    component: MainPage,
    exact: true,
    authentication: false,
  },
  {
    name: "merchantDetails",
    path: "/home/merchant/:id/:userId?:from",
    component: MerchantDetails,
    exact: true,
    authentication: true
  },
];
