/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// NavBar Component
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Import Component
import Icons from "../Icons";
import config from "../../service/config";

// Constant
import { navItemTitle } from "../../constants";
import uiConfig from "../../uiConfig";
import home from "../../assets/img/home.png";
import maya_homeSelected from "../../assets/img/maya_homeSelected.png"
import adib_homeSelected from "../../assets/img/adib_homeSelected.png"
import hdfc_homeSelected from "../../assets/img/hdfc_homeSelected.png"
import visa_black from "../../assets/img/visa_black.svg"
import visa_blue from "../../assets/img/visa_blue.svg"
const imgPath="../../../assets/img/"+uiConfig.config.whitelabel.clientName+"_homeSelected.png"
//Import Config
var MatomoTracker = require('matomo-tracker');
var matomo = new MatomoTracker(config.siteId, config.siteURL);

const homeLogo = {
  maya:maya_homeSelected,
  adib:adib_homeSelected,
  hdfc:hdfc_homeSelected,
}
export default class NavBar extends PureComponent {
  state = {
    width: 0,
    language: localStorage.getItem("language") ? localStorage.getItem("language") : "EN"
  };

  static get propTypes() {
    return {
      activeMenu: PropTypes.string,
      onChangeTab: PropTypes.func
    };
  }

  static get defaultProps() {
    return {
      activeMenu: "",
      onChangeTab: null
    };
  }

  componentDidMount() {
    this.setState({language: localStorage.getItem("language") || "EN"});
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  /* OnClick Function */
  _onChangeTab = (id) => {
    const { onChangeTab } = this.props;
    // if(id == "visa")
    // {
    //   localStorage.setItem("selectCountry",JSON.stringify({"label":"Singapore","value":"Singapore","geoCode":{"latitude":"1.32698","longitude":"103.847508"},"stateValue":"All","countryLabel":"Singapore","countryValue":"Singapore","countryCode":"SG"}))
    // }
    if (onChangeTab) {
      onChangeTab(id);
    }
    localStorage.setItem('tab',id)
    matomo.track({
      url: window.location.href,
      e_c: id,
      e_a: 'VIEW_CATEGORY'
    });
  };

  /* Other Render Function */
  _renderImage(id) {
    const { activeMenu } = this.props;
    const cls = {
      "nav": true,
      "nav-act": id === activeMenu
    };

    return (
      <div className={`${clsx(cls)} ${this.state.language == "EN" ? "homeiconenglish" : "homeiconarabic"} p-0 home-icon-desk`} onClick={() => this._onChangeTab(id)}
      style={id===activeMenu?{borderBottom:`3px solid ${uiConfig.config.whitelabel.brandColor}`,color:uiConfig.config.whitelabel.brandColor}:uiConfig.config.whitelabel.header.navbar.categoryColor}>
        {/* <Icons 
          name="category"
          width={20}
          height={20}
          fill={id === activeMenu ? "#0292DF" : "#8E8E93"}
        /> */}
        {id===activeMenu?<img src={homeLogo[uiConfig.config.whitelabel.clientName]} alt="home"/>:<img src={home} alt="home"/>}
      </div>
    );
  }

  _renderTitle(title, id) {
    const { activeMenu } = this.props;
    const {language} = this.state;
    const cls = {
      nav: true,
      "nav-act": id === activeMenu
    };

    return (
      <div className={`${clsx(cls)} p-0 nav-bar-items`} onClick={() => this._onChangeTab(id)}
      style={id===activeMenu?{borderBottom:`3px solid ${uiConfig.config.whitelabel.brandColor}`,color:uiConfig.config.whitelabel.brandColor}:uiConfig.config.whitelabel.header.navbar.categoryColor}>
        <span>{title}</span>
      </div>
    );
  }

  _renderTitleForMobile(title, id) {
    const { activeMenu } = this.props;
    const {language} = this.state;
    const clsNew={
      "uiConfig.config.navbar.nav_active":true
    }  

    const cls = {
      "nav-mbl": true,
      "nav-mbl-act": id === activeMenu
    };
    
    return (
      <div
        className={`${clsx(cls)} p-0 flex-center`}
        onClick={() => this._onChangeTab(id)}
        style={id===activeMenu?{backgroundColor:uiConfig.config.whitelabel.brandColor,color:uiConfig.config.whitelabel.header.navbar.category_mbl_activeColor}:uiConfig.config.whitelabel.header.navbar.categoryColor_mbl}>      
                {
          id === "visa" ?
          <div className="title-mbl visa">Singapore Deals by <img src={id===activeMenu? visa_blue : visa_black} alt="visa"/></div>
          :
        <div className="title-mbl">{title}</div>
                }
      </div>
    );
  }

  /* Main Render Functions */
  render() {
    let { categoryList } =this.props;
    let catList = categoryList; //> 0 ? categoryList : JSON.parse(sessionStorage.getItem("catList")) ;
    const {language} = this.state;

    let allCategory = [];
    allCategory = [
      {

        label: "All Offers",
        value: "all offers"
      }
    ];
    let hyperLocalOffersCategory = [{label: "Nearby offers", value: "nearby_offers"}];

    let cateListType = [...catList,...(config.visaOffers ? [{label: "visa", value: "visa"}] : []),...allCategory, ...hyperLocalOffersCategory ];
    if ((catList && catList.length === 0) || !catList) {
      return null;
    }

    const minWidth = 933;
    if (minWidth >= this.state.width) {
      return (
        <div className="col-md-12 p-0 mt-15 navbar-div-mbl">
          {
            language == "EN" ? this._renderTitleForMobile("Home", "home") : this._renderTitleForMobile("الصفحة الرئيسية", "home")
          }
          {
            cateListType.map(category=>{
              {return this._renderTitleForMobile(
                category.label,
                category.value
              )}
            })
          }
          
          {/* {this._renderTitleForMobile(navItemTitle.allOffers, "allOffers")} */}
        </div>
      );
    }
    // Normal View
    return (
      <div className="col-md-12 pt-10 navbar-div">
        {this._renderImage("home")}
        {cateListType.map(category=>{
          {return this._renderTitle(
            category.label,
            category.value
          )}
          })
        }
        {/* {this._renderTitle(navItemTitle.allOffers, "allOffers")} */}
      </div>
    );
  }
}
