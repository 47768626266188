/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Form Select Component
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { find } from "lodash";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";

import Image from "../Image";
import Icons from "../Icons";
import EnImage from "../../assets/img/en.png";
import ArabImage from "../../assets/img/arb.png";
import EnImageReact from "../../assets/img/en_rect.png";
import LocationImage from "../../assets/img/location.png";
import downArrow from "../../assets/img/arrow-down.png";
import SelectTick from "../../assets/img/select-tick.png"

const { Option } = components;
const lang = localStorage.getItem("language")
let selectedLanguage = lang === "EN" ? "english" : 'arabic'
const IconOption = (props) => (
  <Option {...props}>
    <div className="flex justify-between _ai-c">
      <div className="section-left flex justify-between _ai-c">
      <Image
        src={props.data.url === "en" ? EnImage : ArabImage}
        alt={props.data.url}
        w={16}
        h={16}
      />
      <span className={`p-0 ${lang === "EN" ? 'ml-10' : 'mr-10 lang-direction-ltr'}`}>{props.data.label}</span>
      </div>
      {
        props.data.value === selectedLanguage && 
      <div className="section-right">
      <Image
        src={SelectTick}
        alt={"Icon"}
        w={12}
        h={12}
      />
      </div>
      }
    </div>
  </Option>
);

const ValueContainer = ({ children, ...props }) => {
  if (!props.hasValue) {
    return <components.ValueContainer {...props}></components.ValueContainer>;
  }

  const value = props.getValue()[0];
  return (
    <components.ValueContainer {...props}>
      <div className="flex-center">
        <div className={value.value == "EN" ? "mr-8 country-lbl" : "country-lbl"}>
          {value.value === "english" ? "EN" : "(AR)عربى"}
        </div>
        {/* <Image
          src={value.value === "english" ? EnImageReact : ArabImage}
          alt="image"
          w={28}
          h={18}
        /> */}
        {/* <img src={downArrow} width="10" className={value.value == "EN" ? "" : "ml-5"}/> */}
      </div>
      {children[1]}{" "}
      {/* https://github.com/JedWatson/react-select/issues/4170 - For menu close */}
    </components.ValueContainer>
  );
};

const AddOption = (props) => {
  return (
    <div className="col-md-12 location p-0">
      <div className="col-md-12 location-content">
        <div className="col-md-1 p-0 mt-4">
          <Icons name="location" width={18} height={18} />
        </div>
        <div className="col-md-9 p-0 ml-20 mb-10">
          <h5 className="location-text margin-0" onClick={props.changeFunction}>Detect current location</h5>
          <p className="location-description margin-0 mb-10">
            Using Browser Position
          </p>
        </div>
      </div>
    </div>
  );
};

/* const ValueOption = ({ children, ...props }) => {
  if (!props.hasValue) {
    return <components.ValueContainer {...props}></components.ValueContainer>;
  }

  const value = props.getValue()[0];
  return (
    <components.ValueContainer {...props}>
      <div className="flex-center">
        <Image src={LocationImage} alt="image" w={15} h={20} />
        <div className="ml-10 country-lbl">{value.label}</div>
      </div>
      {children[1]}{" "}
      /* https://github.com/JedWatson/react-select/issues/4170 - For menu close
    </components.ValueContainer>
  );
}; */

const ValueOptionList = ({ children, ...props }) => {
  if (!props.hasValue) {
    return <components.ValueContainer {...props}></components.ValueContainer>;
  }

  const value = props.getValue()[0];
  return (
    <components.ValueContainer {...props}>
      <div className="flex-center">
        <Icons name="location_icon" width={14} height={14} />
        <div className="ml-10 country-lbl">{value.label}</div>
      </div>
      {children[1]}{" "}
      {/* https://github.com/JedWatson/react-select/issues/4170 - For menu close */}
    </components.ValueContainer>
  );
};

const SelectMenuList = (props) => {
  const { children } = props; // eslint-disable-line
  const options = props.options;

  return (
    <components.MenuList {...props}>
      {options.length > 0 && <AddOption changeFunction={props.selectProps.onChange}/>}
      <div className="col-md-12 p-0">{children}</div>
    </components.MenuList>
  );
};


const CustomOption = props => {
  const { data, innerRef, innerProps } = props;
  return data.custom ? (
    // <div className="col-md-12 location p-0" ref={innerRef} {...innerProps}>
    //   <div className="col-md-12 location-content">
    //     <div className="col-md-1 p-0 mt-4">
    //       <Icons name="location" width={18} height={18} />
    //     </div>
    //     <div className="col-md-9 p-0 ml-20 mb-10">
    //       <h5 className="location-text margin-0" onClick={props.changeFunction}>Detect current location</h5>
    //       <p className="location-description margin-0 mb-10">
    //         Using Browser Position
    //       </p>
    //     </div>
    //   </div>
    // </div>
    <div className="ocation p-0" ref={innerRef} {...innerProps}>
      <div className="location-content">
          <table>
            <tr>
              <td valign="top" style={{paddingTop: "5px"}}><Icons name="location" width={18} height={18} /></td>
              <td style={{paddingLeft: "5px"}}>
                <h5 className="location-text margin-0" style={{cursor: "pointer"}} onClick={props.changeFunction}>Detect current location</h5><br/>
                <p className="location-description margin-0 mt-0 pt-0 mb-10">
                  Using Browser Position
                </p>
              </td>
            </tr>
          </table>
      </div>
    </div>
  ) : data.label !== "Near me" ? <components.Option {...props} /> : <></>
};
export default class FormSelect extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: ''
    };
    this._refSelect = null;
  }

  static get propTypes() {
    return {
      value: PropTypes.string,
      placeholder: PropTypes.string,
      name: PropTypes.string,
      isDisabled: PropTypes.bool,
      autoFocus: PropTypes.bool,
      onChange: PropTypes.func,
      options: PropTypes.array,
      isError: PropTypes.bool,
      isAutoComplete: PropTypes.bool,
      isCustomSelect: PropTypes.bool,
      isCountrySelect: PropTypes.bool,
      isCountryForMerchant: PropTypes.bool,
      isCountrySelectForMobile: PropTypes.bool
    };
  }

  static get defaultProps() {
    return {
      value: "",
      placeholder: "",
      name: "",
      isDisabled: false,
      tabIndex: 0,
      autoFocus: false,
      onChange: null,
      options: [],
      isError: false,
      isAutoComplete: false,
      isCustomSelect: false,
      isCountrySelect: false,
      isCountryForMerchant: false,
      isCountrySelectForMobile: false
    };
  }

  /* Main Render Function */
  render() {
    const {
      placeholder,
      name,
      isDisabled,
      value,
      isCountrySelectForMobile,
      isAutoComplete,
      isCustomSelect,
      isCountrySelect,
      isCountryForMerchant,
      options,
      onChange,
      onFocus,
      onBlur,
      cacheOptions,
      defaultOptions,
      loadOptions
    } = this.props;

    const handleFocus = (ev) => {
      ev.stopPropagation();

      if (!isDisabled && onFocus) {
        onFocus();
      }
    };

    const handleBlur = (ev) => {
      ev.stopPropagation();

      if (!isDisabled && onBlur) {
        onBlur();
      }
    };

    const commonProps = {
      ref: (ref) => {
        this._refSelect = ref;
      },
      className: "select",
      classNamePrefix: "slt",
      options: options,
      name,
      placeholder: placeholder ? placeholder : "",
      value: find(options, (opt) => opt.value === value) || null,
      onChange: onChange,
      openMenuOnFocus: true,
      closeMenuOnSelect: true,
      isDisabled: isDisabled,
      isMulti: false,
      onFocus: handleFocus,
      onBlur: handleBlur
    };

    if (isAutoComplete) {
      return (
        <div className="select-wrap async-select">
          <AsyncSelect
            {...commonProps}
            cacheOptions={cacheOptions}
            defaultOptions={defaultOptions}
            loadOptions={loadOptions}
          />
        </div>
      );
    }

    if (isCustomSelect) {
      return (
        <div className="select-wrap pointer-none">
          <Select
            {...commonProps}
            components={{ Option: IconOption, ValueContainer ,DropdownIndicator:() => null}}
            isSearchable={false}
          />
        </div>
      );
    }

    if (isCountrySelect) {
      return (
        <div className="flex">
          <div className="flex-center ml-10">
            <Image src={LocationImage} alt="image" w={15} h={20} />
          </div>
          <div className="select-wrap">
          {/* <Select
              {...commonProps}
              components={{
                MenuList: SelectMenuList
              }}
              isSearchable={false}
            /> */}
            <Select
              {...commonProps}
              components={{ Option: CustomOption }}
              isSearchable
            />
          </div>
        </div>
      );
    }

    if (isCountryForMerchant) {
      return (
        <div className="select-wrap merchant">
          <Select
            {...commonProps}
            components={{
              MenuList: SelectMenuList,
              ValueContainer: ValueOptionList,
              DropdownIndicator:() => null
            }}
            isSearchable={false}
            isDisabled
          />
        </div>
      );
    }

    if (isCountrySelectForMobile) {
      return (
        <div className="select-wrap-country">
          <Select {...commonProps} />
        </div>
      );
    }

    return (
      <div className="select-wrap-normal">
        <Select {...commonProps} />
      </div>
    );
  }
}
