/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Footer Component
import React, { PureComponent } from "react";

import { withRouter } from "react-router";
import moment from "moment";
import { arabicContent } from "../../constants/index";
import './style.scss';
import right_arrow from "../../assets/img/right_arrow.png"
import arrow_down from "../../assets/img/arrow_down.png"
import downArrow from "../../assets/img/downArrow.png"
import rightArrow from '../../assets/img/right_arrow1.png'
import adib_globe from "../../assets/img/adib_globe.png"
import maya from "../../assets/img/maya_logo_for_mobile.png"
import Icons from "../../components/Icons";
import uiConfig from "../../uiConfig";
// import powered_by_maya from "../../assets/img/powered_by_maya.svg"
const lang = localStorage.getItem("language");

class Footer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      disclaimer : false
    }
  }
  _renderTitle(title) {
    return <h5 className="ftr-sub">{title}</h5>;
  }

  routeToProfile = (activeItem) => {
    this.props.setActiveItem(activeItem);
    sessionStorage.setItem("currPage", activeItem);
    this.props.history.push("/home/profile", activeItem)
    window.scrollTo(0, 0);
  }
  
  render() {
    return (
      <>
        {this.props.history.location.pathname !== "/login" &&
          <div className="col-md-12 p-0 hdfc-mob-footer" style={{background:uiConfig.config.whitelabel.footer.background ,color:uiConfig.config.whitelabel.footer.foottext}}>
             <div className="item-o" style={{paddingRight : '14px',color:uiConfig.config.whitelabel.footer.foottext, background:uiConfig.config.whitelabel.footer.backgroundColor1}} onClick={() => { this.setState({disclaimer : !this.state.disclaimer}) }} >
              <div className='h-1' >
                <span className='desc' >{lang === 'AR' ? arabicContent.faq : `Disclaimer`}
                </span>
                <span className={ lang === "AR" ? "arrw-ar" : "arrw" } >
                  <img src={uiConfig.config.whitelabel.festiveTreats ? downArrow : arrow_down} width={15} height={9}/>
                </span>
              </div>
            </div>
           <div className={`disclaimer-content${this.state.disclaimer ? '-show' : ''}`}>
                {`Smart Buy is a platform for communication of offers extended by Merchants to HDFC bank's Customers. HDFC bank is only communicating the offers extended by Merchants to its Customers and not selling/rendering any of these products/services. HDFC bank is merely facilitating the payment to its Customers by providing the Payment Gateway Services. HDFC bank is neither guaranteeing nor making any representation of the same. HDFC bank is not responsible for sales/quality/features of the products/services under the offers.`}
            </div>
            {/* <div className="item-o" onClick={() => { this.routeToProfile("faq") }} >
              <div className='h-1' >
                <span className='desc' >{lang === 'AR' ? arabicContent.faq : `FAQ's`}
                </span>
                <span className={ lang === "AR" ? "arrw-ar" : "arrw" } >
                  <img src={right_arrow} />
                </span>
              </div>
            </div> */}
            <div style={{color:uiConfig.config.whitelabel.footer.foottext, background:uiConfig.config.whitelabel.footer.backgroundColor1}} className="item-o" onClick={() => { this.routeToProfile("privacy")}} >
              <div className='h-1' >
                <span className='desc' >{lang === 'AR' ? arabicContent.privPolicy : `Privacy Policy`}
                </span>
                <span className={ lang === "AR" ? "arrw-ar" : "arrw" } >
                  <img src={uiConfig.config.whitelabel.festiveTreats ? rightArrow :right_arrow} />
                </span>
              </div>
            </div>
            <div style={{color:uiConfig.config.whitelabel.footer.foottext, background:uiConfig.config.whitelabel.footer.backgroundColor1}} className="item-o" onClick={() => { this.routeToProfile("termsAndCondition") }} >
              <div className='h-1' >
                <span className='desc' >{lang === 'AR' ? arabicContent.termAndCond : `Terms & Conditions`}
                </span>
                <span className={ lang === "AR" ? "arrw-ar" : "arrw" } >
                  <img src={uiConfig.config.whitelabel.festiveTreats ? rightArrow :arrow_down} />
                </span>
              </div>
            </div>
            {/* <div className="item-o" >
              <a href="mailto:" style={{ textDecorationLine: 'none' }} ><div className='h-1' >
                <span className='desc' >{ lang === "AR" ? arabicContent.contactUsMob : "Contact us" }
                </span>
                <span className={ lang === "AR" ? "arrw-ar" : "arrw" }>
                  <img src={right_arrow} />
                </span>
              </div></a>
            </div> */}
            {/* <div className='sep-line'>
            </div> */}
            <div className='maya'>
            <Icons name="smartbuy" width={160} height={25} />
            </div>
            <div className='rights-content'>
            <span>
                <span>{lang === 'AR' ? '' : `©`}</span>
                    {/* <span> {moment().format("YYYY")} </span> */}
                    <span>{lang === 'AR' ? arabicContent.adbCopyright : ` 2024 HDFC Smartbuy, Inc.`}</span>
                    {lang === 'AR' ? arabicContent.allright : `All rights reserved`} 
                </span>
            </div>
            <div className='privacy'>
              <span >
                <span className="cursor ml-1" onClick={() => {
                  this.routeToProfile("privacy")
                }}>   {lang === 'AR' ? arabicContent.privacy : `Privacy`} · </span>
                <span className="cursor" onClick={() => {
                  this.routeToProfile("termsAndCondition")
                }}>   {lang === 'AR' ? arabicContent.terms : `Terms`}</span>
                {/* {lang === 'AR' ? arabicContent.sitemap : `Sitemap`} */}
              </span>
            </div>
            <div className='maya'>
            <Icons name="mayatextadib" width={207} height={""} />
              {/* {lang !== "AR" ? "" : ""}<img src={maya} />{lang === "AR" ? <span className="mr-10 pt-5" >Powered by</span> : ""} */}
            </div>
            {/* <div className="crayon"><img style={{width:"222px",height:"28px"}} src={powered_by_maya} /></div> */}
          </div>
        }
      </>
    );
  }
}

export default withRouter(Footer)