/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Icon
import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { ReactComponent as Group } from "./Svgs/group.svg";
import { ReactComponent as ArrowUp } from "./Svgs/chevron-arrow-up.svg";
import { ReactComponent as ArrowDown } from "./Svgs/chevron-arrow-down.svg";
import { ReactComponent as Notification } from "./Svgs/notification.svg";
import { ReactComponent as ArrowRight } from "./Svgs/ei-chevron-right.svg";
import { ReactComponent as ArrowLeft } from "./Svgs/ei-chevron-left.svg";
import { ReactComponent as NavLeft } from "./Svgs/nav-arrow-right.svg";
import { ReactComponent as NavRight } from "./Svgs/nav-arrow-left.svg";
import { ReactComponent as Category } from "./Svgs/category.svg";
import { ReactComponent as Location } from "./Svgs/location.svg";
import { ReactComponent as Heart } from "./Svgs/heart.svg";
import { ReactComponent as Star } from "./Svgs/star.svg";
import { ReactComponent as MapView } from "./Svgs/mapview.svg";
import { ReactComponent as MerchantHeart } from "./Svgs/merchant_heart.svg";
import { ReactComponent as Settings } from "./Svgs/settings.svg";
import { ReactComponent as Connect } from "./Svgs/connect.svg";
import { ReactComponent as TasteMeter } from "./Svgs/taste_meter.svg";
import { ReactComponent as Claim } from "./Svgs/claim.svg";
import { ReactComponent as LocationIcon } from "./Svgs/location_icon.svg";
import { ReactComponent as TickIcon } from "./Svgs/tick.svg";
import { ReactComponent as GreenTick } from "./Svgs/green_tick.svg";
import { ReactComponent as Cancel } from "./Svgs/cancel.svg";
import { ReactComponent as Edit } from "./Svgs/edit.svg";
import { ReactComponent as WishList } from "./Svgs/wishlist.svg";
import { ReactComponent as Maya } from "./Svgs/maya.svg";
import { ReactComponent as Menu } from "./Svgs/menu.svg";
import { ReactComponent as Arrow } from "./Svgs/arrow.svg";
import { ReactComponent as NotificationMsg } from "./Svgs/notification_msg.svg";
import { ReactComponent as NotificationContent } from "./Svgs/notification_info.svg";
import { ReactComponent as MayaText } from "./Svgs/powered_by_maya.svg";
import { ReactComponent as MayaTextHDFC } from "./Svgs/powered_by_maya.svg";
import { ReactComponent as MayaTextAdib } from "./Svgs/powered_by_maya1.svg";
import { ReactComponent as Search } from "./Svgs/search.svg";
// import { ReactComponent as CrayonTextHDFC } from "./Svgs/powered_by_crayon.svg";
import { ReactComponent as ArrowFeedBack } from "./Svgs/arrLeft.svg";
import { ReactComponent as Attach } from "./Svgs/attach.svg";
import { ReactComponent as Setting_BL } from "./Svgs/setting_bl.svg";
import { ReactComponent as Connect_BL } from "./Svgs/connect_bl.svg";
import { ReactComponent as Gauge } from "./Svgs/gauge.svg";
import { ReactComponent as MapMarker } from "./Svgs/mapMarker.svg";
import { ReactComponent as Close } from "./Svgs/close.svg";
import { ReactComponent as Clock } from "./Svgs/clock.svg"
import { ReactComponent as CancelMobile } from "./Svgs/cancel_mbl.svg";
import { ReactComponent as EyeEmpty } from "./Svgs/eye-empty.svg";
import { ReactComponent as Expiry } from './Svgs/expiry.svg'
import { ReactComponent as Smartbuy }from './Svgs/smartbuy_new.svg'

export default class Icon extends PureComponent {
  static get propTypes() {
    return {
      fill: PropTypes.string,
      name: PropTypes.string,
      height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    };
  }

  static get defaultProps() {
    return {
      fill: "",
      name: "",
      height: 44,
      width: 44
    };
  }

  /* Main Render Function */
  render() {
    const { name, fill, width, height } = this.props;
    if (name === "group") {
      return <Group fill={fill} width={width} height={height} />;
    }
    if (name === "arrowUp") {
      return <ArrowUp fill={fill} width={width} height={height} />;
    }
    if (name === "arrowDown") {
      return <ArrowDown fill={fill} width={width} height={height} />;
    }
    if (name === "notification") {
      return <Notification fill={fill} width={width} height={height} />;
    }
    if (name === "arrowRight") {
      return <ArrowRight fill={fill} width={width} height={height} />;
    }
    if (name === "arrowLeft") {
      return <ArrowLeft fill={fill} width={width} height={height} />;
    }
    if (name === "navRight") {
      return <NavRight fill={fill} width={width} height={height} />;
    }
    if (name === "navLeft") {
      return <NavLeft fill={fill} width={width} height={height} />;
    }
    if (name === "eye-empty") {
      return <EyeEmpty fill={fill} width={width} height={height} />;
    }
    if (name === "category") {
      return <Category fill={fill} width={width} height={height} />;
    }
    // if (name === "crayontexthdfc") {
    //   return <CrayonTextHDFC fill={fill} width={width} height={height} />;
    // }
    if (name === "location") {
      return <Location fill={fill} width={width} height={height} />;
    }
    if (name === "heart") {
      return <Heart fill={fill} width={width} height={height} />;
    }
    if (name === "star") {
      return <Star fill={fill} width={width} height={height} />;
    }
    if (name === "mapview") {
      return <MapView fill={fill} width={width} height={height} />;
    }
    if (name === "merchant_heart") {
      return <MerchantHeart fill={fill} width={width} height={height} />;
    }
    if (name === "settings") {
      return <Settings fill={fill} width={width} height={height} id='settings-icn'/>;
    }
    if (name === "connect") {
      return <Connect fill={fill} width={width} height={height} />;
    }
    if (name === "taste-meter") {
      return <TasteMeter fill={fill} width={width} height={height} />;
    }
    if (name === "claim") {
      return <Claim fill={fill} width={width} height={height} />;
    }
    if (name === "location_icon") {
      return <LocationIcon fill={fill} width={width} height={height} />;
    }
    if (name === "tick") {
      return <TickIcon fill={fill} width={width} height={height} />;
    }
    if (name === "green_tick") {
      return <GreenTick fill={fill} width={width} height={height} />;
    }
    if (name === "cancel") {
      return <Cancel fill={fill} width={width} height={height} />;
    }
    if (name === "edit") {
      return <Edit fill={fill} width={width} height={height} />;
    }
    if (name === "wishList") {
      return <WishList fill={fill} width={width} height={height} />;
    }
    if (name === "maya") {
      return <Maya fill={fill} width={width} height={height} />;
    }
    if (name === "menu") {
      return <Menu fill={fill} width={width} height={height} />;
    }
    if (name === "arrow") {
      return <Arrow fill={fill} width={width} height={height} />;
    }
    if (name === "not-msg") {
      return <NotificationMsg fill={fill} width={width} height={height} />;
    }
    if (name === "not-cnt") {
      return <NotificationContent fill={fill} width={width} height={height} />;
    }
    if (name === "mayatext") {
      return <MayaText fill={fill} width={width} height={height} />;
    }
    if (name === "mayatextadib") {
      return <MayaTextAdib fill={fill} width={width} height={height} />;
    }
    if (name === "mayatexthdfc") {
      return <MayaTextHDFC fill={fill} width={width} height={height} />;
    }
    if (name === "search") {
      return <Search fill={fill} width={width} height={height} />;
    }
    if (name === "arrleft") {
      return <ArrowFeedBack fill={fill} width={width} height={height} />;
    }
    if (name === "attach") {
      return <Attach fill={fill} width={width} height={height} />;
    }
    if (name === "setting_bl") {
      return <Setting_BL fill={fill} width={width} height={height} />;
    }
    if (name === "connect_bl") {
      return <Connect_BL fill={fill} width={width} height={height} />;
    }
    if (name === "gauge") {
      return <Gauge fill={fill} width={width} height={height} />;
    }
    if (name === "mapmarker") {
      return <MapMarker fill={fill} width={width} height={height} />;
    }
    if (name === "close") {
      return <Close fill={fill} width={width} height={height} />;
    }
    if (name === "clock") {
      return <Clock fill={fill} width={width} height={height} />;
    }
    if (name === "cancelMobile") {
      return <CancelMobile fill={fill} width={width} height={height} />;
    }
    if (name === "expiry") {
      return <Expiry fill={fill} width={width} height={height} />;
    }
    if (name === "smartbuy") {
      return <Smartbuy fill={fill} width={width} height={height} />;
    }

    return null;
  }
}
