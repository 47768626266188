/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

import axios from "axios";
import { accessTokenHelper } from "../helper/token";
import Config from "./config";
import { headerwotoken } from "./header";

const BASE_URL = Config.domain + Config.apiVersion;
const getAxios = ({ baseURL = '', registerInterceptor = false }) => {
    const instance = axios.create({
        baseURL,
        headers: headerwotoken,
    })

    if (registerInterceptor) {
        instance.interceptors.request.use(
            config => {
                const token = localStorage.getItem('TOKEN');
                if (token) {
                    config.headers['User-Access-Token'] = token
                }
                return config
            }
            ,
            error => {
                return error
            }
        )

        instance.interceptors.response.use(
            response => response,
            error => {
                if (error && error.response && error.response.status === 401 && Config.isLoginEnabled === "true") {
                    localStorage.clear();
                    sessionStorage.clear();
                    accessTokenHelper.del();
                    window.location.href = "/login"
                }
                else
                    return Promise.reject(error);
            }
        );
    }
    return instance
}

export const axiosOpen = getAxios({ baseURL: BASE_URL })
export const axiosProtected = getAxios({
    baseURL: BASE_URL,
    registerInterceptor: true,
})