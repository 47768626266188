/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Options
const languageOption = [
  {
    label: "English",
    value: "english",
    url: "en"
  },
  // {
  //   label: "Arabic (أرابيكاربيك)",
  //   value: "arabic",
  //   url: "arb"
  // }
];

const coutryOptions = [
  {
    label: "Abu Dhabi",
    value: "abudhabi"
  },
  {
    label: "Oman",
    value: "oman"
  },
  {
    label: "Dubai",
    value: "dubai"
  },
  {
    label: "Sharjah",
    value: "sharjah"
  }
];

const tagOptions = [
  {
    label: "Discount",
    id: "discount",
    color: "#FFF7E6",
    arabicLabel: "أفضل العروض"
  },
  {
    label: "Retail",
    id: "retail",
    color: "#D1EEEB",
    arabicLabel: "تنتهي اليوم"
  },
  {
    label: "Cashback",
    id: "cashback",
    color: "#FFF4EF",
    arabicLabel: "الاسترداد النقدي"
  },
  {
    label: "Online",
    id: "online",
    color: "#D1EEEB",
    arabicLabel: "قم بشراء 1 واحصل على 1"
  }
];

const searchOptions = [
  {
    label: "Cafe Center",
    value: "cafe center"
  },
  {
    label: "Starbucks",
    value: "star bucks"
  },
  {
    label: "lulu cafe",
    value: "lulu cafe"
  },
  {
    label: "Iranian cafe",
    value: "iranian cafe"
  },
  {
    label: "buy 1 get 1 cafe",
    value: "buy 1 get 1 cafe"
  },
  {
    label: "20% off on coffee & pastry",
    value: "20% off on coffee & pastry"
  }
];

const genderOptions = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Others", value: "Others" }
];

export {
  languageOption,
  coutryOptions,
  genderOptions,
  tagOptions,
  searchOptions
};
