/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

import React, { Component } from "react";
import { withRouter } from "react-router";
import "./style.scss"

//Import  Packages
import disableScroll from 'disable-scroll';

//Import Component
import Select from "../Select";
import Icon from "../Icons";
import Icons from "../Icons";
import AdibSearchBar from "../Adib_Search_Bar";

// Import Helper
import { languageOption } from "../helper/options";
import config from "../../service/config";
import { arabicContent, arabicHeaderMenu } from "../../constants/index";
import { deepEqual } from "../../pages/helper";
import { getError, getTypeHead, getCampaigns } from "../../service";

//Import Assests
import ArrowDown from "../../assets/img/arrow-down.png";
import HeartIcon from "../../assets/img/heartunfilled.png";
import SearchIcon from "../../assets/img/search-icon.png";
import SmartDeal from "../../assets/img/smart-deal.png";
import MapMarker from "../../assets/img/map-marker.png";
import UserIcon from "../../assets/img/user-icon.png";
import ActiveUserIcon from "../../assets/img/act-user-icon.png";
import location_img from "../../assets/img/locationSlider.png"
import recent_location from "../../assets/img/recent_location.png";
import recomend_location from "../../assets/img/recomend_location.png"
import HeaderMobile from "../HeaderMobile";

//Import Matomo Config
var MatomoTracker = require('matomo-tracker');
var matomo = new MatomoTracker(config.siteId, config.siteURL);

//Creating Const - Modifing the original const values
const homeMenuEN = {
  label: "Home",
  value: "home"
}

const homeMenuAR = {
  label: "الصفحة الرئيسية",
  value: "home"
}

const myAccount = [
  {
    label: "Claimed Offers",
    value: "claimed"
  },
  {
    label: "Redemptions",
    value: "redemption"
  },
  {
    label: "App Feedback",
    value: "appFeedback"
  },
  {
    label: "FAQs",
    value: "faq"
  },
  {
    label: "Privacy Policy",
    value: "privacy"
  },
  {
    label: "Terms & Conditions",
    value: "termsAndCondition"
  }
]

const myAccountAR = [
  {
    label: arabicHeaderMenu.claimedOffers,
    value: "claimed"
  },
  {
    label: arabicHeaderMenu.redemptions,
    value: "redemption"
  },
  {
    label: arabicHeaderMenu.appFeedback,
    value: "appFeedback"
  },
  {
    label: arabicHeaderMenu.faq,
    value: "faq"
  },
  {
    label: arabicHeaderMenu.privacyPolicy,
    value: "privacy"
  },
  {
    label: arabicHeaderMenu.termsAndConditions,
    value: "termsAndCondition"
  }
]

class AdibHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryValue: localStorage.getItem("language") && localStorage.getItem("language") == "EN" ? "english" : "arabic",
      hasShowLocation: false,
      width: 0,
      filteredCountryList: [],
      value: "",
      showGps: true,
      country: "",
      searchCountry: "",
      selectedCity: null,
      language: localStorage.getItem("language"),
      countryListUpdated: [],
      invisible: false,
      suggestions: [],
      suggestionSelected: false,
      typeAhead: [],
      searchValue: ""
    }
    this.watchID = null;
    this.textInput = React.createRef();
  }

  componentDidMount = () => {
    this.setState({
      language: localStorage.getItem("language")
    })
    if (this.props.countryList) {
      this.setState({
        filteredCountryList: this.props.countryList
      })
      var countries = this.props.countryList;
      var newCountryList = [];
      countries.map((country, index) => {
        if (!country.custom) {
          newCountryList.push(country);
        }
      })
      var newcountry2 = [];
      newcountry2.push({ custom: true });
      newCountryList.map((country2, index) => {
        newcountry2.push(country2);
      })
      newcountry2.push({ label: "Near me", value: this.state.language === 'EN' ? "Near me" : "بالقرب مني" });
      const countryList = newcountry2;
      this.setState({ countryListUpdated: newcountry2 });
      const selOption = localStorage.getItem('selectCountry');
      const firstItem = selOption ? selOption : countryList[1];
      const mapValue = selOption ? JSON.parse(firstItem) : countryList[1];
      if (mapValue) {
        this.setState({
          country: mapValue.value,
          selectedCity: mapValue,
          searchCountry: mapValue.label
        });
      }
      this.updateWindowDimensions();
      window.addEventListener("resize", this.updateWindowDimensions);
      this.watchID = navigator.geolocation.watchPosition((position) => {
        localStorage.setItem(
          "currentLocation",
          JSON.stringify({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          })
        );
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.countryList && this.props.countryList.length > 0) {
      var countries = this.props.countryList;
      var newCountryList = [];
      if (countries) {
        countries.map((country) => {
          if (!country.custom) {
            newCountryList.push(country);
          }
        })
        var newcountry2 = [];
        newcountry2.push({ custom: true });
        newCountryList.map((country2, index) => {
          newcountry2.push(country2);
        })
        newcountry2.push({ label: "Near me", value: this.state.language === 'EN' ? "Near me" : "بالقرب مني" });
        const countryList = newcountry2;
        // let enLoc = localStorage.getItem('selectCountry');
        // let res = countryList.filter(obj => {
        //   if (obj.geoCode && enLoc) {
        //     return Object.values(obj.geoCode)[0] === String(JSON.parse(enLoc)?.geoCode?.latitude);
        //   }
        // }) || null;
        // const selOption = JSON.stringify(res[0]);
        const selOption = localStorage.getItem('selectCountry');
        const firstItem = selOption ? selOption : countryList[1];
        const mapValue = selOption ? JSON.parse(firstItem) : countryList[1];
        if (!deepEqual(prevProps, this.props)) {
          if (mapValue) {
            this.setState({
              country: mapValue.value,
              selectedCity: mapValue,
              searchCountry: mapValue.label,
              countryListUpdated: newcountry2,
            });
          }
          if (this.props.history.location?.state?.from === "wishlist") {
            window.scrollTo(0, 0);
          }
        }
      }
      this.watchID = navigator.geolocation.watchPosition((position) => {
        localStorage.setItem(
          "currentLocation",
          JSON.stringify({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          })
        );
      });
    }
  }

  //Update Window Dimensions
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  /* OnLanguage Select */
  _onChangeSelect = (opt) => {
    localStorage.setItem("language", opt.value === "english" ? "EN" : "AR");
    this.setState({
      countryValue: opt ? opt.value : "",
    });
    matomo.track({
      url: window.location.href,
      e_c: localStorage.getItem("TOKEN"),
      e_a: 'CHANGE_LANGUAGE',
      e_n: opt.value === "english" ? "EN" : "AR",
    });
    setTimeout(() => {
      this.props.history.go(0);
    }, 100)
  };

  //Navigate Whishlist
  handleWhishlist = (val) => {
    this.props.setRightMenuActive(val);
    this.props.setActiveMenu(" ");
    this.props.history.push("/home/wishlisted");
    this.props.onSelectWhishlist()
  }

  _onClickWishlist = () => {
    this.props.setActiveMenu(" ");
    this.props.history.push("/home/wishlisted")
  }

  //On Showing Map Location
  handleLocation = (val) => {
    this.onCloseSearchBar()
    const minWidth = 933;
    this.props.setRightMenuActive(val);
    this.setState({
      hasShowLocation: true,
      filteredCountryList: this.props.countryList || []
    }, () => {
      document.documentElement.style.overflowY = 'hidden'
      document.body.style.overflowY = 'hidden';
      if (minWidth <= this.state.width) { document.body.style.padding = '0px' }
    })
  }

  handleAccOption = (val) => {
    sessionStorage.setItem("activeMenu", "undefined");
    this.props.setRightMenuActive(val);
    this.props.onProfileMenu(val);
  }

  //When click outside the suggestion section will hide
  onCloseSearchBar = () => {
    this.props.onSelectFocus();
    this.props.setRightMenuActive(" ");
    this.setState({ invisible: false, value: "" });
    disableScroll.off();
    document.documentElement.style.overflowY = 'auto'
    document.body.style.overflowY = 'auto';
  }

  //Render the Map Location Component
  _renderLocationModal() {
    const minWidth = 933;
    const { showGps, language } = this.state;
    let selected = localStorage.getItem("selectCountry") || localStorage.getItem("selectFirstCountry");
    let selCountry = JSON.parse(selected).label === "Near me" || JSON.parse(selected).label === arabicContent.nearMe;
    
    // Removed notification/settings Icon for mobile view
    if (minWidth >= this.state.width) {
      return (
        <div className={`col-md-12 p-0 not-loc-modal`}>
          <div className={`col-md-4 p-0 main ${language === "AR" && "main-arabic"}`}>
            <div className="not-cont col-md-12 p-0">
              <div className="col-md-12 header p-0 flex-start">
                <div
                  className="cancel-icn flex-center cursor"
                  onClick={this.closeCountryModal}
                >
                  <Icon fill="#000" width={15} height={15} name="cancel" />
                </div>
              </div>
              <div className="search-location">
                {/* <input type="text" placeholder={language === "EN" ? "Search for area, street name.." : arabicContent.searchLoc} onChange={(e) => this.searchCountry(e.target.value)} /> */}
                <input type="text" placeholder={language === "EN" ? "Search for city" : arabicContent.searchCity} onChange={(e) => this.searchCountry(e.target.value)} />
              </div>
              {showGps &&
                <div className="gps">
                  <div className="box">
                    <div><Icons name="location" width={18} height={18} /></div>
                    <div className="gps-text" onClick={() => this._onChangeCountrySelect()}>
                      <div className="sub-text">{language === "EN" ? "Get current location" : arabicContent.getCurrentLoc}</div>
                      <div className="support-text">{language === "EN" ? "USING GPS" : arabicContent.gps}</div></div>
                  </div>
                </div>
              }
              {this._renderCountryData(language === "EN" ? "Recommended Locations" : arabicContent.recommendLoc)}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="col-md-12 p-0 not-loc-modal">
        <div className={`col-md-4 p-0 main ${language === "AR" && "main-arabic"}`}>
          <div className="not-cont col-md-12 p-0">
            <div className="col-md-12 header p-0 flex-start">
              <div
                className="cancel-icn flex-center cursor"
                onClick={() => this.closeCountryModal()}
              >
                <Icon fill="#000" width={15} height={15} name="cancel" />
              </div>
            </div>
            <div className="search-location">
              {/* <input type="text" placeholder={language === "EN" ? "Search for area, street name.." : arabicContent.searchLoc} onChange={(e) => this.searchCountry(e.target.value)} /> */}
              <input type="text" placeholder={language === "EN" ? "Search for city" : arabicContent.searchCity} onChange={(e) => this.searchCountry(e.target.value)} />
            </div>
            {showGps && <div className="gps">
              <div className="box">
                <div><img src={location_img} width={18} height={18} /></div>
                <div className="gps-text" onClick={() => this._onChangeCountrySelect()}>
                  <div className={`sub-text ${selCountry && "act"}`}>
                    {language === "EN" ? "Get current location" : arabicContent.getCurrentLoc}
                    </div>
                  <div className={`support-text ${selCountry && "act"}`}>
                    {language === "EN" ? "USING GPS" : arabicContent.gps}
                  </div>
                </div>
              </div>
            </div>}
            {this._renderCountryData(language === "EN" ? "Recommended Locations" : arabicContent.recommendLoc)}
          </div>
        </div>
      </div>
    );
  }

  //Render Country Data
  _renderCountryData = (title) => {
    const { filteredCountryList } = this.state;
    let selected = localStorage.getItem("selectCountry") || localStorage.getItem("selectFirstCountry");
    let selCountry = JSON.parse(selected);
    
    return (
      <>
        <div className="recent-location">
          <div className="gps-title">{title}</div>
          {filteredCountryList.map(country => <div className="box">
            <div>
              {title === "recent locations" ? <img src={recent_location} width={18} height={18} />
                : <img src={recomend_location} width={18} height={18} />}
            </div>
            <div className="recent-gps-text" onClick={() => this._onChangeCountrySelect(country)}>
            {selCountry.label === country.label ? <>
              <div className="sub-text act">{country.label}</div>
              <div className="support-text act">{country.countryLabel}</div>
            </>  :  <>
              <div className="sub-text">{country.label}</div>
              <div className="support-text">{country.countryLabel}</div>
            </> }
            </div>
          </div>
          )}
        </div>
      </>
    )
  }

  //Close Country Modal
  closeCountryModal = () => {
    this.props.setRightMenuActive(" ");
    this.setState({
      hasShowLocation: false,
      filteredCountryList: this.props.countryList || [],
      showGps: true
    }, () => {
      document.documentElement.style.overflowY = 'unset';
      document.body.style.overflowY = 'unset';
      document.body.style.paddingRight = '0px';
    })
  }

  //On Country Change Select
  _onChangeCountrySelect = async (opt) => {
    const { getHomeList, history } = this.props;
    if (opt && opt.label) {
      this.setState({
        country: opt ? opt.value : "",
        searchCountry: opt ? opt.label : "",
        selectedCity: opt,
        filteredCountryList: this.props.countryList || []
      });
      localStorage.setItem('selectCountry', JSON.stringify(opt));
      this.closeCountryModal()
      if (getHomeList) {
        getHomeList(opt);
        getCampaigns();
      }
      if (history){
        sessionStorage.setItem("actMenu", sessionStorage.getItem("actMenu") || "home")
        this.props.setActiveMenu(sessionStorage.getItem("actMenu") || "home");
        history.push(`/home`);
      }
    } else {
      this.closeCountryModal()
      if (navigator.geolocation) {
        this.showLocation();
      }
    }
  };

  //Show Location
  showLocation = () => {
    if(navigator.permissions&&navigator.permissions.query)
    {
      navigator.permissions.query({ name: 'geolocation' })
      .then((result) => {
        if (result.state === "denied" || result.state === "prompt") {
          alert("Geolocation is not enabled. Please enable to use this feature");
        } else {
          this.setState({ country: "Near me" })
          const { getHomeList, history } = this.props;
          var browserLocation = localStorage.getItem("currentLocation");
          var latlong = JSON.parse(browserLocation);

          var opt = { "label": "Near me", "value": "Near me", "geoCode": { "latitude": latlong?.latitude, "longitude": latlong?.longitude } };
          if (this.state.language === "EN") {
            localStorage.setItem('selectCountry', JSON.stringify({ "label": "Near me", "value": "Near me", "geoCode": { "latitude": latlong?.latitude, "longitude": latlong?.longitude } }));
          } else {
            localStorage.setItem('selectCountry', JSON.stringify({ "label": arabicContent.nearMe, "value": arabicContent.nearMe, "geoCode": { "latitude": latlong?.latitude, "longitude": latlong?.longitude } }));
          }

          if (getHomeList) {
            getHomeList(opt);
            getCampaigns(opt);
          }
          if (history){
            sessionStorage.setItem("actMenu", "home")
            this.props.setActiveMenu("home");
            history.push(`/home`);
          } 
        }
      });}
  }

  //Search Country
  searchCountry = (val) => {
    const { countryList } = this.props;
    let filteredCountryList = countryList.filter(data =>
      data.value.toLowerCase().includes(val.toLowerCase()) || data.countryValue.toLowerCase().includes(val.toLowerCase())
    );
    this.setState({ filteredCountryList })
    if (val.length === 0) {
      this.setState({ showGps: true })
    }
    else {
      this.setState({ showGps: false })
    }
  }

  _onClickHome = () => {
    const { history } = this.props;
    this.props.onChangeTab("home")
    history.push(`/home`, "home");
  };


  //Handle Search Action - Bar
  handleSearch = (val) => {
    this.props.setRightMenuActive(val);
    this.setState({ invisible: true })
    document.documentElement.style.overflowY = 'hidden'
    document.body.style.overflowY = 'hidden';
    this.props.onSelectWhishlist()
  }

  //Suggestions Fetch Request
  onSuggestionsFetchRequest = () => { }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  //When Suggestion Selected
  oneSuggestionSelected = async (event, { suggestion }) => {
    if (suggestion.type == 'item') {
      const { history } = this.props;
      const { emptyTypeAhead } = this.props;
      if (event && event.keyCode !== 13) {
        this.setState({ invisible: false, value: "", suggestionSelected: true });
        disableScroll.off();
        if (history) {
          this.onSuggestionsClearRequested()
          await this.props.setActiveMenu("");
          emptyTypeAhead && emptyTypeAhead();
          history.push(`/home/merchant/${suggestion.id}`);
          sessionStorage.setItem("activeMenu", "undefined");
          sessionStorage.setItem("actMenu", "home")
          //this.textInput.current.blur();
        }
        document.documentElement.style.overflowY = 'auto'
        document.body.style.overflowY = 'auto';
      }
    } else {
      this.onClickRecentSearch(suggestion.name);
    }
  };

  //On Recent Search
  onClickRecentSearch = (suggestion) => {
    const { history } = this.props;
    this.setState({ invisible: false, value: "" });
    disableScroll.off();
    history.push("/home/search", {
      searchKeyword: suggestion,
      selectedCity: this.state.selectedCity
    });
    //  this.textInput.current.blur();
    document.documentElement.style.overflowY = 'auto'
    document.body.style.overflowY = 'auto';
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue = (suggestion) => suggestion.name;

  //On Change
  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      suggestionSelected: false
    });
    this.onSuggestionsFetchRequested(newValue)
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = (value) => {
    const { country } = this.state;
    if (value.length >= 1) {
      if (this.getTypeHeadItem) {
        this.getTypeHeadItem(country, value)
      }
    }
  };

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = (value) => {
    if (value.length >= 2) {
      const { typeAhead } = this.state;
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;
      return inputLength === 0
        ? []
        : typeAhead
    }
    else {
      return []
    }
  };

  getTypeHeadItem = (city, query) => {
    var activeMenu = "all";
    if (this.props.activeMenu == "home" || this.props.activeMenu == "allOffers" || this.props.activeMenu == "") {
      activeMenu = "all";
    } else {
      activeMenu = this.state.activeMenu;
    }
    getTypeHead(activeMenu, city, query)
      .then((res) => {
        if (res && res.response && res.response && !this.state.suggestionSelected) {
          this.setState({ typeAhead: res.response }, () => {
            this.setState({ suggestions: this.getSuggestions(query) })
          });
        }

      })
      .catch((err) => alert(getError(err)));
  };

  onFocus = () => {
    if (document.getElementById('header-content') !== null) {
      var headerWidth = document.getElementById("header-content").offsetWidth;
      var searchwidth = headerWidth / 1.2;
      document.getElementById("searchDiv").style.width = searchwidth + "px";
      this.setState({ invisible: true });
    }
  };

  _onkeyDownContent = (ev) => {
    const { history } = this.props;
    const { emptyTypeAhead } = this.props;
    if (ev && ev.keyCode === 13) {
      this.setState({ invisible: false, value: "", suggestionSelected: true });
      disableScroll.off();
      history.push("/home/search", {
        searchKeyword: ev.target.value,
        selectedCity: this.state.selectedCity,
      });
      //    this.textInput.current.blur();
      document.documentElement.style.overflowY = 'auto'
      document.body.style.overflowY = 'auto';
      this.props.getRecentSearchData();
    }
    else if (ev && ev.keyCode === 8 && ev.target.value.length <= 1) {
      emptyTypeAhead && emptyTypeAhead();
      this.setState({ invisible: true, value: "", suggestions: [] });
    }
  };

  //When Clicking search text in search modal
  onClickSearchText = (v) => {
    const { history } = this.props;
    this.setState({ invisible: false, value: "", suggestionSelected: true });
    disableScroll.off();
    history.push("/home/search", {
      searchKeyword: v,
      selectedCity: this.state.selectedCity,
    });
    document.documentElement.style.overflowY = 'auto'
    document.body.style.overflowY = 'auto';
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      suggestionSelected: false
    });
    this.onSuggestionsFetchRequested(newValue)
  };

  //Close outside when click search bar
  closeOutSide = () => {
    this.onCloseSearchBar()
  }


  render() {
    const { activeMenu, rightMenuActive, onChangeTab, reacentSearch, categoryList, hasShowMoreMenu, handleOnMoreMenu, onShowMyMenu, hasActiveMyMenu, onProfileMenu, selectedMyMenu, onSelectFocus, countryList, mainMenuActive } = this.props;
    const { countryValue, hasShowLocation, invisible, searchValue, searchCountry, suggestions, value, country } = this.state;
    
    let selectedLanguage = localStorage.getItem("language");
    let catList=sessionStorage.getItem('catList')
    let categoriesList=categoryList.length>0?categoryList:catList?JSON.parse(catList):[];
    let menuCategory = [localStorage.getItem("language") === 'EN' ? homeMenuEN : homeMenuAR, ...categoriesList];
    let menuList = menuCategory.slice(0, 4);
    let moreMenuList = menuCategory.slice(4, menuCategory.length);
    const minWidth = 1024;
    if (this.state.width <= minWidth) {
      return <HeaderMobile
        categoryList={categoriesList}
        languageOption={languageOption}
        language={countryValue}
        country={country}
        countryList={countryList}
        onChangeTab={onChangeTab}
        changeHeader={this.props.changeHeader}
        onLanguageChange={this._onChangeSelect}
        _onChangeCountrySelect={this._onChangeCountrySelect}
        _onClickHome={this._onClickHome}
        _onClickProfileItem={onProfileMenu}
        _onClickWishlist={this._onClickWishlist}
        handleSearch={this.handleSearch} 
        searchValue={searchValue}
        reacentSearch={reacentSearch}
        searchCountry={searchCountry}
        onClickRecentSearch={this.onClickRecentSearch}
        onCloseSearchBar={this.onCloseSearchBar}
        value={value}
        suggestions={suggestions}
        onChange={this.onChange}
        _onkeyDownContent={this._onkeyDownContent}
        onFocus={this.onFocus}
        onSuggestionsFetchRequest={this.onSuggestionsFetchRequest}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        oneSuggestionSelected={this.oneSuggestionSelected}
        getSuggestionValue={this.getSuggestionValue}
        textInput={this.textInput}
        invisible={invisible}
        closeOutSide={this.closeOutSide}
        onClickSearchText={(v) => this.onClickSearchText(v)}
        myAccount={selectedLanguage === "AR" ? myAccountAR : myAccount}
        onProfileMenu={onProfileMenu}
        selectedMyMenu={selectedMyMenu}
      />
    }
    else {
      return (
        <React.Fragment>
          <header>
            <div className={`header-wrap ${selectedLanguage === "AR" && "header-wrap-ar"}`}>
              <div className="header-inner-wrap">
                {
                  selectedLanguage === "EN" ?
                    <div className="col-md-12 p-0">
                      <div className="col-md-5 p-0">
                        <div className="left-menu">
                          <ul>
                            {
                              menuList.map((c) => {
                                return (
                                  <li className={`${c.value === activeMenu && sessionStorage.getItem('actMenu') === activeMenu  ? "active" : ""}`} onClick={() => {onChangeTab(c.value)}}>
                                    {
                                      c.label.length > 12 ? <span title={c.label}>{c.label.substring(0, 12)}...</span> : <span title={c.label}>{c.label}</span>
                                    }
                                  </li>
                                )
                              })
                            }
                            <li className={hasShowMoreMenu?"more-submenu-li menu-active":"more-submenu-li"} onClick={() => handleOnMoreMenu()}>
                              <span class="more-menu">More <img src={ArrowDown} style={{verticalAlign: "baseline"}}  /></span>
                              {
                                hasShowMoreMenu &&
                                <div className="more-submenu">
                                  <ul>
                                    {
                                      moreMenuList.map((c) => {
                                        return (
                                          <li className={`${c.value === activeMenu && sessionStorage.getItem('actMenu') === activeMenu ? "active" : ""}`} onClick={() => onChangeTab(c.value)}>
                                            <span>{c.label}</span>
                                          </li>
                                        )
                                      })
                                    }
                                  </ul>
                                </div>
                              }
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="right-menu">
                          {/* <div className="language-wrap">
                            <Select
                              isCustomSelect
                              value={countryValue}
                              options={languageOption}
                              onChange={this._onChangeSelect}
                              onFocus={onSelectFocus}
                            />
                          </div>
                          <div className="spacer"></div> */}
                          <div className={`search-div cursor ${rightMenuActive === "search" && mainMenuActive && "active"}`} onClick={() => this.handleSearch("search")}>
                            <img src={SearchIcon} alt="icon" width={18}/>
                          </div>
                          <div className="spacer"></div>
                          <div className={`whishlist-div cursor ${rightMenuActive === "whishlist" && mainMenuActive && "active"}`} onClick={() => this.handleWhishlist("whishlist")}>
                            <img src={HeartIcon} alt="icon" width={22} />
                          </div>
                          <div className="spacer"></div>
                          {countryList.length > 0 && <>
                          <div className={`location-div cursor flex ${rightMenuActive === "location" && "active"}`} onClick={() => this.handleLocation("location")}>
                            {/* <img src={MapMarker} alt="icon" width={15}/> */}
                            <Icons name="mapmarker" width={20} height={24} />
                          </div>
                          <div className="spacer"></div>
                          </>}
                          <div className="my-account" onClick={onShowMyMenu}>
                            <button className={`btn btn-account ${hasActiveMyMenu && "act"}`}>
                              <span>Account</span>
                              {hasActiveMyMenu ?
                                <img src={ActiveUserIcon} alt="icon" /> :
                                <img src={UserIcon} alt="icon" />}
                            </button>
                            {
                              hasActiveMyMenu &&
                              <div className="myaccount-submenu">
                                <ul>
                                  {
                                    myAccount.map((m) => {
                                      return (
                                        <li className={`${m.value === selectedMyMenu ? "active" : ""}`} onClick={() => this.handleAccOption(m.value)}>
                                          <span>{m.label}</span>
                                        </li>
                                      )
                                    })
                                  }
                                </ul>
                              </div>
                            }
                          </div>
                          <div className="extspacer"></div>
                          <div className="deal-div cursor" onClick={() => onChangeTab("home")}>
                            <img src={SmartDeal} alt="icon" width="160" />
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    <div className="col-md-12 p-0">
                      <div className="col-md-8">
                        <div className="right-menu">
                          <div className={selectedLanguage == "EN" ? "language-wrap" : "language-wrap-ar"}>
                            <Select
                              isCustomSelect
                              value={countryValue}
                              options={languageOption}
                              onChange={this._onChangeSelect}
                              onFocus={onSelectFocus}
                            />
                          </div>
                          <div className="spacer"></div>
                          <div className={`search-div cursor ${rightMenuActive === "search" && mainMenuActive && "active"}`} onClick={() => this.handleSearch("search")}>
                            <img src={SearchIcon} alt="icon" />
                          </div>
                          <div className="spacer"></div>
                          <div className={`whishlist-div cursor ${rightMenuActive === "whishlist" && mainMenuActive && "active"}`} onClick={() => this.handleWhishlist("whishlist")}>
                            <img src={HeartIcon} alt="icon" width={20} />
                          </div>
                          <div className="spacer"></div>
                          <div className={`location-div cursor ${rightMenuActive === "location" && "active"}`} onClick={() => this.handleLocation("location")}>
                            <img src={MapMarker} alt="icon" />
                          </div>
                          <div className="spacer"></div>
                          <div className="my-account" onClick={onShowMyMenu}>
                            <button className={`btn btn-account ${hasActiveMyMenu && "act"}`}>
                              {hasActiveMyMenu ?
                                <img src={ActiveUserIcon} alt="icon" /> :
                                <img src={UserIcon} alt="icon" />}
                              <span>{selectedLanguage === "EN" ? "Account" : "الحساب"}</span>
                            </button>
                            {
                              hasActiveMyMenu &&
                              <div className="myaccount-submenu">
                                <ul>
                                  {
                                    myAccountAR.map((m) => {
                                      return (
                                        <li className={`${m.value === selectedMyMenu ? "active" : ""}`} onClick={() => this.handleAccOption(m.value)}>
                                          <span>{m.label}</span>
                                        </li>
                                      )
                                    })
                                  }
                                </ul>
                              </div>
                            }
                          </div>
                          <div className="extspacer"></div>
                          <div className="deal-div cursor" onClick={() => onChangeTab("home")}>
                            <img src={SmartDeal} alt="icon" />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-0">
                        <div className="left-menu">
                          <ul>
                            {
                              menuList.map((c) => {
                                return (
                                  <li className={`${c.value === activeMenu && sessionStorage.getItem('actMenu') === activeMenu ? "active" : ""}`} onClick={() => onChangeTab(c.value)}>
                                    {
                                      c.label.length > 12 ? <span title={c.label}>{c.label.substring(0, 12)}...</span> : <span title={c.label}>{c.label}</span>
                                    }
                                  </li>
                                )
                              })
                            }
                            <li className="more-submenu-li" onClick={() => handleOnMoreMenu()}>
                              <span>{selectedLanguage === "EN" ? "More" : "أكثر"} <img src={ArrowDown} style={{verticalAlign: "baseline"}} /></span>
                              {
                                hasShowMoreMenu &&
                                <div className="more-submenu">
                                  <ul>
                                    {
                                      moreMenuList.map((c) => {
                                        return (
                                          <li className={`${c.value === activeMenu && sessionStorage.getItem('actMenu') === activeMenu ? "active" : ""}`} onClick={() => onChangeTab(c.value)}>
                                            <span>{c.label}</span>
                                          </li>
                                        )
                                      })
                                    }
                                  </ul>
                                </div>
                              }
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                }
              </div>
            </div>
          </header>
          <div className="hiddenDiv">adib bold font</div>
          <div className="hiddenCondBold">adib Condbold font</div>
          {hasShowLocation && <div className="header">{this._renderLocationModal()}</div>}
          {invisible && (
            <AdibSearchBar
              searchValue={searchValue}
              reacentSearch={reacentSearch}
              searchCountry={searchCountry}
              onClickRecentSearch={this.onClickRecentSearch}
              onCloseSearchBar={this.onCloseSearchBar}
              value={value}
              suggestions={suggestions}
              invisible={invisible}
              onChange={this.onChange}
              _onkeyDownContent={this._onkeyDownContent}
              onFocus={this.onFocus}
              onSuggestionsFetchRequest={this.onSuggestionsFetchRequest}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              oneSuggestionSelected={this.oneSuggestionSelected}
              getSuggestionValue={this.getSuggestionValue}
              refInput={el => this.textInput = el}
              closeOutSide={this.closeOutSide}
              onClickSearchText={(v) => this.onClickSearchText(v)}
            />
          )}
        </React.Fragment>
      )
    }
  }
}

export default withRouter(AdibHeader)
