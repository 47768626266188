/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Footer Component
import React, { PureComponent } from "react";

import { withRouter } from "react-router";
import moment from "moment";
import { arabicContent } from "../../constants/index";
import './style.scss';
import right_arrow from "../../assets/img/right_arrow.png"
import adib_globe from "../../assets/img/adib_globe.png"
import maya from "../../assets/img/maya_logo_for_mobile.png"

const lang = localStorage.getItem("language");

class Footer extends PureComponent {

  _renderTitle(title) {
    return <h5 className="ftr-sub">{title}</h5>;
  }

  routeToProfile = (activeItem) => {
    this.props.setActiveItem(activeItem);
    sessionStorage.setItem("currPage", activeItem);
    this.props.history.push("/home/profile", activeItem)
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        {this.props.history.location.pathname !== "/login" &&
          <div className="col-md-12 p-0 mob-footer" >
            {/* <div className="item-1">
              <div className='h-1' >
                <span className='desc' >
                  About ADIB
                </span>
                <span className='arrw' >
                  <img src={right_arrow} />
                </span>
              </div>
            </div> */}
            <div className="item-o" onClick={() => { this.routeToProfile("faq") }} >
              <div className='h-1' >
                <span className='desc' >{lang === 'AR' ? arabicContent.faq : "FAQs"}
                </span>
                <span className={ lang === "AR" ? "arrw-ar" : "arrw" } >
                  <img src={right_arrow} />
                </span>
              </div>
            </div>
            <div className="item-o" >
              <a href="mailto:customerservice@adib.ae?subject=Smartdeals" style={{ textDecorationLine: 'none' }} ><div className='h-1' >
                <span className='desc' >{ lang === "AR" ? arabicContent.contactUsMob : "Contact us" }
                </span>
                <span className={ lang === "AR" ? "arrw-ar" : "arrw" }>
                  <img src={right_arrow} />
                </span>
              </div></a>
            </div>
            <div className='sep-line'>
            </div>
            <div className='maya'>
              {lang !== "AR" ? <span className="mr-10 pt-5" >Powered by</span> : ""}<img src={maya} />{lang === "AR" ? <span className="mr-10 pt-5" >Powered by</span> : ""}
            </div>
            <div className='rights-content'>
              <span >
                <span>{lang === 'AR' ? '' : `© `}</span>
                <span>{ lang === 'AR' ? arabicContent.copyrightsreserved : 'Copyrights reserved' } ADIB. { lang === 'AR' ? arabicContent.allrights : 'All rights' }</span>
              </span>
            </div>
            <div className='privacy'>
              <span >
              { lang === 'AR' ? arabicContent.reserved : 'reserved ' }
                <span className="cursor ml-1" onClick={() => {
                  this.routeToProfile("privacy")
                }}>   {lang === 'AR' ? arabicContent.privacy : `Privacy`} · </span>
                <span className="cursor" onClick={() => {
                  this.routeToProfile("termsAndCondition")
                }}>   {lang === 'AR' ? arabicContent.terms : `Terms`} · </span>{lang === 'AR' ? arabicContent.sitemap : `Sitemap`}
              </span>
            </div>
          </div>
        }
      </>
    );
  }
}

export default withRouter(Footer)