/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Notification Component
import React, { PureComponent } from "react";
import ContentLoader from "react-content-loader";

// Imported Component
import Switch from "../Switch";
import { arabicNotification } from '../../constants/index';
import uiConfig from "../../uiConfig";
export default class Notification extends PureComponent {
  /* onClick Function */
  handleToggle = (name, checked, ident) => {
    const { handleToggle } = this.props;
    if(handleToggle) {
      handleToggle(name, checked, ident);
    }
  }

  /* Other Render Functions */
  _renderNotification(title, desc, checked, name, isBorder, ident) {
    const { isFull } = this.props;
    const language = localStorage.getItem("language")
    return (
      <div className="col-md-12 p-0">
        {
          language === 'AR' &&
          <div className="col-md-4"></div>
        }
      <div
        className={`${isFull ? "col-md-12" : "col-md-8"} mt-21 p-0 ${
          !isBorder ? "border-bottom" : ""
        }`}
      >
        <div className="col-md-12 p-0 flex">
          <div className="flex-start notification-item">
            <div className="not-tit mb-4">{title}</div>
            <div className="not-desc mb-8">{desc}</div>
          </div>
          <div className="flex-end">
            <Switch
              keyId={name}
              disabled={false}
              isSelected={checked}
              handleToggle={() => this.handleToggle(name, !checked, ident)}
            />
          </div>
        </div>
      </div>
      </div>
    );
  }

  /* Main Render Functions */
  render() {
    const {
      messages, notifications
    } = this.props.userSettings || {};

    if (!messages && !notifications) {
      return (
        <div className="col-md-12 p-0 loder-div-notification">
          <ContentLoader
            speed={0.7}
            width="100%"
          >
            <rect x="0" y="0" rx="5" ry="5" width="100%" height="100" />
            <rect x="0" y="110" rx="4" ry="4" width="300" height="13" />
            <rect x="0" y="130" rx="3" ry="3" width="250" height="10" />
          </ContentLoader>
        </div>
      );
    }
    const language = localStorage.getItem("language")
    return (
      <div className="col-md-12 p-0 notification-div">
        <div className="col-md-12 p-0">
          <div className="col-md-12 p-0 flex">
            <h2 className="margin-0" style={uiConfig.config.whitelabel.brandColor ? {color:uiConfig.config.whitelabel.brandColor}: {}}>{language === 'EN' ? 'Notification Settings': arabicNotification.title }</h2>
          </div>
        </div>
        <div className="col-md-12 p-0 mt-18 notification-title" style={uiConfig.config.whitelabel.brandColor ? {color:uiConfig.config.whitelabel.brandColor}: {}}>
            { language === 'EN' ? 'Toggle to modify notification and message settings' : arabicNotification.subTitle}
        </div>
        <div className="col-md-12 p-0 mt-36">
          <div className="col-md-12 p-0 title">{ language === 'EN' ? 'Notifications' : arabicNotification.headingOne}</div>
          {this._renderNotification(
            language === 'EN' ? 'Push Notification' : arabicNotification.pushNotification,
            language === 'EN' ? "What’s better than sweet texts? Instant access to mind-blowing deals!" : arabicNotification.pushNotificationDesc,
            notifications.PUSH,
            "PUSH",
            false,
            "notifications"
          )}
          {this._renderNotification(
            language === 'EN' ? "Email Notification": arabicNotification.emailNotification,
            language === 'EN' ? "Don’t fret! We’ll send clutter-free, essential mails such as redemption codes" : arabicNotification.emailNotificationDesc,
            notifications.EMAIL,
            "EMAIL",
            false,
            "notifications"
          )}
          {this._renderNotification(
            language === 'EN' ? "SMS Notification" : arabicNotification.smsNotification,
            language === 'EN' ? "Want to live under a rock, yet in-trend? Our SMSs will reach you where internet can’t": arabicNotification.smsNotificationDesc,
            notifications.SMS,
            "SMS",
            false,
            "notifications"
          )}
          {this._renderNotification(
            language === 'EN' ? "Proximity Notification" : arabicNotification.proximityNotification,
            language === 'EN' ? "You move, we move! We’ve got your back on adventure trips and whimsical strolls.": arabicNotification.proximityNotificationDesc,
            notifications.PROXIMITY,
            "PROXIMITY",
            false,
            "notifications"
          )}
        </div>
        <div className="col-md-12 p-0 mt-36">
          <div className="col-md-12 p-0 title">{language === 'EN' ? 'Message': arabicNotification.messageTitle }</div>
          {this._renderNotification(
            language === 'EN' ? "Promotional Messages": arabicNotification.promotionalMessages,
            language === 'EN' ? "Not all Promotional Messages are bad, Ours are personalized to your taste!": arabicNotification.promotionalMessagesDesc,
            messages.PROMOTIONAL,
            "PROMOTIONAL",
            false,
            "messages"
          )}
          {this._renderNotification(
            language === 'EN' ? "Transactional Messages" : arabicNotification.transactionalMessages,
            language === 'EN' ? "Get notified every time a transaction is completed on your account." : arabicNotification.transactionalMessagesDesc,
            messages.TRANSACTIONAL,
            "TRANSACTIONAL",
            false,
            "messages"
          )}
          {this._renderNotification(
            language === 'EN' ? "Reminder & Alert Messages": arabicNotification.reminderMessages,
            language === 'EN' ? "We know you have other things on your mind, leave this task to us!": arabicNotification.reminderMessagesDesc,
            messages.REMINDERS,
            "REMINDERS",
            true,
            "messages"
          )}
        </div>
      </div>
    );
  }
}
